import { combineReducers } from 'redux'

import contactsReducer from './Contacts/reducer'

import layoutReducer from "./Layout/reducer"

// Calendar
import calendarReducer from "./Calendar/reducer";

//Chat
import chatReducer from "./Chat/reducer";

// //Invoices
import invoicesReducer from "./Invoices/reducer";

//register
import registerReducer from "./Auth/Register/reducer";

//login
import loginReducer from "./Auth/Login/reducer";

//project
import projectsReducer from "./Projects/reducer";

// // User Profile 
import profileReducer from "./Auth/Profile/reducer";

// //kanban
import kanbanboardsReducer from "./Kanbanboards/reducer"

// // Forget Password
import forgetPasswordReducer from "./Auth/Forgetpwd/reducer";

import customersReducer from "./Customer/reducer";

import locationReducer from "./Location/reducer";

//Tools
import toolslayouts from "./Layouts/reducer";

//Installations
import installations from "./Installations/reducer";

//Orders
import orders from "./Orders/reducer";

//Reports
import reports from "./Reports/reducer";

//Receipts
import receipts from "./Receipts/reducer";

//Products
import products from "./Products/reducer";

//Notify
import notify from "./Notify/reducer";

//Settings
import settings from "./Settings/reducer";

//InputEdit 
import InputEdit from './InputEdit_Component/reducer';

//Users
import Users from './Users/reducer';

const appReducer = combineReducers({
    contacts: contactsReducer,
    Layout: layoutReducer,
    calendar: calendarReducer,
    chat: chatReducer,
    invoices: invoicesReducer,
    register: registerReducer,
    login: loginReducer,
    forgetPassword: forgetPasswordReducer,
    projects: projectsReducer,
    profile: profileReducer,
    kanbanboards: kanbanboardsReducer,
    customer: customersReducer,
    locations: locationReducer,
    tools:  toolslayouts,
    notify: notify,
    inputEdit: InputEdit,
    settings:settings,
    users:Users,
    orders:orders,
    reports:reports,
    receipts:receipts,
    products:products,
    installations:installations
});

// Root reducer om te luisteren naar de RESET actie
const rootReducer = (state: ReturnType<typeof appReducer> | undefined, action: any) => {
    if (action.type === 'RESET') {
      state = undefined; // Zet de state terug naar de initiële waarden
    }
    return appReducer(state, action);
  };


export type DefaultRootState = ReturnType<typeof rootReducer>;
export default rootReducer