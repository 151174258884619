import React from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import CustomTable from '../CustomTable';
import { useTranslation } from 'react-i18next';
import { Col, Grid, Row } from 'rsuite';

Modal.setAppElement('#root'); // Set the root element for accessibility

const LocationSelectModal = ({ isOpen, onRequestClose, locations, onSelectLocation }): any => {
  const { t } = useTranslation();
  const tableDataColumns = [
    { label: "Id", dataName: "uuid", props: { fixed: true, align: "center" } },
    { label: t('Active'), dataName: "Active", props: { fixed: false, flexGrow: 0, resizable: true, sortable: true, fullText: false } },
    { label: t('Location'), dataName: "locationname", props: { fixed: false, flexGrow: 1, resizable: false, sortable: true, fullText: false, style: { fontWeight: "700" } } },
    { label: t('Streetname'), dataName: "locationadress", props: { fixed: false, flexGrow: 1, resizable: true, sortable: true, fullText: false } },
    { label: t('Zipcode'), dataName: "locationzipcode", props: { fixed: false, flexGrow: 1, resizable: true, sortable: true, fullText: true } },
    { label: t('City'), dataName: "locationcity", props: { fixed: false, flexGrow: 1, resizable: true, sortable: true, fullText: true } },
    { label: t('Country'), dataName: "locationcountry", props: { fixed: false, flexGrow: 1, resizable: true, sortable: true, fullText: true } },

    // { label: t('City'), dataName: "city", props: { fixed: false, flexGrow: 1, resizable: true, sortable: true, fullText: false } },
  ]
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Location Selection"
    >
      {/* <h2>Select a Location</h2>
      <ul>
        {locations.map((location) => (
          <li key={location.id} onClick={() => onSelectLocation(location)}>
            {location.name}
          </li>
        ))}
      </ul> */}
      <Grid fluid>
        <Col xl={24}>
          <Row>
            <CustomTable
              PageSize={10}
              dataRows={locations}
              onClickEvent={() => onSelectLocation(locations)}
              columns={tableDataColumns}
              keyRow={"uuid"}
              isEdit={false}
              isModal={{ enable: false, modal: "Customers" }}
            />
          </Row>
          <Row>
            <button onClick={onRequestClose}>Close</button>
          </Row>
        </Col>
      </Grid>

    </Modal>
  );
}

LocationSelectModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  locations: PropTypes.array.isRequired,
  onSelectLocation: PropTypes.func.isRequired,
};

export default LocationSelectModal;