import { createAsyncThunk } from "@reduxjs/toolkit";
// import { customersFailed, customersSuccessful } from "./reducer";

import { getOrders as getOrdersApi,  getOrderbyID as getOrderApi, getLastOrderNumber as getLastOrderNumberApi, preAddNewOrder as preAddNewOrderApi } from "../../helpers/real_helper";

export const getOrders = createAsyncThunk('orders/getOrders', async (order:{}, {rejectWithValue}) => {
    try {
      const res = await getOrdersApi(order);
      //console.log(res);
      return res;
    } catch (err) {
      return rejectWithValue({ error: err.message })
    }
  });

  export const getOrder = createAsyncThunk('orders/getOrder', async (order:{}, {rejectWithValue}) => {
    try {
      const res = await getOrderApi(order);
      return res;
    } catch (err) {
      return rejectWithValue({ error: err.message })
    }
  })

  export const getLastOrderNumber = createAsyncThunk('orders/getLastOrderNumber', async (order:{}, {rejectWithValue}) => {
    try {
      const res = await getLastOrderNumberApi(order);
      return res;
    } catch (err) {
      return rejectWithValue({ error: err.message })
    }
  })

  export const preAddNewOrder = createAsyncThunk('orders/preAddNewOrder', async (order:{}, {rejectWithValue}) => {
    try {
      const res = await preAddNewOrderApi(order);
      return res;
    } catch (err) {
      return rejectWithValue({ error: err.message })
    }
  })


