import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import MetaTags from "react-meta-tags";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";

//Import Image
import logo from "../../../assets/images/logo-light-aweka.png";

import { Link } from 'react-router-dom';
import LocationSelectModal from 'src/components/Location/LocationSelectModal';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getLastOrderNumber, getReceipt, updateReceiptItem } from 'src/slices/thunks';
import Cookie from "js-cookie";
import { useParams } from "react-router-dom";
import CurrencyInput from 'react-currency-input-field';
import { useNavigate } from 'react-router-dom';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { AppDispatch, RootState } from 'src';


function ViewReceipt() {
    const dispatch = useDispatch<AppDispatch>();
    const { t } = useTranslation();
    const locations = useSelector((state: RootState) => state.locations);
    const receipt = useSelector((state: RootState) => state.receipts);
    const order = useSelector((state: RootState) => state.orders);
    const { id } = useParams();
    const { status, error: any, } = receipt
    const obj = JSON.parse(Cookie.get("userData"));
    const [data, setData] = useState([]);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [receiptDescription, setReceiptDescription] = useState();

    useEffect(() => {
        const datain: {} = { email: obj.email, token: obj.token, id: id, METHOD: "GetReceiptbyId" };
        dispatch(getReceipt(datain))
        const datainOrder: {} = { email: obj.email, token: obj.token, METHOD: "getLastOrderNumber" };
        dispatch(getLastOrderNumber(datainOrder))
    }, [dispatch])
    useEffect(() => {
        if (status === 'success') {
            console.log("Status => " + status);
            // setData(receipt.receiptDetail)
            // console.log(receipt.receiptDetail.length);
            
            if (receipt.receiptDetail) {
                setIsLoading(false);
            } else {
                setIsLoading(true);
            }
            
        }
    }, [dispatch, status])


    // console.log(data);
    const viewValue = receipt.receiptDetail[0];
    const parseJson = (value) => {
        try {

            return value?.length > 0 ? JSON.parse(value) : [];
        } catch (error) {
            console.log("JSON parsing error:", error);
            return [];
        }
    }
    const viewLocationValue = parseJson(viewValue?.receiptLocation);
    const viewCustomerValue = parseJson(viewValue?.receiptCustomer);
    const productsValue = parseJson([viewValue?.receiptProducts]);
    const [receiptItems, setReceiptItems] = useState(productsValue);
    useEffect(() => {
        if (productsValue.length <= 0) {
            setReceiptItems([{ name: '', price: 0, quantity: 1, discount: 0 }])
        } else {
            setReceiptItems(productsValue)
        }
    }, [isLoading, dispatch, productsValue.length])

    // console.log(receiptItems);

    const addReceiptItem = () => {
        setReceiptItems([...receiptItems, { name: '', price: 0, quantity: 1, discount: 0, canEdit: true }]);
    };

    const removeReceiptItem = (index) => {
        const updatedItems = [...receiptItems];
        updatedItems.splice(index, 1);
        setReceiptItems(updatedItems);
        updateItems(updatedItems);
    };

    const calculateSubtotal = (item) => {
        if (item.discount) {
            return item.price * item.quantity - calculateDiscount(item)
        } else {
            return item.price * item.quantity;
        }
    };

    const calculateDiscount = (item) => {
        if (item.discount) {
            return (item.quantity * item.price) / 100 * item.discount;
        }
    };

    const handleItemChange = (index, field, value) => {
        const updatedItems = [...receiptItems];
        updatedItems[index][field] = value;
        // console.log(updatedItems);
        setReceiptItems(updatedItems);
        updateItems(updatedItems);
    };

    const updateItems = (items) => {
        const datain: {} = { email: obj.email, token: obj.token, data: { items: JSON.stringify(items), uuid: id }, METHOD: "UpdateReceiptItems" };
        dispatch(updateReceiptItem(datain))
    }

    const calculateTotal = () => {
        return receiptItems.reduce((total, item) => {
            return total + item.price * item.quantity;
        }, 0);
    };
    const calculateTotalDiscount = () => {
        return receiptItems.reduce((totalDiscount, item) => {
            return totalDiscount + calculateDiscount(item);
        }, 0);
    };

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedLocation, setSelectedLocation] = useState(null);


    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const handleLocationSelect = (location) => {
        setSelectedLocation(location);
        closeModal();
    };


    const formatCurrency = (value) => {
        return new Intl.NumberFormat("nl-NL", {
            style: 'currency',
            currency: 'EUR', // You can change the currency code as needed
        }).format(value);
    };
    // console.log(JSON.parse(JSON.stringify(productsValue)));

    

    const [componentYear, SetcomponentYear] = useState();
    // GetNumber({
    //     numberInput: order?.orderLastOrderNumber.length > 0 ? order?.orderLastOrderNumber[0][0] : null,
    //     yearAuto: true,
    //     numberOutput: (value) => { SetcomponentYear(value) },
    //     increaseAuto: true,
    // })

    return (
        <>
            <React.Fragment>
                {data &&
                    <LocationSelectModal
                        isOpen={isModalOpen}
                        onRequestClose={closeModal}
                        locations={data}
                        onSelectLocation={handleLocationSelect}
                    />}
                <div className="page-content">
                    {/* <MetaTags>
                        <title>{t('View')} {t('Receipt')} {viewValue?.receiptNumber} | AWEKA Energy Solutions</title>
                    </MetaTags> */}
                    <Container fluid>
                        {/* Render Breadcrumbs */}
                        <Breadcrumbs title={t('Receipts')} breadcrumbItem={[{ active: false, urlLink: "#", label: t('Receipt') }, { active: true, urlLink: "#", label: viewValue?.receiptNumber }]} />

                        <Row>
                            <Col xl={3}>
                                <button className="btn btn-soft-success mb-2" onClick={() => navigate(-1)}>
                                    <i className="uil uil-arrow-up-left me-2"></i> {t('GoBack')}
                                </button>
                            </Col>

                            <Col lg={12}>
                                <Card>
                                    <CardBody>
                                    {isLoading ? (
                                            <p>Bezig met laden van de bon...</p>
                                        ) : (
                                        <><div className="invoice-title">
                                                    <h4 className="float-end font-size-15">{t('Receipt')} {viewValue?.receiptNumber}
                                                        {viewValue?.receiptStatus === 'new' && <span className="badge bg-warning font-size-12 ms-2">Nieuw</span>}
                                                        {viewValue?.receiptStatus === 'paid' && <span className="badge bg-success font-size-12 ms-2">Betaald</span>}
                                                        {viewValue?.receiptStatus === 'guarantee' && <span className="badge bg-purple font-size-12 ms-2">Garantie</span>}
                                                    </h4>
                                                    <div className="mb-4">
                                                        <img src={logo} alt="logo" height={150} />
                                                    </div>
                                                </div><hr className="my-4" /><Row>
                                                        <Col sm={4}>
                                                            <button className='btn-sm btn-soft-primary' onClick={openModal}>Select Customer</button>
                                                            {selectedLocation && <p>Selected Customer: {selectedLocation.name}</p>}
                                                            <div className="text-muted">
                                                                <h5 className="font-size-16 mb-3">{t('Customer')}: </h5>
                                                                <h5 className="font-size-15 mb-2">{viewCustomerValue?.companyname}</h5>
                                                                <p className="mb-1">{viewCustomerValue?.streetname}</p>
                                                                <p className="mb-1">{viewCustomerValue?.zipcode}, {viewCustomerValue?.city}</p>
                                                                <p>{viewCustomerValue?.country}</p>
                                                            </div>
                                                        </Col>
                                                        <Col sm={4}>
                                                            <button className='btn-sm btn-soft-primary' onClick={openModal}>Select Location</button>
                                                            {selectedLocation && <p>Selected Location: {selectedLocation.name}</p>}
                                                            <div className="text-muted">
                                                                <h5 className="font-size-16 mb-3">{t('Location')}: </h5>
                                                                <h5 className="font-size-15 mb-2">{viewLocationValue?.locationname}</h5>
                                                                <p className="mb-1">{viewLocationValue?.locationadress}</p>
                                                                <p className="mb-1">{viewLocationValue?.locationzipcode}, {viewLocationValue?.locationcity}</p>
                                                                <p>{viewLocationValue?.locationcountry}</p>
                                                            </div>
                                                        </Col>
                                                        <Col sm={4}>
                                                            <button className='btn-sm btn-soft-primary' onClick={openModal}>Select Installation</button>
                                                            {selectedLocation && <p>Selected Location: {selectedLocation.name}</p>}
                                                            <div className="text-muted">
                                                                <h5 className="font-size-16 mb-3">{t('Installation')}: </h5>
                                                                <h5 className="font-size-15 mb-2">{viewLocationValue?.locationname}</h5>
                                                                <p className="mb-1">{viewLocationValue?.locationadress}</p>
                                                                <p className="mb-1">{viewLocationValue?.locationzipcode}, {viewLocationValue?.locationcity}</p>
                                                                <p>{viewLocationValue?.locationcountry}</p>
                                                            </div>
                                                        </Col>
                                                    </Row><hr></hr><Row>
                                                        <h5 className="font-size-16 mb-3">Omschrijving: </h5>
                                                        <Col>
                                                            <Editor
                                                                style={{ width: '200px' }}
                                                                editorState={receiptDescription}
                                                                toolbarClassName="toolbarClassName"
                                                                // toolbarClassName="demo-toolbar-absolute"
                                                                wrapperClassName="wrapperClassName"
                                                                editorClassName="editorClassName"
                                                                onEditorStateChange={setReceiptDescription}
                                                                toolbarOnFocus
                                                                toolbar={{
                                                                    options: ['inline', 'blockType', 'fontSize', 'fontFamily'],
                                                                    inline: {
                                                                        options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace'],
                                                                        bold: { className: 'bordered-option-classname' },
                                                                        italic: { className: 'bordered-option-classname' },
                                                                        underline: { className: 'bordered-option-classname' },
                                                                        strikethrough: { className: 'bordered-option-classname' },
                                                                        code: { className: 'bordered-option-classname' },
                                                                    },
                                                                    blockType: {
                                                                        className: 'bordered-option-classname',
                                                                    },
                                                                    fontSize: {
                                                                        className: 'bordered-option-classname',
                                                                    },
                                                                    fontFamily: {
                                                                        className: 'bordered-option-classname',
                                                                    },
                                                                }}
                                                                mention={{
                                                                    separator: ' ',
                                                                    trigger: '=',
                                                                    suggestions: [
                                                                        { text: 'APPLE', value: 'apple' },
                                                                        { text: 'BANANA', value: 'banana', url: 'banana' },
                                                                        { text: 'CHERRY', value: 'cherry', url: 'cherry' },
                                                                        { text: 'DURIAN', value: 'durian', url: 'durian' },
                                                                        { text: 'EGGFRUIT', value: 'eggfruit', url: 'eggfruit' },
                                                                        { text: 'FIG', value: 'fig', url: 'fig' },
                                                                        { text: 'GRAPEFRUIT', value: 'grapefruit', url: 'grapefruit' },
                                                                        { text: 'HONEYDEW', value: 'honeydew', url: 'honeydew' },
                                                                    ],
                                                                }} />
                                                        </Col>
                                                    </Row><hr></hr><div className="py-2">
                                                        <Row>
                                                            <Col xl="6">
                                                                <h5 className="font-size-16">Artikelen: </h5>
                                                            </Col>
                                                            <Col className='pull-right text-end'>
                                                                <h5><button className='btn btn-soft-success' onClick={addReceiptItem}><i className="uil-comment-alt-plus"></i> Toevoegen</button> | <button className='btn btn-soft-primary' onClick={() => console.log("Search product")}><i className="uil-comment-alt-search"></i> Zoeken</button></h5>
                                                            </Col>
                                                        </Row>
                                                        <div className="table-responsive">
                                                            <table className="table align-middle table-nowrap table-centered mb-0">
                                                                <thead>
                                                                    <tr>
                                                                        <th style={{ width: "70px" }}>Nr.</th>
                                                                        <th style={{ flexGrow: 0 }}>Aantal</th>
                                                                        <th>Artikel</th>
                                                                        <th>Prijs</th>
                                                                        <th>Korting (%)</th>
                                                                        <th className="text-end">Korting (€)</th>
                                                                        <th className="text-end" style={{ width: "120px" }}>Totaal</th>
                                                                        <th>Aktie</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {receiptItems.map((item, index) => (
                                                                        <tr key={index}>
                                                                            <th scope="row">0{index + 1}</th>
                                                                            <td><div onClick={(e) => handleItemChange(index, 'isEditing', true)}>
                                                                                {item.isEditing & item.canEdit ? (<input
                                                                                    style={{ width: 50, flexGrow: 0 }}
                                                                                    maxLength={3}
                                                                                    type="number"
                                                                                    placeholder="Quantity"
                                                                                    value={item.quantity}
                                                                                    onBlur={(e) => handleItemChange(index, 'isEditing', false)}
                                                                                    onChange={(e) => handleItemChange(index, 'quantity', parseFloat(e.target.value))} />) : (
                                                                                    <span>{item.quantity}</span>
                                                                                )}
                                                                            </div></td>
                                                                            <td>
                                                                                <div>
                                                                                    <h5 className="text-truncate font-size-14 mb-1">
                                                                                        <div onClick={(e) => handleItemChange(index, 'isEditing', true)}>
                                                                                            {item.isEditing & item.canEdit ? (<input
                                                                                                style={{ width: "100%" }}
                                                                                                maxLength={600}
                                                                                                type="text"
                                                                                                placeholder="Artikel omschrijving"
                                                                                                value={item.name}
                                                                                                onBlur={(e) => handleItemChange(index, 'isEditing', false)}
                                                                                                onChange={(e) => handleItemChange(index, 'name', e.target.value)} />
                                                                                            ) : (
                                                                                                item.name ? (<span style={item.canEdit ? { textDecoration: "underline" } : {}}>{item.name}</span>) : (<span style={item.canEdit ? { textDecoration: "underline" } : {}}>Geen artikel omschrijving</span>)
                                                                                            )}
                                                                                        </div></h5>
                                                                                    <p className="text-muted mb-0"></p>
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <CurrencyInput
                                                                                    id="input-example"
                                                                                    name="Price"
                                                                                    placeholder="Price"
                                                                                    defaultValue={item.price}
                                                                                    decimalsLimit={2}
                                                                                    style={{ width: 80 }}
                                                                                    onValueChange={(value, name) => handleItemChange(index, 'price', value)} />
                                                                            </td>
                                                                            <td><input
                                                                                style={{ width: 80 }}
                                                                                maxLength={3}
                                                                                type="number"
                                                                                placeholder="Discount"
                                                                                max={100}
                                                                                min={0}
                                                                                value={item.discount}
                                                                                onChange={(e) => handleItemChange(index, 'discount', parseFloat(e.target.value))} /></td>
                                                                            <td className="text-end">{item.discount && formatCurrency(calculateDiscount(item).toFixed(2))}</td>
                                                                            <td className="text-end">{formatCurrency(calculateSubtotal(item).toFixed(2))}</td>
                                                                            <td><button onClick={() => removeReceiptItem(index)}><i className="uil-trash-alt"></i></button></td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                        </div>

                                                        <Row>
                                                            <Col className='text-end mt-3'>
                                                                <table style={{ display: 'inline-grid' }}>
                                                                    <tbody>
                                                                        <tr>
                                                                            <th scope="row" colSpan={5} className="border-0 text-end">Sub-Totaal : </th>
                                                                            <td className="text-end">{formatCurrency(calculateTotal())}</td>
                                                                        </tr>

                                                                        <tr>
                                                                            <th scope="row" colSpan={5} className="border-0 text-end">
                                                                                Korting : </th>
                                                                            <td className="border-0 text-end" style={{ color: '#f34e4e' }}>- {formatCurrency(calculateTotalDiscount())}</td>
                                                                        </tr>

                                                                        <tr>
                                                                            <th scope="row" colSpan={5} className="border-0 text-end">
                                                                                Belasting : </th>
                                                                            <td className="border-0 text-end" style={{ color: '#f34e4e' }}>{formatCurrency(((calculateTotal() - calculateTotalDiscount()) * 1.21) - (calculateTotal() - calculateTotalDiscount()))}</td>
                                                                        </tr>

                                                                        <tr>
                                                                            <th scope="row" colSpan={5} className="border-0 text-end">
                                                                                Totaal (inc.) : </th>
                                                                            <td className="border-0 text-end">{formatCurrency((calculateTotal() - calculateTotalDiscount()) * 1.21)}</td>
                                                                        </tr>

                                                                        <tr>
                                                                            <th scope="row" colSpan={5} className="border-0 text-end">Totaal (exc.) : </th>
                                                                            <td className="border-0 text-end"><h4 className="m-0 fw-semibold" style={{ color: '#51d28c' }}>{formatCurrency(calculateTotal() - calculateTotalDiscount())}</h4></td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </Col>
                                                        </Row>


                                                        <div className="d-print-none mt-4">
                                                            <div className="float-end">
                                                                {/* <Link to="#" className="btn btn-success me-1"><i className="fa fa-print"></i></Link> */}
                                                                <Link to="#" className="btn btn-primary w-md">Verzenden & Afsluiten</Link>
                                                            </div>
                                                        </div>
                                                    </div></>)}

                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        </>
    );
}


export default ViewReceipt