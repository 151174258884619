import React, { useEffect, useState } from "react";

import { Route, Routes } from "react-router-dom";

// Import Routes all
import { userRoutes } from "./routes/allRoutes";

//redux

// Import all middleware

// layouts Format
import VerticalLayout from "./components/VerticalLayout/";
import HorizontalLayout from "./components/HorizontalLayout/index";

// Import scss
import "./assets/scss/theme.scss";

//Toast Container 
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


// Import Firebase Configuration file
// import { initFirebaseBackend } from "./helpers/firebase_helper";

import fakeBackend from "./helpers/AuthType/fakeBackend";
// import './server';

import Login from "./pages/Authentication/Login";
import Sales from "./pages/Sales";
import AuthGuard from "./routes/middleware/AuthGuard";
import Logout from "./pages/Authentication/Logout";
import { PhonePlugin as VoipPlugin } from "./components/Voip/PhonePlugin";
import { LoadAllData } from "./components/AllDataloading/LoadAllData";
import InactivityMonitor from "./components/InactiveLock/InactivityMonitor";
import LockScreen from "./pages/Authentication/Lockscreen";
import { useNavigate } from "react-router-dom";
import Cookie from "js-cookie";
import _ from "lodash";


// Activating fake backend
fakeBackend();



const App = () => {


  VoipPlugin();


  type LayoutType = "horizontal" | "vertical";
  function getLayout(layoutType: LayoutType): any {
    let layoutCls: any = VerticalLayout; // Default to VerticalLayout
    switch (layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout;
        break;
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  }



  // Check if location is defined before accessing its properties

  const layoutType: LayoutType = "vertical"; // Example layout type, you should set this dynamically

  const Layout = getLayout(layoutType);




  //#endregion

  // ####### GET ALL DATA AT ONES ###########
  //#region

  LoadAllData()
  //#endregion

  const [isLocked, setIsLocked] = useState(false);
  // const [lastActivityTime, setLastActivityTime] = useState(Date.now());
  const navigate = useNavigate();

  useEffect(() => {
    if (Cookie.get('lock')) {
            navigate('/lock'); // Of een andere gewenste route
          }
  }, [])

  // useEffect(() => {
  //   const handleActivity = () => {
  //     setLastActivityTime(Date.now());
  //     if (isLocked) {
  //       setIsLocked(false);
  //       Cookie.remove('lock'); // Verwijder de lock-cookie bij activiteit
  //       navigate('/'); // Of een andere gewenste route
  //     }
  //   };

  //   document.addEventListener('keydown', handleActivity);
  //   document.addEventListener('mousemove', handleActivity);
  //   document.addEventListener('click', handleActivity);

  //   // Tijdelijke variabele voor de timeout ID
  //   let timeoutId: NodeJS.Timeout;

  //   const startInactivityTimer = () => {
  //     // Start een timer die controleert op inactiviteit na 10 minuten
  //     timeoutId = setTimeout(() => {
  //       if (Cookie.get('userData') && Date.now() - lastActivityTime > 10 * 60 * 50) { // 10 minuten
  //         if (!isLocked) {
  //           setIsLocked(true);
  //           Cookie.set('lock', 'true'); // Zet de lock-cookie
  //           navigate('/lock'); // Ga naar de lockpagina
  //         }
  //       }
  //     }, 1000); // Controleer elke seconde
  //   };

  //   // Start de inactiviteitstimer bij het laden van de component
  //   startInactivityTimer();

  //   return () => {
  //     document.removeEventListener('keydown', handleActivity);
  //     document.removeEventListener('mousemove', handleActivity);
  //     document.removeEventListener('click', handleActivity);
  //     clearTimeout(timeoutId); // Stop de timer bij het ontruimen van de component
  //   };
  // }, [isLocked, lastActivityTime, navigate]);

  return (
    <React.Fragment>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Routes>
        <Route path="/lock" element={<LockScreen />} />
        <Route path="/login" element={<Login />} />
        <Route path="/logout" element={<Logout history={undefined} />} />
        <Route element={<AuthGuard />} component={<AuthGuard />}>
          <Route element={<Layout />} component={<Layout />}>
            {/* <Route element={<AuthGuard />}> */}
            <Route
              path="/store"
              element={(
                <Sales />
              )}
              component={(
                <Sales />
              )}
            />
            {userRoutes.map((route: any, idx: number) => {

              return (<Route
                key={idx}
                path={route.path}
                element={route.component}
                component={route.component}
              />)

            })}

          </Route>
        </Route>
      </Routes>
    </React.Fragment>
  );
};

export default App;