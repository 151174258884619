import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { UserLogoutReal } from "../../slices/thunks";

//redux
import { useDispatch } from "react-redux";
import { AppDispatch } from "src";

interface LogoutProps {
  history?: any;
}

const Logout = ({ history }: LogoutProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  useEffect(() => {
     dispatch(UserLogoutReal(history));
      navigate('/login');
  }, [dispatch, history]);

  return <></>;
};

export default Logout;
