import { createSlice } from "@reduxjs/toolkit";
import { getReceipts, getReceipt, updateReceiptItem, newReceipt } from "./thunk";


interface ReceiptStateType {
    receipts: Array<any>;
    receiptDetail: object;
    status: string;
    error?: string | object | null | undefined | unknown;
    setAddreport: Array<any>;
    dump: string;
}
export const initialState: ReceiptStateType = {
    receipts: [],
    receiptDetail: {},
    status: 'idle',
    error: {},
    setAddreport: [],
    dump: ' ',
};

const receiptsSlice = createSlice({
    name: "receipts",
    initialState,
    reducers: {
        // customersSuccessful(state, action) {
        //     state.status    = 'Success'
        //     state.customers = action.payload
        // },
        // customersFailed(state, action) {
        //     state.error     = action.payload
        //     state.customers = action.payload
        // },
    },
    extraReducers: builder => {
        //Get Orders ALL
        builder.addCase(getReceipts.pending, (state: any, action: any) => {
            state.status = 'loading';
        });
        builder.addCase(getReceipts.fulfilled, (state: any, action: any) => {
            state.receipts = action.payload;
            state.status = 'success';
        });
        builder.addCase(getReceipts.rejected, (state, action) => {
            state.error = action.payload || null;
            state.status = 'error';
        });
        // Get Order by ID
        builder.addCase(getReceipt.pending, (state: any, action: any) => {
            state.status = 'loading';
        });
        builder.addCase(getReceipt.fulfilled, (state: any, action: any) => {
            state.receiptDetail = action.payload;
            state.status = 'success';
        });
        builder.addCase(getReceipt.rejected, (state, action) => {
            state.error = action.payload || null;
            state.status = 'error';
        });
        // Update Receipt Item
        builder.addCase(updateReceiptItem.pending, (state: any, action: any) => {
            state.status = 'loading';
        });
        builder.addCase(updateReceiptItem.fulfilled, (state: any, action: any) => {
            state.dump = action.payload;
            state.status = 'success';
        });
        builder.addCase(updateReceiptItem.rejected, (state, action) => {
            state.error = action.payload || null;
            state.status = 'error';
        });
        // New Receipt 
        builder.addCase(newReceipt.pending, (state: any, action: any) => {
            state.status = 'loading';
        });
        builder.addCase(newReceipt.fulfilled, (state: any, action: any) => {
            state.dump = action.payload;
            state.status = 'success';
        });
        builder.addCase(newReceipt.rejected, (state, action) => {
            state.error = action.payload || null;
            state.status = 'error';
        });
    },
});
// export const {
//     // customersSuccessful,
//     // customersFailed
// } = receiptsSlice.actions

export default receiptsSlice.reducer;