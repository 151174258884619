import { postRealLogin as loginUserApi } from "../../../helpers/real_helper";
import { createAsyncThunk } from '@reduxjs/toolkit';
import  Cookie  from "js-cookie";



export const UserLoginReal = createAsyncThunk('login/User', async (user:{}, {rejectWithValue}) => {
    try {
      const res:any = await loginUserApi(user);
    
      if (res?.APIResponseStatus === 'success') {
        //Success Login
        return res;
      } else {
        //Failed Login
        return rejectWithValue(res?.msg)
      }
    } catch (err) {
      return rejectWithValue(err)
    }
  })


export const UserLogoutReal = (navigate: any) => async (dispatch: any) => {

    try {
        localStorage.removeItem("authUser");
        Cookie.remove("isLogged");
        Cookie.remove("userData");
        Cookie.remove("lock");
        dispatch({ type: 'RESET' });
        navigate("/login");
    } catch (error) {
       return error
    }
};