import { createAsyncThunk } from "@reduxjs/toolkit";
import { getUsers as getUsersApi,  getUser as getUserApi, setUser as setUserApi, getAuth as getAuthApi, DeleteCustomer as deleteCustomerApi } from "../../helpers/real_helper";

export const getUsers = createAsyncThunk('users/getUsers', async (user:{}, {rejectWithValue}) => {
    try {
      const res = await getUsersApi(user);
     // console.log("response Server GetallUsers => "+JSON.stringify(res));
      return res;
    } catch (err) {
      return rejectWithValue({ error: (err as Error).message })
    }
  });

  export const getUser = createAsyncThunk('users/getUser', async (user:{}, {rejectWithValue}) => {
    try {
      const res = await getUserApi(user);
      return res;
    } catch (err) {
      return rejectWithValue({ error: (err as Error).message })
    }
  });

  export const getAuth = createAsyncThunk('users/getAuth', async (user:{}, {rejectWithValue}) => {
    try {
      const res = await getAuthApi(user);
      console.log(res);
      
      return res;
    } catch (err) {
      return rejectWithValue({ error: (err as Error).message })
    }
  })

  export const deleteUser = createAsyncThunk('users/deleteUser', async (user:{}, {rejectWithValue}) => {
    try {
      const res = await deleteCustomerApi(user);
      return res;
    } catch (err) {
      return rejectWithValue({ error: (err as Error).message })
    }
  })

  export const addUser = createAsyncThunk('users/addUser', async (user:{}, {rejectWithValue}) => {
    try {
      const response:any = await setUserApi(user);
      console.log(response);
      const dataout:any =  JSON.parse(response)
      // console.log(response);
      if (dataout.status !== 'error') {
        return dataout.responseServer;
      }else{
        return rejectWithValue(JSON.stringify(dataout.responseServer))
      }
      
      //return response.data.responseServer
      //console.log(res);
      //return res;
    } catch (err) {
      return rejectWithValue(err)
    }
  })

  export const UpdateUser = createAsyncThunk('users/UpdateUser', async (updateuser:{}, {rejectWithValue}) => {
    try {
      const response:any = await setUserApi(updateuser);
      console.log(response);
      const dataout:any =  JSON.parse(response)
      // console.log(response);
      if (dataout.status !== 'error') {
        return dataout.responseServer;
      }else{
        return rejectWithValue(JSON.stringify(dataout.responseServer))
      }
      
      //return response.data.responseServer
      //console.log(res);
      //return res;
    } catch (err) {
      return rejectWithValue(err)
    }
  })


