import React, { useEffect, useMemo, useState } from 'react';
import { UncontrolledDropdown, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Card, CardBody, Form, Input, Modal, ModalHeader, ModalBody, Row, Col, ModalFooter } from "reactstrap";
import { Link, useNavigate } from 'react-router-dom';

import cookie from "js-cookie";
import { useDispatch, useSelector } from 'react-redux';
import { deleteCustomer, getCustomers, getLocationbyCustomerID } from 'src/slices/thunks';
import { useTranslation } from 'react-i18next';
import dataloaded from "../Common/DataLoaded";
import PaginationCustom from '../Common/Pagenite';
import DeleteModal from '../Common/UseModalDelete';
import { AppDispatch, RootState } from './../../index'

interface CustomerProps {
    PageSize: number
}


const Customers = (props: CustomerProps) => {
    const {
        PageSize
    } = props;

    //Load Languages Translate
    //#region
    const { t } = useTranslation();
    //#endregion

    //Getting State Customers
    //#region
    const dispatch = useDispatch<AppDispatch>();
    // const dispatch = useDispatch();
    const customers = useSelector((state: RootState) => state.customer);
    const { status, error: any, error } = customers
    const obj = JSON.parse(cookie.get("userData"));
    const [deletemodal, setDeleteModal] = useState(false);
    const [deleteData, setDeleteData] = useState(null);
    const Deletetoggle = () => setDeleteModal(!deletemodal);
    const [SelectedCheckboxCustomer, setCheckboxCustomer] = useState([]);
    //#endregion

    //Load Data 
    //#region
    const [data, setData] = useState([]);
    useEffect(() => {
        const datain: {} = { email: obj.email, token: obj.token, METHOD: "GetCustomers" };
        dispatch(getCustomers(datain))
    }, [])
    useEffect(() => {
        dataloaded({ id: 1, status, successful: t('CustomersLoaded'), time: 1000, notify: true, notifyData: "test data send to notifysystem!" })
        if (status === 'success') {
            setData(customers.customers);
            setCurrentPage(1);
        }
    }, [status]);
    //#endregion

    //Pagination 
    //#region

    const HandleSelectCheckbox = (id) => {
        let newArray = [...SelectedCheckboxCustomer, id];
        if (SelectedCheckboxCustomer.includes(id)) {
            newArray = newArray.filter(day => day !== id);
        }
        setCheckboxCustomer(newArray);
    }

    // Toggle searchF
    const toggleSearch = () => {
        setsearch_Menu(!search_Menu);
    };
    const [search_Menu, setsearch_Menu] = useState<boolean>(false);
    /// END SEARCH 


    const [filteredList, setFilteredList] = useState([]);
    const [filter, setFilter] = useState("");
    //let PageSize = 10;
    const filterBySearch = (event) => {
        // Access input value
        const query = event.target.value;
        // Set filter value
        setFilter(query);
        // Create copy of item list
        var updatedList = [...data];
        // Include all elements which includes the search query
        updatedList = updatedList.filter((o) => Object.keys(o).some((k) => o[k]?.toLowerCase().includes(query.toLowerCase())));
        console.log(updatedList.filter((o) => Object.keys(o).some((k) => o[k]?.toLowerCase().includes(query.toLowerCase()))));

        // Trigger render with updated values
        setFilteredList(updatedList);
        //reset by search to first page 
        setCurrentPage(1);
    };

    const [sort, setSort] = useState('Asc');
    const sortAscending = (Typen) => {
        console.log(Typen);

        let sortedData = [].concat(data).sort((a, b) => a[Typen].localeCompare(b[Typen].toLowerCase()))
        setData(sortedData)
        setSort("Asc")
    }
    const sortDescending = (Typen) => {
        console.log(data);
        let sortedData = [].concat(data).sort((a, b) => b[Typen].localeCompare(a[Typen].toLowerCase()))
        setData(sortedData)
        setSort("Desc")
    }

    const [currentPage, setCurrentPage] = useState(1);
    const currentTableData = useMemo(() => {

        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;


        return filteredList.length > 0 ?
            filteredList.slice(firstPageIndex, lastPageIndex) :
            data.slice(firstPageIndex, lastPageIndex);
    }, [currentPage, filteredList, data]);

    //#endregion

    //Push History
    //#region
    const navigate = useNavigate();
    const GoTo = (e) => {
        console.log("to UUID => " + e);

        navigate('/customer-profile/' + e, {
            state: { customerDetail: customers, }
        })
    }
    //#endregion

    //Modal Selected Customer 
    //#region
    // const [isOpen, toggle, ModalData] = useModal();
    const [modal, setModal] = useState(false);
    const [modalData, setModalData] = useState(null);
    const toggle = () => setModal(!modal);
    const locationsbycustomerID = useSelector((state: RootState) => state.locations);
    const [locationsData, setlocationsData] = useState([]);

    useEffect(() => {
        setlocationsData([]);
        if (modalData?.UUID) {
            const datain: {} = { email: obj.email, token: obj.token, id: modalData?.UUID, METHOD: "GetLocationbyCustomerID" };
            dispatch(getLocationbyCustomerID(datain))
            console.log(locationsbycustomerID);

        } else if (locationsbycustomerID.status === 'success') {
            setlocationsData(locationsbycustomerID.locations);
        }

    }, [modal, modalData])
    const convertDate = (selectDate: Date, landString: string) => {

        if (selectDate) {
            const date = new Date(selectDate);
            return date.toLocaleDateString(landString);
        } else {
            return "Geen tijd";
        }


    }


    const CustomerModal = () => {

        return (
            <Modal isOpen={modal} toggle={toggle} >
                <ModalHeader>{t("Customer")}</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col xl={6}>
                            <b>{modalData?.companyname}</b><br />
                            {modalData?.streetname}<br />
                            {modalData?.zipcode} {modalData?.city}<br />
                            {modalData?.country}
                        </Col>
                        <Col xl={6}>
                            <Row>
                                <p><small>{t('Email')}</small> : <Link target='blank' to='#' onClick={() => window.location.href = "mailto:" + modalData?.email}>{modalData?.email}</Link></p>
                            </Row>
                            <Row>
                                <p><small>{t('InvoiceEmail')}</small> : <Link target='blank' to='#' onClick={() => window.location.href = "mailto:" + modalData?.invoiceemail}>{modalData?.invoiceemail}</Link></p>
                            </Row>
                            <Row>
                                <p><small>{t('PhoneNumber')}</small> : {modalData?.phonenumber}</p>
                            </Row>
                            <Row>
                                <p><small>{t('MobileNumber')}</small> : {modalData?.mobilenumber} </p>
                            </Row>
                            <Row>
                                <p><small>{t('Active')}</small> : {modalData?.Active}</p>
                            </Row>
                        </Col>

                    </Row>


                    <div className='mt-5'>
                        <Row>
                            <b>Analystics</b>
                            <Col xl={6}>
                                <Card>
                                    <CardBody>

                                        <div className="d-flex align-items-center">
                                            <div className="flex-shrink-0 me-3">
                                                <div className="avatar-sm">
                                                    <div className="avatar-title bg-soft-primary text-primary rounded-circle font-size-18">
                                                        <i className="uil uil-list-ul"></i>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="flex-grow-1 overflow-hidden">
                                                <p className="mb-1 text-truncate text-muted">{t('Amount')} {t('Locations')}</p>
                                                <h5 className="font-size-16 mb-0">{locationsbycustomerID.locations.length}</h5>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col xl={6}>
                                <Card>
                                    <CardBody>
                                        <div className="d-flex align-items-center">
                                            <div className="flex-shrink-0 me-3">
                                                <div className="avatar-sm">
                                                    <div className="avatar-title bg-soft-primary text-primary rounded-circle font-size-18">
                                                        <i className="uil uil-list-ul"></i>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="flex-grow-1 overflow-hidden">
                                                <p className="mb-1 text-truncate text-muted">{t('Amount')} {t('Receipts')}</p>
                                                <h5 className="font-size-16 mb-0">21</h5>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className="d-flex flex-wrap gap-2">
                        <button className="btn btn-soft-success" onClick={() => GoTo(modalData?.UUID)}>
                            <i className="uil uil-user me-2"></i>{t("View")}
                        </button>
                        <button className="btn btn-soft-primary" onClick={toggle}>
                            <i className="uil uil-archive me-2"></i>{t("Receipts")}
                        </button>
                        <button className="btn btn-soft-danger" onClick={() => { setDeleteData(modalData); setDeleteModal(!deletemodal); setModal(!modal) }}>
                            <i className="uil uil-trash-alt me-2"></i>{t("Delete")}
                        </button>
                        <button className="btn btn-soft-warning" onClick={toggle}>
                            <i className="uil uil-times me-2"></i>{t('Close')}
                        </button>
                    </div>
                    <p><small> {t('CreationDate')} : <b>{convertDate(modalData?.creationdate, 'nl-NL')}</b> by <b>{modalData?.createdby}</b></small></p>
                </ModalFooter>
            </Modal>
        )
    }
    //#endregion

    //View part 
    //#region
    return (
        <React.Fragment>
            <CustomerModal />
            <DeleteModal
                isOpen={deletemodal}
                toggle={Deletetoggle}
                data={deleteData}
                from='Customer'
            />
            <Card>
                <CardBody>
                    <div className="d-flex justify-content-between">
                        <h4 className="card-title mb-4">{t("Customers")} ({data.length})</h4>
                        <div>
                            <Dropdown isOpen={search_Menu} toggle={toggleSearch} className="d-inline">
                                <DropdownToggle className="text-muted me-3 mb-3 align-middle" tag="a">
                                    <i className="bx bx-search font-size-16" />{" "}
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-end p-0 dropdown-menu-lg">
                                    <Form className="p-2">
                                        <div className="search-box">
                                            <div className="position-relative">
                                                <Input
                                                    onChange={filterBySearch}
                                                    type="text"
                                                    className="form-control rounded bg-light border-0"
                                                    placeholder={t("Search")}
                                                    aria-label="Recipient's username"
                                                    value={filter.length >= 0 ? filter : ""}
                                                />
                                                <i className="bx bx-search font-size-16 search-icon"></i>
                                            </div>
                                        </div>
                                    </Form>
                                </DropdownMenu>
                            </Dropdown>
                        </div>
                    </div>

                    <div className="table-responsive">
                        <table className="table table-hover table-nowrap mb-0 align-middle table-check">
                            <thead className="bg-light">
                                <tr>
                                    <th className="rounded-start" style={{ width: "15px" }}>
                                        {/* <div className="form-check">
                                            <input className="form-check-input font-size-16" type="checkbox"
                                                value="" id="checkAll" />
                                            <label className="form-check-label" htmlFor="checkAll"> </label>
                                        </div> */}
                                    </th>
                                    <th>ID</th>
                                    <th onClick={sort === 'Asc' ? () => sortDescending('Active'): () => sortAscending('Active') }>{t("Active")}</th>
                                    <th onClick={sort === 'Asc' ? () => sortDescending('companyname'): () => sortAscending('companyname') }>{t("Customer")}</th>
                                    <th onClick={sort === 'Asc' ? () => sortDescending('streetname'): () => sortAscending('streetname') }>{t("Streetname")}</th>
                                    <th onClick={sort === 'Asc' ? () => sortDescending('zipcode'): () => sortAscending('zipcode') }>{t("Zipcode")}</th>
                                    <th onClick={sort === 'Asc' ? () => sortDescending('city'): () => sortAscending('city') } colSpan={2} className="rounded-end">{t("City")}</th>
                                </tr>

                            </thead>

                            <tbody>

                                {(currentTableData || []).map((order: any, key: number) => (
                                    <tr key={key}>
                                        <td>
                                            <div className="form-check">
                                                <input className="form-check-input font-size-16" type="checkbox"
                                                    value="" id={"flexCheckexampleone" + key} onClick={
                                                        () => HandleSelectCheckbox(order['UUID'])
                                                    } />
                                                <label className="form-check-label" htmlFor={"flexCheckexampleone" + key}>
                                                </label>
                                            </div>
                                        </td>
                                        <td className="fw-medium">
                                            {order['UUID']}
                                        </td>
                                        <td>
                                            <div className="d-flex">
                                                <div className="me-2">
                                                    <i className={`mdi ${order['Active'] === 'Ja' ? 'mdi-check-circle-outline text-success' : 'mdi-close-circle-outline text-danger'}`}></i>
                                                </div>
                                                <div>
                                                    <p className="mb-0">{order['Active']}</p>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="d-flex align-items-center">
                                                <div className="me-2">
                                                    {/* <img src={order['image']}
                                                        className="avatar-sm img-thumbnail h-auto rounded-circle"
                                                        alt="Error" /> GoTo(order["UUID"])*/}
                                                </div>
                                                <div>
                                                    <h5 className="font-size-14 text-truncate mb-0">
                                                        <Link to="#" onClick={() => { setModal(!modal); setModalData(order); }} className="text-reset">{order['companyname']}</Link>
                                                    </h5>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            {order['streetname']}
                                        </td>
                                        <td>
                                            {order['zipcode']}
                                        </td>
                                        <td>
                                            {order['city']}
                                        </td>
                                        {/* <td>
                                            <UncontrolledDropdown>
                                                <DropdownToggle className="card-drop" tag="a">
                                                    <i className="mdi mdi-dots-horizontal font-size-18 text-muted"></i>
                                                </DropdownToggle>
                                                <DropdownMenu className="dropdown-menu-end">
                                                    <DropdownItem onClick={() => GoTo(order["UUID"])}>
                                                        <i className="mdi mdi-archive-outline font-size-16 text-success me-1"></i>{t("View")}
                                                    </DropdownItem>

                                                    <DropdownItem>
                                                        <i className="mdi mdi-archive-outline font-size-16 text-success me-1"></i>{t("Receipts")}
                                                    </DropdownItem>

                                                    <DropdownItem>
                                                        <i className="mdi mdi-pencil font-size-16 text-success me-1"></i>{t("Edit")}
                                                    </DropdownItem>

                                                    <DropdownItem>
                                                        <i className="mdi mdi-trash-can font-size-16 text-danger me-1"></i>{t("Delete")}
                                                    </DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </td> */}
                                    </tr>
                                ))}

                            </tbody>
                        </table>
                        <PaginationCustom
                            className="pagination-bar"
                            currentPage={currentPage}
                            totalCount={Number(filteredList.length > 0 ? filteredList.length : data.length)}
                            siblingCount={5}
                            pageSize={PageSize}
                            onPageChange={page => setCurrentPage(page)} />
                    </div>
                </CardBody>
            </Card>
        </React.Fragment >
    );
    //#endregion
};

export default Customers;