import usFlag from "../assets/images/flags/us.jpg";
import spain from "../assets/images/flags/spain.jpg";
import germany from "../assets/images/flags/germany.jpg";
import italy from "../assets/images/flags/italy.jpg";
import russia from "../assets/images/flags/russia.jpg";
import nlFlag from "../assets/images/flags/nl.jpg";


const languages : Object = {
  nlNL: {
    label: "Dutch",
    flag: nlFlag,
  },
  esES: {
    label: "Spanish",
    flag: spain,
  },
  // gr: {
  //   label: "German",
  //   flag: germany,
  // },
  // it: {
  //   label: "Italian",
  //   flag: italy,
  // },
  // rs: {
  //   label: "Russian",
  //   flag: russia,
  // },
  enGB: {
    label: "English",
    flag: usFlag,
  },
};

export default languages;
