import { createAsyncThunk } from "@reduxjs/toolkit";
import { UpdateCustomer as UpdateCustomerApi, updateSettings as UpdateSettingsApi} from "../../helpers/real_helper";

export const UpdateCustomer = createAsyncThunk("Input/UpdateCustomer", async (customer:{}, {rejectWithValue}) => {
    try {
        const response:any = await UpdateCustomerApi(customer);
      console.log(response);
      const dataout:any =  JSON.parse(response)
      // console.log(response);
      if (dataout.status !== 'error') {
        return dataout.responseServer;
      }else{
        return rejectWithValue(JSON.stringify(dataout.responseServer))
      }
    } catch (error) {
        return error;
    }
});
export const UpdateSettings = createAsyncThunk("Input/UpdateSettings", async (settings:{}, {rejectWithValue}) => {
  try {
      const response:any = await UpdateSettingsApi(settings);
    console.log(response);
    const dataout:any =  JSON.parse(response)
    // console.log(response);
    if (dataout.status !== 'error') {
      return dataout.responseServer;
    }else{
      return rejectWithValue(JSON.stringify(dataout.responseServer))
    }
  } catch (error) {
      return error;
  }
});

