import React, { useEffect, useState } from "react";
import img4 from "../../assets/images/small/img-4.jpg";
import { Card, CardBody, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown } from "reactstrap";
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';


const CustomerLocations = ({ data }) => {

    
    const [loadlist, setloadlist] = useState(Array.isArray(data) ? data : []);

    useEffect(() => {
        // Update loadlist if data changes and ensure it is always an array
        setloadlist(Array.isArray(data) ? data : []);
    }, [data]);

    // console.log(data);


    const listLocations = loadlist.map((detail: any, keyLocation: number) => {
        return (

            <li key={keyLocation} className="list-group-item py-3 px-0">
                <div className="d-flex align-items-center">
                    <div className="flex-shrink-0 me-3">
                        <div>
                            <img src={img4} alt="blog img" className="avatar-lg h-auto rounded" />
                        </div>
                    </div>
                    <div className="flex-grow-1 overflow-hidden">
                        <h5 className="font-size-14 mb-1 text-truncate"><Link to="#" className="text-dark">{detail.locationname}</Link></h5>
                        <ul className="list-inline font-size-13 text-muted">
                            <li className="list-inline-item">
                                <Link to="#" className="text-muted">
                                    <i className="uil uil-user me-1"></i> {detail.locationname}
                                </Link>
                            </li>{" "}
                            <li className="list-inline-item">
                                <i className="uil uil-calender me-1"></i> {detail.locationadress}, {detail.locationzipcode} {detail.locationcity}
                            </li>
                        </ul>
                    </div>
                    <div className="flex-shrink-0 ms-2">
                        <UncontrolledDropdown>
                            <DropdownToggle
                                type="button"
                                className="btn btn-link text-dark font-size-16 p-1 shadow-none"
                                tag="a"
                            >
                                <i className="uil uil-ellipsis-h"></i>
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-end">
                                <DropdownItem to="#">Action</DropdownItem>
                                <DropdownItem to="#">Another action</DropdownItem>
                                <DropdownItem to="#">Something else here</DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>
                </div>
            </li>
        );

    });


    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    {data && data.length > 0 ? listLocations : <p>Geen locaties gevonden </p>}
                </CardBody>
            </Card>
        </React.Fragment>
    );
}

export const CountLocations = (data: any, customerID: string) => {
    return data.length
}

CustomerLocations.propTypes = {
    data: PropTypes.array.isRequired,        // Adjust the type as needed
  };
export default CustomerLocations;