import { createSlice } from "@reduxjs/toolkit";
// import { getCustomers, getCustomer } from "./thunk";
import { getToolsLayouts, getToolsLayoutsbyID, setToolsLayoutbyID } from "./thunk";


interface ToolsLayoutsStateType {
    toolsLayouts: Array<any>;
    toolsLayoutDetail: {};
    status: string;
    error?: string | object | null | undefined | unknown;
}
export const initialState: ToolsLayoutsStateType = {
    toolsLayouts: [],
    toolsLayoutDetail: {},
    status: 'idle',
    error: {},
};

const ToolsLayoutsSlice = createSlice({
    name: "tools",
    initialState,
    reducers: {
        // customersSuccessful(state, action) {
        //     state.status    = 'Success'
        //     state.customers = action.payload
        // },
        // customersFailed(state, action) {
        //     state.error     = action.payload
        //     state.customers = action.payload
        // },
    },
    extraReducers: builder => {
        builder.addCase(getToolsLayouts.pending, (state: any, action: any) => {
            state.status = 'loading';
        });
        builder.addCase(getToolsLayouts.fulfilled, (state: any, action: any) => {
            state.toolsLayouts = action.payload;
            state.status = 'success';
        });
        builder.addCase(getToolsLayouts.rejected, (state, action) => {
            state.error = action.payload || null;
            state.status = 'error';
        });



        builder.addCase(getToolsLayoutsbyID.pending, (state: any, action: any) => {
            state.status = 'loading';
        });
        builder.addCase(getToolsLayoutsbyID.fulfilled, (state: any, action: any) => {
            state.toolsLayoutDetail = action.payload;
            state.status = 'success';
        });
        builder.addCase(getToolsLayoutsbyID.rejected, (state, action) => {
            state.error = action.payload || null;
            state.status = 'error';
        });

        builder.addCase(setToolsLayoutbyID.fulfilled, (state: any, action: any) => {
            state.setToolsLayoutbyID = action.payload;
            state.status = 'success';
        });
    },
});
// export const {
//     // customersSuccessful,
//     // customersFailed
// } = ToolsLayoutsSlice.actions

export default ToolsLayoutsSlice.reducer;