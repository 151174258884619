import React, { useEffect, useState } from "react";
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Label,
    InputGroup,
    Alert
} from "reactstrap";
import SimpleBar from "simplebar-react";
import { Link } from 'react-router-dom';


import { addUser, getSettings, getUser, getUsers, setNotifybyID } from "src/slices/thunks";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import dataloaded from "src/components/Common/DataLoaded";
import Cookie from "js-cookie";
import InputEdit from "src/components/Input/inputEdit";
import img1 from '../../assets/images/logo-dark.png'
import { SubmitHandler, useForm } from "react-hook-form";
import { AppDispatch, RootState } from "src";
import useMetaTag from "src/components/Common/useMetaTag";



const MainSettings = () => {


    //Constans
    //#region
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const settings = useSelector((state: RootState) => state.settings);
    const users = useSelector((state: RootState) => state.users);
    const { status, error } = settings
    const { statusUsers, errorUsers: errorUsers } = users
    const obj = JSON.parse(Cookie.get("userData"));
    // const [SuccessEdit, SetSuccessEdit] = useState(false);
    const { register, handleSubmit, formState: { errors }, getValues } = useForm();



    //#endregion


    //Load Data 
    //#region
    const [data, setData] = useState([]);
    const [Usersdata, setUsers] = useState([]);
    useEffect(() => {
        const datain: {} = { email: obj.email, token: obj.token, METHOD: "GetSettings" };
        dispatch(getSettings(datain))

        const users: {} = { email: obj.email, token: obj.token, METHOD: "GetUsers" };
        dispatch(getUsers(users))

        console.log(statusUsers);

    }, [])
    useEffect(() => {
        if (status === 'success') {
            dataloaded({ id: 1, status,  successful: t('SettingsLoaded'), time: 1000, notify: true, notifyData: "test data send to notifysystem!" })
            setData(settings.settings);
        }
        if (statusUsers === 'success') {
            dataloaded({ id: 2, status: statusUsers,  successful: t('SettingsLoaded'), time: 1000, notify: true, notifyData: "test data send to notifysystem!" })
            setUsers(users.users);
        }

    }, [status, statusUsers]);
    //#endregion

    const setting = settings.settings[0];

    //Modal Selected User 
    //#region
    // const [isOpen, toggle, ModalData] = useModal();
    const [modal, setModal] = useState(false);
    const [modalData, setModalData] = useState(null);
    const toggle = () => setModal(!modal);
    const locationsbycustomerID = useSelector((state: RootState) => state.locations);
    const [locationsData, setlocationsData] = useState([]);

    useEffect(() => {
        setlocationsData([]);
        if (modalData?.id) {
            const datain: {} = { email: obj.email, token: obj.token, id: modalData?.id, METHOD: "GetUserbyId" };
            dispatch(getUser(datain))
        } else if (locationsbycustomerID.status === 'success') {
            setlocationsData(locationsbycustomerID.locations);
        }

    }, [modal, modalData])
    const convertDate = (selectDate: Date, landString: string) => {

        if (selectDate) {
            const date = new Date(selectDate);
            return date.toLocaleDateString(landString);
        } else {
            return "Geen tijd";
        }


    }


    const UserModal = () => {

        return (
            <Modal size="lg" isOpen={modal} toggle={toggle} >
                <ModalHeader>{t("User")}</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col xl={12}>
                            <Row>
                                <Col xl={2}>
                                    <InputEdit
                                        DatabaseUUID={modalData?.id}
                                        Value={modalData?.profileimg}
                                        DatabaseTableName="g_Users"
                                        DatabaseFieldName="profileimg"
                                        isHeaderText="img"
                                        isMultiLine="img"
                                        // className="profile-bg position-relative overflow-hidden"
                                        style={{ minWidth: "10em", minHeight: "10em" }}
                                    />
                                    {/* <img src={modalData?.profileimg ? modalData?.profileimg : img1} alt="blog img" className="avatar-lg h-auto rounded" /> */}
                                </Col>
                                <Col xl={3}>

                                </Col>
                                <Col xl={7}>
                                    <div className="me-1">
                                        <b>{modalData?.first_name}</b>
                                        <br />
                                        {modalData?.last_name}
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        {/* <Col xl={6}>
                            <Row>
                                <p><small>{t('Email')}</small> : <Link target='blank' to='#' onClick={() => window.location.href = "mailto:" + modalData?.email}><h6>{modalData?.email}</h6></Link></p>
                            </Row>
                            <Row>
                                {/* <p><small>{t('PhoneNumber')}</small> : {modalData?.maintenance_number}</p> 
                                <p><small>{t('PhoneNumber')}</small> : <InputEdit
                                    DatabaseUUID={modalData?.id}
                                    Value={modalData?.maintenance_number}
                                    DatabaseTableName="g_Users"
                                    DatabaseFieldName="maintenance_number"
                                    isHeaderText="h6"
                                    isMultiLine="single"
                                    className="mb-0 font-size-14"
                                /></p>
                            </Row>
                            {/* <Row>
                                <p><small>{t('MobileNumber')}</small> : {modalData?.mobilenumber} </p>
                            </Row> 
                            <Row>
                                <p><small>{t('Active')}</small> : {modalData?.account_status}</p>
                            </Row>
                        </Col> */}

                    </Row>
                    <div className='mt-5'>
                        <Row>
                            <b>Algemene gegevens</b>
                            <Col xl={12}>
                                <Card>
                                    <CardBody>
                                        <div className="d-flex align-items-center">
                                            <div className="flex-grow-1 overflow-hidden">
                                                <Col xl={6}>
                                                    <p className="mb-1 text-truncate text-muted">{t('UserAdres')}</p>
                                                    <InputEdit
                                                        DatabaseUUID={modalData?.id}
                                                        Value={modalData?.adres}
                                                        DatabaseTableName="g_Users"
                                                        DatabaseFieldName="adres"
                                                        isHeaderText="h6"
                                                        isMultiLine="single"
                                                        className="font-size-12 mb-0"
                                                    />
                                                    <p className="mb-1 text-truncate text-muted">{t('UserZipcode')}</p>
                                                    <InputEdit
                                                        DatabaseUUID={modalData?.id}
                                                        Value={modalData?.zipcode}
                                                        DatabaseTableName="g_Users"
                                                        DatabaseFieldName="zipcode"
                                                        isHeaderText="h6"
                                                        isMultiLine="single"
                                                        className="font-size-12 mb-0"
                                                    />
                                                </Col>
                                                <Col xl={6}>
                                                    <p className="mb-1 text-truncate text-muted">{t('UserCity')}</p>
                                                    <InputEdit
                                                        DatabaseUUID={modalData?.id}
                                                        Value={modalData?.city}
                                                        DatabaseTableName="g_Users"
                                                        DatabaseFieldName="city"
                                                        isHeaderText="h6"
                                                        isMultiLine="single"
                                                        className="font-size-12 mb-0"
                                                    />
                                                    <p className="mb-1 text-truncate text-muted">{t('UserCountry')}</p>
                                                    <InputEdit
                                                        DatabaseUUID={modalData?.id}
                                                        Value={modalData?.country}
                                                        DatabaseTableName="g_Users"
                                                        DatabaseFieldName="country"
                                                        isHeaderText="h6"
                                                        isMultiLine="single"
                                                        className="font-size-12 mb-0"
                                                    />
                                                </Col>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col xl={6}>
                                <Card>
                                    <CardBody>
                                        <div className="d-flex align-items-center">
                                            <div className="flex-grow-1 overflow-hidden">
                                                <p className="mb-1 text-truncate text-muted">{t('UserMaritalStatus')}</p>
                                                <InputEdit
                                                    DatabaseUUID={modalData?.id}
                                                    Value={modalData?.maritalStatus}
                                                    DatabaseTableName="g_Users"
                                                    DatabaseFieldName="maritalStatus"
                                                    isHeaderText="h6"
                                                    isMultiLine="single"
                                                    className="font-size-12 mb-0"
                                                />
                                                <p className="mb-1 text-truncate text-muted">{t('UserCitizenNumber')}</p>
                                                <InputEdit
                                                    DatabaseUUID={modalData?.id}
                                                    Value={modalData?.citizenNumber}
                                                    DatabaseTableName="g_Users"
                                                    DatabaseFieldName="citizenNumber"
                                                    isHeaderText="h6"
                                                    isMultiLine="single"
                                                    className="font-size-12 mb-0"
                                                />
                                                <p className="mb-1 text-truncate text-muted">{t('UserNamePartner')}</p>
                                                <InputEdit
                                                    DatabaseUUID={modalData?.id}
                                                    Value={modalData?.namePartner}
                                                    DatabaseTableName="g_Users"
                                                    DatabaseFieldName="namePartner"
                                                    isHeaderText="h6"
                                                    isMultiLine="single"
                                                    className="font-size-12 mb-0"
                                                />
                                                <p className="mb-1 text-truncate text-muted">{t('UserPhonePartner')}</p>
                                                <InputEdit
                                                    DatabaseUUID={modalData?.id}
                                                    Value={modalData?.phonePartner}
                                                    DatabaseTableName="g_Users"
                                                    DatabaseFieldName="phonePartner"
                                                    isHeaderText="h6"
                                                    isMultiLine="single"
                                                    className="font-size-12 mb-0"
                                                />
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col xl={6}>
                                <Card>
                                    <CardBody>
                                        <div className="d-flex align-items-center">
                                            <div className="flex-grow-1 overflow-hidden">
                                                <p className="mb-1 text-truncate text-muted">{t('Email')}</p>
                                                <h6 className="font-size-12 mb-0"><Link target='blank' to='#' className="font-size-12 mb-0" onClick={() => window.location.href = "mailto:" + modalData?.email}><h6>{modalData?.email}</h6></Link></h6>
                                                <p className="mb-1 text-truncate text-muted">{t('PhoneNumber')}</p>
                                                <h6 className="font-size-12 mb-0"><InputEdit
                                                    DatabaseUUID={modalData?.id}
                                                    Value={modalData?.maintenance_number}
                                                    DatabaseTableName="g_Users"
                                                    DatabaseFieldName="maintenance_number"
                                                    isHeaderText="h6"
                                                    isMultiLine="single"
                                                    className="font-size-12 mb-0"
                                                /></h6>
                                                <p className="mb-1 text-truncate text-muted">{t('Active')}</p>
                                                <InputEdit
                                                    DatabaseUUID={modalData?.id}
                                                    Value={modalData?.account_status}
                                                    DatabaseTableName="g_Users"
                                                    DatabaseFieldName="account_status"
                                                    isHeaderText="h6"
                                                    isMultiLine="single"
                                                    className="font-size-12 mb-0"
                                                />
                                                <p className="mb-1 text-truncate text-muted">{t('UserFunctionCompany')}</p>
                                                <InputEdit
                                                    DatabaseUUID={modalData?.id}
                                                    Value={modalData?.functionCompany}
                                                    DatabaseTableName="g_Users"
                                                    DatabaseFieldName="functionCompany"
                                                    isHeaderText="h6"
                                                    isMultiLine="single"
                                                    className="font-size-12 mb-0"
                                                />
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>

                    {/* <div className='mt-5'>
                        <Row>
                            <b>Analystics</b>
                            <Col xl={6}>
                                <Card>
                                    <CardBody>

                                        <div className="d-flex align-items-center">
                                            <div className="flex-shrink-0 me-3">
                                                <div className="avatar-sm">
                                                    <div className="avatar-title bg-soft-primary text-primary rounded-circle font-size-18">
                                                        <i className="uil uil-list-ul"></i>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="flex-grow-1 overflow-hidden">
                                                <p className="mb-1 text-truncate text-muted">{t('Amount')} {t('Locations')}</p>
                                                <h5 className="font-size-16 mb-0">{locationsbycustomerID.locations.length}</h5>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col xl={6}>
                                <Card>
                                    <CardBody>
                                        <div className="d-flex align-items-center">
                                            <div className="flex-shrink-0 me-3">
                                                <div className="avatar-sm">
                                                    <div className="avatar-title bg-soft-primary text-primary rounded-circle font-size-18">
                                                        <i className="uil uil-list-ul"></i>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="flex-grow-1 overflow-hidden">
                                                <p className="mb-1 text-truncate text-muted">{t('Amount')} {t('Receipts')}</p>
                                                <h5 className="font-size-16 mb-0">21</h5>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div> */}
                </ModalBody>
                <ModalFooter>
                    <Col>
                        <Row>
                            <div className="d-flex flex-wrap gap-2">
                                <button className="btn btn-soft-danger" onClick={toggle}>
                                    <i className="uil uil-trash-alt me-2"></i>{t("Delete")}
                                </button>
                                <button className="btn btn-soft-warning" onClick={toggle}>
                                    <i className="uil uil-times me-2"></i>{t('Close')}
                                </button>
                            </div>
                        </Row>
                        <Row>
                            <p><small> {t('CreationDate')} : <b>{convertDate(modalData?.creationdate, 'nl-NL')}</b> by <b>{modalData?.createdby}</b></small></p>
                        </Row>
                    </Col>
                </ModalFooter>
            </Modal>
        )
    }
    //#endregion

    //Modal add new User 
    //#region
    // const [isOpen, toggle, ModalData] = useModal();
    const [modalnew, setnewModal] = useState(false);
    const [modalnewData, setModalnewData] = useState(null);
    const togglenew = () => setnewModal(!modalnew);

    interface IFormValues {
        companyname: string,
        streetname: string,
        zipcode: string,
        city: string,
        country: string,
        phonenumber: string,
        mobilenumber: string,
        email: string,
        banknumber: string,
        VATnumber: string,
        VATrate: number,
        gAccount: string,
        payterms: number,
        invoiceemail: string,
        createdby: string
    }

    const onSubmit: SubmitHandler<IFormValues> = (data) => {
        console.log(data);

        let formDataObject = Object.fromEntries(Object.entries(data));
        // Format the plain form data as JSON
        let formDataJsonString = JSON.stringify(formDataObject);
        const datain: {} = { email: obj.email, token: obj.token, dataUpload: formDataJsonString, METHOD: "setAddUser" };
        const returning = dispatch(addUser(datain))
        console.log(datain);

        dataloaded({ id: 15, status: status, error: errors.toString(), successful: t('successMessageAdding', { mail: obj.email, company: getValues('first_name') }), time: 1000 })
        if (returning && status === 'success') {
            const dataNotify: {} = {
                email: obj.email, token: obj.token, dataN: JSON.stringify({
                    action: t('successMessageAdding', { mail: obj.email, company: getValues('first_name') }),
                    user: obj.email,
                    readed: 0,
                    rawdata: datain.toString(),
                    status: "Success"
                }), METHOD: "setNotify"
            }
            dispatch(setNotifybyID(dataNotify));
            // console.log(dataNotify);
            //   history.push({
            //     pathname: '/Customers/',
            //     state: {}
            //   })
        }
    }


    const UserAddModal = () => {

        return (
            <Modal isOpen={modalnew} toggle={togglenew} >
                <ModalHeader>{t("User")} {t("Add")}</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col xl={12}>
                            <div className="mb-3">
                                <Label
                                    htmlFor="basicpill-first_name-input"
                                    className="form-label"
                                >
                                    {t("Firstname")}
                                </Label>
                                <InputGroup>
                                    {errors.companyname?.type === "required" && (
                                        <div className="input-group-text-danger">
                                            !
                                        </div>)}
                                    <input
                                        {...register("first_name", { required: true })}
                                        type="text"
                                        required
                                        className="form-control "
                                        id="basicpill-first_name-input"
                                        aria-invalid={errors.first_name ? "true" : "false"}
                                    />
                                </InputGroup>
                                {errors.first_name?.type === "required" && (
                                    <p>
                                        <Alert color="danger mt-2">{t('required', { labelreq: t("Firstname") })}</Alert>
                                    </p>
                                )}
                            </div>
                            <div className="mb-3">
                                <Label
                                    htmlFor="basicpill-last_name-input"
                                    className="form-label"
                                >
                                    {t("Lastname")}
                                </Label>
                                <InputGroup>
                                    {errors.companyname?.type === "required" && (
                                        <div className="input-group-text-danger">
                                            !
                                        </div>)}
                                    <input
                                        {...register("last_name", { required: true })}
                                        type="text"
                                        required
                                        className="form-control "
                                        id="basicpill-last_name-input"
                                        aria-invalid={errors.last_name ? "true" : "false"}
                                    />
                                </InputGroup>
                                {errors.last_name?.type === "required" && (
                                    <p>
                                        <Alert color="danger mt-2">{t('required', { labelreq: t("Lastname") })}</Alert>
                                    </p>
                                )}
                            </div>
                            <div className="mb-3">
                                <Label
                                    htmlFor="basicpill-phone-input"
                                    className="form-label"
                                >
                                    {t("PhoneNumber")}
                                </Label>
                                <InputGroup>
                                    {errors.phone?.type === "required" && (
                                        <div className="input-group-text-danger">
                                            !
                                        </div>)}
                                    <input
                                        {...register("phone", { required: true })}
                                        type="text"
                                        required
                                        className="form-control "
                                        id="basicpill-phone-input"
                                        aria-invalid={errors.phone ? "true" : "false"}
                                    />
                                </InputGroup>
                                {errors.phone?.type === "required" && (
                                    <p>
                                        <Alert color="danger mt-2">{t('required', { labelreq: t("PhoneNumber") })}</Alert>
                                    </p>
                                )}
                            </div>
                            <div className="mb-3">
                                <Label
                                    htmlFor="basicpill-email-input"
                                    className="form-label"
                                >
                                    {t("Email")}
                                </Label>
                                <InputGroup>
                                    {errors.email?.type === "required" && (
                                        <div className="input-group-text-danger">
                                            !
                                        </div>)}
                                    <input
                                        {...register("email", { required: true })}
                                        type="text"
                                        required
                                        className="form-control "
                                        id="basicpill-email-input"
                                        aria-invalid={errors.email ? "true" : "false"}
                                    />
                                </InputGroup>
                                {errors.email?.type === "required" && (
                                    <p>
                                        <Alert color="danger mt-2">{t('required', { labelreq: t("Email") })}</Alert>
                                    </p>
                                )}
                            </div>
                            <div className="mb-3">
                                <Label
                                    htmlFor="basicpill-password-input"
                                    className="form-label"
                                >
                                    {t("Password")}
                                </Label>
                                <InputGroup>
                                    {errors.companyname?.type === "required" && (
                                        <div className="input-group-text-danger">
                                            !
                                        </div>)}
                                    <input
                                        {...register("password", { required: true })}
                                        type="text"
                                        required
                                        className="form-control "
                                        id="basicpill-password-input"
                                        aria-invalid={errors.password ? "true" : "false"}
                                    />
                                </InputGroup>
                                {errors.password?.type === "required" && (
                                    <p>
                                        <Alert color="danger mt-2">{t('required', { labelreq: t("Password") })}</Alert>
                                    </p>
                                )}
                            </div>
                        </Col>
                    </Row>


                    {/* <div className='mt-5'>
                        <Row>
                            <b>Analystics</b>
                            <Col xl={6}>
                                <Card>
                                    <CardBody>

                                        <div className="d-flex align-items-center">
                                            <div className="flex-shrink-0 me-3">
                                                <div className="avatar-sm">
                                                    <div className="avatar-title bg-soft-primary text-primary rounded-circle font-size-18">
                                                        <i className="uil uil-list-ul"></i>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="flex-grow-1 overflow-hidden">
                                                <p className="mb-1 text-truncate text-muted">{t('Amount')} {t('Locations')}</p>
                                                <h5 className="font-size-16 mb-0">{locationsbycustomerID.locations.length}</h5>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col xl={6}>
                                <Card>
                                    <CardBody>
                                        <div className="d-flex align-items-center">
                                            <div className="flex-shrink-0 me-3">
                                                <div className="avatar-sm">
                                                    <div className="avatar-title bg-soft-primary text-primary rounded-circle font-size-18">
                                                        <i className="uil uil-list-ul"></i>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="flex-grow-1 overflow-hidden">
                                                <p className="mb-1 text-truncate text-muted">{t('Amount')} {t('Receipts')}</p>
                                                <h5 className="font-size-16 mb-0">21</h5>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div> */}
                </ModalBody>
                <ModalFooter>
                    <Col>
                        <Row>
                            <div className="d-flex flex-wrap gap-2">
                                <button className="btn btn-soft-success" onClick={handleSubmit(onSubmit)}>
                                    <i className="uil uil-trash-alt me-2"></i>{t("Add")}
                                </button>
                                <button className="btn btn-soft-warning" onClick={togglenew}>
                                    <i className="uil uil-times me-2"></i>{t('Cancel')}
                                </button>
                            </div>
                        </Row>
                        {/* <Row>
                            <p><small> {t('CreationDate')} : <b>{convertDate(modalData?.creationdate, 'nl-NL')}</b> by <b>{modalData?.createdby}</b></small></p>
                        </Row> */}
                    </Col>
                </ModalFooter>
            </Modal>
        )
    }
    //#endregion

    //USER LIST
    //#region
    const listUsers = (Usersdata || []).map((detail: any, keyLocation: number) =>
        <li key={keyLocation} className="list-group-item py-3 px-0">
            <div className="d-flex align-items-center">
                <div className="flex-shrink-0 me-3">
                    <div>
                        <img src={detail.profileimg ? detail.profileimg : img1} alt="blog img" className="avatar-lg h-auto rounded" />
                    </div>
                </div>
                <div className="flex-grow-1 overflow-hidden">
                    <h5 className="font-size-14 mb-1 text-truncate"><i className="uil uil-user me-1"></i> <Link to="#" onClick={() => { setModal(!modal); setModalData(detail); }} className="text-dark">{detail.first_name} {detail.last_name}</Link></h5>
                    <ul className="list-inline font-size-13 text-muted">
                        <li className="list-inline-item">
                            <Link target='blank' to='#' onClick={() => window.location.href = "mailto:" + detail.email} className="text-muted">
                                <i className="uil uil-fast-mail me-1"></i> {detail.email}
                            </Link>
                        </li>{" "}
                        <li className="list-inline-item">
                            {detail.account_status === 'active' ? <i className="uil uil-bolt me-1"></i> : <i className="uil uil-bolt-slash me-1"></i>} {detail.account_status}
                        </li>
                    </ul>
                </div>
                <div className="flex-shrink-0 ms-2">

                </div>
            </div>
        </li>)
    //#endregion


    useMetaTag('Settings | AWEKA Systeem', 'Locaties AWEKA');

    return (
        <React.Fragment>
            <UserModal />
            <UserAddModal />
            <div className="page-content">
                {/* <MetaTags>
                    <title>{t('Settings')} | AWEKA Energy Solutions</title>
                </MetaTags> */}
                {data &&
                    <Container fluid>
                        <div className="mx-n4 mt-n4">
                            <Row>
                                <Col lg={12}>
                                    <Card className="mb-0">
                                        <CardBody className="p-0">
                                            <div className="profile-bg position-relative overflow-hidden">
                                                <div className="bg-overlay bg-dark bg-gradient"></div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </div>

                        <Row>
                            <Col xl={3}>
                                <Card className="mt-n5">
                                    <CardBody className="text-center">
                                        {/* <div className="text-end">
                                        <UncontrolledDropdown>
                                            <DropdownToggle
                                                type="button"
                                                className="btn btn-link text-dark font-size-16 p-1 shadow-none"
                                                tag="a"
                                            >
                                                <i className="uil uil-ellipsis-h"></i>
                                            </DropdownToggle>
                                            <DropdownMenu className="dropdown-menu-end">
                                                <DropdownItem to="#">Action</DropdownItem>
                                                <DropdownItem to="#">Another action</DropdownItem>
                                                <DropdownItem to="#">Something else here</DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </div> */}
                                        <h5 className="mb-1">{t('Settings')} </h5>
                                        {/* <p className="text-muted">{data?.[0]['companyname']}</p> */}

                                        {/* <div><i className="mdi mdi-wallet text-muted font-size-16 align-middle me-1"></i> <span className="align-middle">Balance : <b className="fw-semibold font-size-16">$6951.02</b></span></div> */}
                                    </CardBody>
                                    {/* <div className="d-flex border-top p-2">
                                    <div className="flex-grow-1">
                                        <div className="p-2 ps-3"></div>
                                    </div>
                                    <div className="flex-shrink-0">
                                        <button type="button" className="btn btn-light"><i className="uil uil-envelope-alt me-1"></i> Message</button>
                                    </div> 
                                </div> */}
                                </Card>


                                <Card>
                                    <CardBody>
                                        <ul className="list-unstyled mb-0">
                                            <li className="pb-3">
                                                <div className="d-flex align-items-center">
                                                    <div className="font-size-20 text-primary flex-shrink-0 me-3">
                                                        <i className="uil uil-globe"></i>
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <p className="text-muted mb-1 font-size-13">Website</p>
                                                        <InputEdit
                                                            DatabaseUUID={1}
                                                            Value={setting?.website}
                                                            DatabaseTableName="g_Settings"
                                                            DatabaseFieldName="website"
                                                            isHeaderText='h5'
                                                            isMultiLine="single"
                                                            className="mb-0 font-size-14"

                                                        />
                                                        {/* <h5 className="mb-0 font-size-14">{data[0]['website']}</h5> */}
                                                    </div>
                                                </div>
                                            </li>

                                            <li className="py-3">
                                                <div className="d-flex align-items-center">
                                                    <div className="font-size-20 text-primary flex-shrink-0 me-3">
                                                        <i className="uil uil-envelope-alt"></i>
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <p className="text-muted mb-1 font-size-13">Email</p>
                                                        <InputEdit
                                                            DatabaseUUID={1}
                                                            Value={setting?.email}
                                                            DatabaseTableName="g_Settings"
                                                            DatabaseFieldName="email"
                                                            isHeaderText='h5'
                                                            isMultiLine="single"
                                                            className="mb-0 font-size-14"

                                                        />
                                                        {/* <h5 className="mb-0 font-size-14">{data[0]['email']}</h5> */}
                                                    </div>
                                                </div>
                                            </li>

                                            <li className="py-3">
                                                <div className="d-flex align-items-center">
                                                    <div className="font-size-20 text-primary flex-shrink-0 me-3">
                                                        <i className="uil uil-envelope-alt"></i>
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <p className="text-muted mb-1 font-size-13">Phonenumber</p>
                                                        <InputEdit
                                                            DatabaseUUID={1}
                                                            Value={setting?.phone}
                                                            DatabaseTableName="g_Settings"
                                                            DatabaseFieldName="phone"
                                                            isHeaderText='h5'
                                                            isMultiLine="single"
                                                            className="mb-0 font-size-14"

                                                        />
                                                        {/* <h5 className="mb-0 font-size-14"></h5> */}
                                                    </div>
                                                </div>
                                            </li>

                                            <li className="py-3">
                                                <div className="d-flex align-items-center">
                                                    <div className="font-size-20 text-primary flex-shrink-0 me-3">
                                                        <i className="uil uil-map-marker"></i>
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <p className="text-muted mb-1 font-size-13">Location</p>
                                                        <InputEdit
                                                            DatabaseUUID={1}
                                                            Value={setting?.adress}
                                                            DatabaseTableName="g_Settings"
                                                            DatabaseFieldName="adress"
                                                            isHeaderText='h5'
                                                            isMultiLine="single"
                                                            className="mb-0 font-size-14"

                                                        />
                                                        <InputEdit
                                                            DatabaseUUID={1}
                                                            Value={setting?.zipcode}
                                                            DatabaseTableName="g_Settings"
                                                            DatabaseFieldName="zipcode"
                                                            isHeaderText='h5'
                                                            isMultiLine="single"
                                                            className="mb-0 font-size-14"

                                                        />
                                                        <InputEdit
                                                            DatabaseUUID={1}
                                                            Value={setting?.city}
                                                            DatabaseTableName="g_Settings"
                                                            DatabaseFieldName="city"
                                                            isHeaderText='h5'
                                                            isMultiLine="single"
                                                            className="mb-0 font-size-14"

                                                        />
                                                        <InputEdit
                                                            DatabaseUUID={1}
                                                            Value={setting?.country}
                                                            DatabaseTableName="g_Settings"
                                                            DatabaseFieldName="country"
                                                            isHeaderText='h5'
                                                            isMultiLine="single"
                                                            className="mb-0 font-size-14"

                                                        />
                                                        {/* <h5 className="mb-0 font-size-14"></h5> */}
                                                    </div>
                                                </div>
                                            </li>

                                        </ul>
                                    </CardBody>
                                </Card>

                                {/* <Card>
                                <CardBody>
                                    <div>
                                        <ul className="list-unstyled mb-0 text-muted">
                                            <li>
                                                <div className="d-flex align-items-center py-2">
                                                    <div className="flex-grow-1">
                                                        <i className="mdi mdi-github font-size-16 text-dark me-1"></i> Github
                                                    </div>
                                                    <div className="flex-shrink-0">
                                                        <div>dashonic</div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="d-flex align-items-center py-2">
                                                    <div className="flex-grow-1">
                                                        <i className="mdi mdi-twitter font-size-16 text-info me-1"></i> Twitter
                                                    </div>
                                                    <div className="flex-shrink-0">
                                                        <div>@dashonic</div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="d-flex align-items-center py-2">
                                                    <div className="flex-grow-1">
                                                        <i className="mdi mdi-linkedin font-size-16 text-primary me-1"></i> Linkedin
                                                    </div>
                                                    <div className="flex-shrink-0">
                                                        <div>dashonic</div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="d-flex align-items-center py-2">
                                                    <div className="flex-grow-1">
                                                        <i className="mdi mdi-pinterest font-size-16 text-danger me-1"></i> Pinterest
                                                    </div>
                                                    <div className="flex-shrink-0">
                                                        <div>dashonic</div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </CardBody>
                            </Card> */}
                            </Col>

                            <Col xl={9}>
                                <div className="mt-4">
                                    <Row>
                                        <Col lg={8}>
                                            <Card>
                                                <CardBody>
                                                    <div className="row">
                                                        <h5 className="card-title col mb-3">{t('Users')}</h5>
                                                        <div className="col text-end mb-3">
                                                            <UncontrolledDropdown>
                                                                <DropdownToggle
                                                                    type="button"
                                                                    className="btn btn-link text-dark font-size-16 p-1 shadow-none"
                                                                    tag="a"
                                                                >
                                                                    <i className="uil uil-ellipsis-h"></i>
                                                                </DropdownToggle>
                                                                <DropdownMenu className="dropdown-menu-end">
                                                                    <DropdownItem to="#" onClick={() => setnewModal(!modalnew)}>{t('User')} {t('Add')}</DropdownItem>
                                                                </DropdownMenu>
                                                            </UncontrolledDropdown>
                                                        </div>
                                                    </div>
                                                    <SimpleBar style={{ maxHeight: "250px" }}>
                                                        <ul className="list-group list-group-flush">
                                                            {Usersdata ? listUsers : "Geen Locaties gevonden"}
                                                            {/* {locationsData ? const listItem = (data || []).map((detail: any, key: number) => 
                                                        <li key={key} className="list-group-item py-3 px-0">
                                                            <div className="d-flex align-items-center">
                                                                <div className="flex-shrink-0 me-3">
                                                                    <div>
                                                                    <MyMapComponent isMarkerShown={false} street={detail.locationadress} city={detail.locationcity} 
                                                                     googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places"
                                                                     loadingElement={<div style={{ height: `100%` }} />}
                                                                     containerElement={<div style={{ height: `400px` }} />}
                                                                     mapElement={<div style={{ height: `100%` }} />}/>
                                                                       
                                                                    </div>
                                                                </div>
                                                                <div className="flex-grow-1 overflow-hidden">
                                                                    <h5 className="font-size-14 mb-1 text-truncate"><Link to="#" className="text-dark">{detail.locationname}</Link></h5>
                                                                    <ul className="list-inline font-size-13 text-muted">
                                                                        <li className="list-inline-item">
                                                                            <Link to="#" className="text-muted">
                                                                                <i className="uil uil-user me-1"></i> {detail.locationname}
                                                                            </Link>
                                                                        </li>{" "}
                                                                        <li className="list-inline-item">
                                                                            <i className="uil uil-calender me-1"></i> {detail.locationadress}, {detail.locationzipcode} {detail.locationcity}
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                                <div className="flex-shrink-0 ms-2">
                                                                    <UncontrolledDropdown>
                                                                        <DropdownToggle
                                                                            type="button"
                                                                            className="btn btn-link text-dark font-size-16 p-1 shadow-none"
                                                                            tag="a"
                                                                        >
                                                                            <i className="uil uil-ellipsis-h"></i>
                                                                        </DropdownToggle>
                                                                        <DropdownMenu className="dropdown-menu-end">
                                                                            <DropdownItem to="#">Action</DropdownItem>
                                                                            <DropdownItem to="#">Another action</DropdownItem>
                                                                            <DropdownItem to="#">Something else here</DropdownItem>
                                                                        </DropdownMenu>
                                                                    </UncontrolledDropdown>
                                                                </div>
                                                            </div>
                                                        </li>)): setlocationsData(locationsbycustomerID)} */}
                                                        </ul>
                                                    </SimpleBar>

                                                </CardBody>
                                            </Card>

                                        </Col>

                                        <div className="col-lg-4">
                                            {/* <Card>
                                            <CardBody>
                                                <h5 className="card-title mb-4">Skills</h5>

                                                <div className="d-flex flex-wrap gap-2 font-size-16">
                                                    <Link to="#" className="badge badge-soft-primary">Photoshop</Link>
                                                    <Link to="#" className="badge badge-soft-primary">illustrator</Link>
                                                    <Link to="#" className="badge badge-soft-primary">HTML</Link>
                                                    <Link to="#" className="badge badge-soft-primary">CSS</Link>
                                                    <Link to="#" className="badge badge-soft-primary">Javascript</Link>
                                                    <Link to="#" className="badge badge-soft-primary">Php</Link>
                                                    <Link to="#" className="badge badge-soft-primary">Python</Link>
                                                </div>
                                            </CardBody>
                                        </Card> */}

                                            <Card>
                                                <CardBody>
                                                    <h5 className="card-title mb-3">{t('ExactOnline')}</h5>

                                                    <div>
                                                        <ul className="list-unstyled mb-0">
                                                            <li className="pb-3">
                                                                <div className="d-flex align-items-center">
                                                                    <div className="font-size-20 text-primary flex-shrink-0 me-3">
                                                                        <i className="uil uil-globe"></i>
                                                                    </div>
                                                                    <div className="flex-grow-1">
                                                                        <p className="text-muted mb-1 font-size-13">{t('ExactUser')}</p>
                                                                        <InputEdit
                                                                            DatabaseUUID={1}
                                                                            Value={setting?.exactUser}
                                                                            DatabaseTableName="g_Settings"
                                                                            DatabaseFieldName="exactUser"
                                                                            isHeaderText='h5'
                                                                            isMultiLine="single"
                                                                            className="mb-0 font-size-14"

                                                                        />
                                                                        {/* <h5 className="mb-0 font-size-14"></h5> */}
                                                                    </div>
                                                                </div>
                                                            </li>

                                                            <li className="py-3">
                                                                <div className="d-flex align-items-center">
                                                                    <div className="font-size-20 text-primary flex-shrink-0 me-3">
                                                                        <i className="uil uil-envelope-alt"></i>
                                                                    </div>
                                                                    <div className="flex-grow-1">
                                                                        <p className="text-muted mb-1 font-size-13">{t('ExactToken')}</p>
                                                                        <InputEdit
                                                                            DatabaseUUID={1}
                                                                            Value={setting?.exactToken}
                                                                            DatabaseTableName="g_Settings"
                                                                            DatabaseFieldName="exactToken"
                                                                            isHeaderText='h5'
                                                                            isMultiLine="single"
                                                                            className="mb-0 font-size-14"

                                                                        />
                                                                        {/* <h5 className="mb-0 font-size-14"></h5> */}
                                                                    </div>
                                                                </div>
                                                            </li>

                                                            {/* <li className="py-3">
                                                            <div className="d-flex align-items-center">
                                                                <div className="font-size-20 text-primary flex-shrink-0 me-3">
                                                                    <i className="uil uil-envelope-alt"></i>
                                                                </div>
                                                                <div className="flex-grow-1">
                                                                    <p className="text-muted mb-1 font-size-13">Phonenumber</p>
                                                                    <h5 className="mb-0 font-size-14"></h5>
                                                                </div>
                                                            </div>
                                                        </li>

                                                        <li className="py-3">
                                                            <div className="d-flex align-items-center">
                                                                <div className="font-size-20 text-primary flex-shrink-0 me-3">
                                                                    <i className="uil uil-map-marker"></i>
                                                                </div>
                                                                <div className="flex-grow-1">
                                                                    <p className="text-muted mb-1 font-size-13">Location</p>
                                                                    <h5 className="mb-0 font-size-14"></h5>
                                                                </div>
                                                            </div>
                                                        </li> */}

                                                        </ul>
                                                    </div>
                                                </CardBody>
                                            </Card>


                                        </div>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                }
            </div>

        </React.Fragment>
    );
};

export default MainSettings;

