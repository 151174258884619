import { createAsyncThunk } from "@reduxjs/toolkit";
import { getLayouts as getLayoutsApi, SetLayoutbyID as setLayoutbyIDApi } from "../../helpers/real_helper";

export const getToolsLayouts = createAsyncThunk('tools/getLayouts', async (layouts:{}, {rejectWithValue}) => {
    try {
      const res = await getLayoutsApi(layouts);
      //console.log(res);
      return res;
    } catch (err) {
      return rejectWithValue({ error: err.message })
    }
  });

  export const getToolsLayoutsbyID = createAsyncThunk('tools/getLayoutsbyID', async (layouts:{}, {rejectWithValue}) => {
    try {
      // const res = await getLayoutsbyIDApi(layouts);
      // //console.log(res);
      // return res;
      return layouts
    } catch (err) {
      return rejectWithValue({ error: err.message })
    }
  });

  // export const setSelectedToolLayout = createAsyncThunk('tools/SetSelectLayout', async (layouts:{}, {rejectWithValue}) => {

  //   try {
  //     //console.log(layouts);
  //     return layouts
      
      
  //   } catch (err) {
  //     return rejectWithValue({ error: err.message})
  //   }
  // });


  export const setToolsLayoutbyID = createAsyncThunk('tools/SetToolsLayoutbyID',async (layouts:{}, {rejectWithValue}) => {
    try {
          const res = await setLayoutbyIDApi(layouts)
          return res;
    } catch (err) {
      return rejectWithValue({error: err.message})
    }
  })
  // export const getCustomer = createAsyncThunk('customer/getCustomer', async (customer:{}, {rejectWithValue}) => {
  //   try {
  //     const res = await getCustomerApi(customer);
  //     //console.log(res);
  //     return res;
  //   } catch (err) {
  //     return rejectWithValue({ error: err.message })
  //   }
  // })

