import React from "react";
import MetaTags from "react-meta-tags";
import { Container, CardHeader, Card, Row, Col} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import BasicPills from "./CustomerAddWizard";
import { useTranslation } from "react-i18next";

const CustomerAdd = () => {
 const { t } = useTranslation();
  return (
    <React.Fragment>
      <div className="page-content">
        {/* <MetaTags>
          <title></title>
        </MetaTags> */}
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title={t("Relation")} breadcrumbItem={[{active: true, urlLink: "#", label: t('Add')}]} />

          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <h4 className="card-title">{t("Customer")}</h4>
                </CardHeader>
                <BasicPills />
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CustomerAdd;
