

export const CountryData = [
    {
      label: "-------------- Europa ------------",
      options: [
        { label: "Aland-eilanden", value: "AX" },
        { label: "Albanië", value: "AL" },
        { label: "Andorra", value: "AD" },
        { label: "Oostenrijk", value: "AT" },
        { label: "Wit-Rusland", value: "BY" },
        { label: "Belgie", value: "BE", mask: "9999" },
        { label: "Bosnië-Herzegovina", value: "BA" },
        { label: "Bulgarije", value: "BG" },
        { label: "Kroatië", value: "HR" },
        { label: "Tsjechië", value: "CZ" },
        { label: "Denemarken", value: "DK" },
        { label: "Estland", value: "EE" },
        { label: "Faeröer", value: "FO" },
        { label: "Finland", value: "FI" },
        { label: "Frankrijk", value: "FR" },
        { label: "Duitsland", value: "DE" },
        { label: "Gibraltar", value: "GI" },
        { label: "Griekenland", value: "GR" },
        { label: "Guernsey", value: "GG" },
        { label: "Heilige Stoel (Vaticaanstad)", value: "VA" },
        { label: "Hongarije", value: "HU" },
        { label: "IJsland", value: "IS" },
        { label: "Ierland", value: "IE" },
        { label: "Isle of Man", value: "IM" },
        { label: "Italië", value: "IT" },
        { label: "Jersey", value: "JE" },
        { label: "Kosovo", value: "XK" },
        { label: "Letland", value: "LV" },
        { label: "Liechtenstein", value: "LI" },
        { label: "Litouwen", value: "LT" },
        { label: "Luxemburg", value: "LU" },
        { label: "Macedonië, de voormalige Joegoslavische Republiek", value: "MK" },
        { label: "Malta", value: "MT" },
        { label: "Moldavië, Republiek", value: "MD" },
        { label: "Monaco", value: "MC" },
        { label: "Montenegro", value: "ME" },
        { label: "Nederland", value: "NL", mask: "9999 aa" },
        { label: "Noorwegen", value: "NO" },
        { label: "Polen", value: "PL" },
        { label: "Portugal", value: "PT" },
        { label: "Roemenië", value: "RO" },
        { label: "San Marino", value: "SM" },
        { label: "Servië", value: "RS" },
        { label: "Servië en Montenegro", value: "CS" },
        { label: "Slowakije", value: "SK" },
        { label: "Slovenië", value: "SI" },
        { label: "Spanje", value: "ES" },
        { label: "Svalbard en Jan Mayen", value: "SJ" },
        { label: "Zweden", value: "SE" },
        { label: "Zwitserland", value: "CH" },
        { label: "Oekraïne", value: "UA" },
        { label: "Verenigd Koningkrijk", value: "GB" }
      ],
    },
    {
      label: "-------------- Noord Amerika ------------",
      options: [
        { label: "Anguilla", value: "AI" },
        { label: "Antigua en Barbuda", value: "AG" },
        { label: "Aruba", value: "AW" },
        { label: "Bahamas", value: "BS" },
        { label: "Barbados", value: "BB" },
        { label: "Belize", value: "BZ" },
        { label: "Bermuda", value: "BM" },
        { label: "Bonaire, Sint Eustatius en Saba", value: "BQ" },
        { label: "Canada", value: "CA" },
        { label: "Kaaiman Eilanden", value: "KY" },
        { label: "Costa Rica", value: "CR" },
        { label: "Cuba", value: "CU" },
        { label: "Curacao", value: "CW" },
        { label: "Dominica", value: "DM" },
        { label: "Dominicaanse Republiek", value: "DO" },
        { label: "El Salvador", value: "SV" },
        { label: "Groenland", value: "GL" },
        { label: "Grenada", value: "GD" },
        { label: "Guadeloupe", value: "GP" },
        { label: "Guatemala", value: "GT" },
        { label: "Haïti", value: "HT" },
        { label: "Honduras", value: "HN" },
        { label: "Jamaica", value: "JM" },
        { label: "Martinique", value: "MQ" },
        { label: "Mexico", value: "MX" },
        { label: "Montserrat", value: "MS" },
        { label: "Nederlandse Antillen", value: "AN" },
        { label: "Nicaragua", value: "NI" },
        { label: "Panama", value: "PA" },
        { label: "Puerto Rico", value: "PR" },
        { label: "Sint-Bartholomeus", value: "BL" },
        { label: "Saint Kitts en Nevis", value: "KN" },
        { label: "Saint Lucia", value: "LC" },
        { label: "Sint-Maarten", value: "MF" },
        { label: "Saint Pierre en Miquelon", value: "PM" },
        { label: "Saint Vincent en de Grenadines", value: "VC" },
        { label: "Sint Maarten", value: "SX" },
        { label: "Trinidad en Tobago", value: "TT" },
        { label: "Turks- en Caicoseilanden", value: "TC" },
        { label: "Verenigde Staten", value: "US", mask: "99999-9999" },
        { label: "Kleine afgelegen eilanden van de Verenigde Staten", value: "UM" },
        { label: "Britse Maagdeneilanden", value: "VG" },
        { label: "Maagdeneilanden, U.s.", value: "VI" }
      ],
    },
    {
      label: "-------------- Zuid Amerika ------------",
      options: [
        { label: "Argentinië", value: "AR" },
        { label: "Bolivia", value: "BO" },
        { label: "Brazilië", value: "BR" },
        { label: "Chili", value: "CL" },
        { label: "Colombia", value: "CO" },
        { label: "Ecuador", value: "EC" },
        { label: "Falklandeilanden (Malvinas)", value: "FK" },
        { label: "Frans Guyana", value: "GF" },
        { label: "Guyana", value: "GY" },
        { label: "Paraguay", value: "PY" },
        { label: "Peru", value: "PE" },
        { label: "Suriname", value: "SR" },
        { label: "Uruguay", value: "UY" },
        { label: "Venezuela", value: "VE" }
      ],
    },
    {
      label: "-------------- Afrika ------------",
      options: [
        { label: "Algerije", value: "DZ" },
        { label: "Angola", value: "AO" },
        { label: "Benin", value: "BJ" },
        { label: "Botswana", value: "BW" },
        { label: "Burkina Faso", value: "BF" },
        { label: "Burundi", value: "BI" },
        { label: "Kameroen", value: "CM" },
        { label: "Kaapverdië", value: "CV" },
        { label: "Centraal Afrikaanse Republiek", value: "CF" },
        { label: "Tsjaad", value: "TD" },
        { label: "Comoren", value: "KM" },
        { label: "Congo", value: "CG" },
        { label: "Congo, Democratische Republiek Congo", value: "CD" },
        { label: "Ivoorkust", value: "CI" },
        { label: "Djibouti", value: "DJ" },
        { label: "Egypte", value: "EG" },
        { label: "Equatoriaal-Guinea", value: "GQ" },
        { label: "Eritrea", value: "ER" },
        { label: "Ethiopië", value: "ET" },
        { label: "Gabon", value: "GA" },
        { label: "Gambia", value: "GM" },
        { label: "Ghana", value: "GH" },
        { label: "Guinea", value: "GN" },
        { label: "Guinee-Bissau", value: "GW" },
        { label: "Kenia", value: "KE" },
        { label: "Lesotho", value: "LS" },
        { label: "Liberia", value: "LR" },
        { label: "Libië", value: "LY" },
        { label: "Madagascar", value: "MG" },
        { label: "Malawi", value: "MW" },
        { label: "Mali", value: "ML" },
        { label: "Mauritanië", value: "MR" },
        { label: "Mauritius", value: "MU" },
        { label: "Mayotte", value: "YT" },
        { label: "Marokko", value: "MA" },
        { label: "Mozambique", value: "MZ" },
        { label: "Namibië", value: "NA" },
        { label: "Niger", value: "NE" },
        { label: "Nigeria", value: "NG" },
        { label: "Bijeenkomst", value: "RE" },
        { label: "Rwanda", value: "RW" },
        { label: "Sint-Helena", value: "SH" },
        { label: "Sao Tomé en Principe", value: "ST" },
        { label: "Senegal", value: "SN" },
        { label: "Seychellen", value: "SC" },
        { label: "Sierra Leone", value: "SL" },
        { label: "Somalië", value: "SO" },
        { label: "Zuid-Afrika", value: "ZA" },
        { label: "Zuid Soedan", value: "SS" },
        { label: "Soedan", value: "SD" },
        { label: "Swaziland", value: "SZ" },
        { label: "Tanzania, Verenigde Republiek", value: "TZ" },
        { label: "Gaan", value: "TG" },
        { label: "Tunesië", value: "TN" },
        { label: "Oeganda", value: "UG" },
        { label: "Westelijke Sahara", value: "EH" },
        { label: "Zambia", value: "ZM" },
        { label: "Zimbabwe", value: "ZW" }
      ],
    },
    {
      label: "-------------- Azië ------------",
      options: [
        { label: "Afghanistan", value: "AF" },
        { label: "Armenië", value: "AM" },
        { label: "Azerbeidzjan", value: "AZ" },
        { label: "Bahrein", value: "BH" },
        { label: "Bangladesh", value: "BD" },
        { label: "Bhutan", value: "BT" },
        { label: "Brits-Indisch oceaan gebied", value: "IO" },
        { label: "Brunei Darussalam", value: "BN" },
        { label: "Cambodja", value: "KH" },
        { label: "China", value: "CN" },
        { label: "Kersteiland", value: "CX" },
        { label: "Cocos (Keeling) eilanden", value: "CC" },
        { label: "Cyprus", value: "CY" },
        { label: "Georgië", value: "GE" },
        { label: "Hong Kong", value: "HK" },
        { label: "India", value: "IN" },
        { label: "Indonesië", value: "ID" },
        { label: "Iran, Islamitische Republiek", value: "IR" },
        { label: "Irak", value: "IQ" },
        { label: "Israël", value: "IL" },
        { label: "Japan", value: "JP" },
        { label: "Jordanië", value: "JO" },
        { label: "Kazachstan", value: "KZ" },
        { label: "Korea, Democratische Volksrepubliek", value: "KP" },
        { label: "Korea, republiek van", value: "KR" },
        { label: "Koeweit", value: "KW" },
        { label: "Kirgizië", value: "KG" },
        { label: "Lao Democratische Volksrepubliek", value: "LA" },
        { label: "Libanon", value: "LB" },
        { label: "Macao", value: "MO" },
        { label: "Maleisië", value: "MY" },
        { label: "Maldiven", value: "MV" },
        { label: "Mongolië", value: "MN" },
        { label: "Myanmar", value: "MM" },
        { label: "Nepal", value: "NP" },
        { label: "Oman", value: "OM" },
        { label: "Pakistan", value: "PK" },
        { label: "Palestijns gebied, bezet", value: "PS" },
        { label: "Filippijnen", value: "PH" },
        { label: "Qatar", value: "QA" },
        { label: "Russische Federatie", value: "RU" },
        { label: "Saoedi-Arabië", value: "SA" },
        { label: "Singapore", value: "SG" },
        { label: "Sri Lanka", value: "LK" },
        { label: "Syrische Arabische Republiek", value: "SY" },
        { label: "Taiwan, provincie China", value: "TW" },
        { label: "Tadzjikistan", value: "TJ" },
        { label: "Thailand", value: "TH" },
        { label: "Oost-Timor", value: "TL" },
        { label: "kalkoen", value: "TR" },
        { label: "Turkmenistan", value: "TM" },
        { label: "Verenigde Arabische Emiraten", value: "AE" },
        { label: "Oezbekistan", value: "UZ" },
        { label: "Vietnam", value: "VN" },
        { label: "Jemen", value: "YE" }
      ],
    },
    {
      label: "-------------- Australië ------------",
      options: [
        { label: "Amerikaans Samoa", value: "AS" },
        { label: "Australië", value: "AU" },
        { label: "Cook Eilanden", value: "CK" },
        { label: "Fiji", value: "FJ" },
        { label: "Frans-Polynesië", value: "PF" },
        { label: "Guam", value: "GU" },
        { label: "Kiribati", value: "KI" },
        { label: "Marshall eilanden", value: "MH" },
        { label: "Micronesië, Federale Staten van", value: "FM" },
        { label: "Nauru", value: "NR" },
        { label: "Nieuw-Caledonië", value: "NC" },
        { label: "Nieuw-Zeeland", value: "NZ" },
        { label: "Niue", value: "NU" },
        { label: "Norfolkeiland", value: "NF" },
        { label: "noordelijke Mariana eilanden", value: "MP" },
        { label: "Palau", value: "PW" },
        { label: "Papoea-Nieuw-Guinea", value: "PG" },
        { label: "Pitcairn", value: "PN" },
        { label: "Samoa", value: "WS" },
        { label: "Solomon eilanden", value: "SB" },
        { label: "Tokelau", value: "TK" },
        { label: "Tonga", value: "TO" },
        { label: "Tuvalu", value: "TV" },
        { label: "Vanuatu", value: "VU" },
        { label: "Wallis en Futuna", value: "WF" }
      ],
    },
    {
      label: "-------------- Antartica ------------",
      options: [
        { label: "Antarctica", value: "AQ" },
        { label: "Bouvet-eiland", value: "BV" },
        { label: "Franse zuidelijke gebieden", value: "TF" },
        { label: "Heard Island en Mcdonald Islands", value: "HM" },
        { label: "Zuid-Georgië en de Zuidelijke Sandwicheilanden", value: "GS" }
      ],
    },
  ];