import React, { useEffect, useState } from "react";
import {
    CardBody,
    Col,
    Input,
    Label,
    Row,
    TabContent,
    TabPane,
    Button,
    Tooltip,
    Alert,
    InputGroup,
    InputGroupText,
    Container,
    Card,
    CardHeader
} from "reactstrap";
import Select from "react-select";

import cookie from "js-cookie";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import InputMask from "react-input-mask";
import { CountryData } from "src/constants/CountrySelect";
import { PayTermsData, PayRateData } from "src/constants/PaymentSelect";
import { setAddCustomer, setNotifybyID } from "src/slices/thunks";
import { useDispatch, useSelector } from "react-redux";
import dataloaded from "src/components/Common/DataLoaded";
import { useNavigate } from "react-router-dom";
import { useForm, SubmitHandler, useFormState, Controller, useController } from "react-hook-form";
import NotifyComponent from "src/components/Common/NotifyComponent";
import Breadcrumbs from "../../components/Common/Breadcrumb";

import { AppDispatch, RootState } from "src";
import useMetaTag from "src/components/Common/useMetaTag";

//#endregion

interface IFormValues {
    companyname: string,
    streetname: string,
    zipcode: string,
    city: string,
    country: string,
    phonenumber: string,
    mobilenumber: string,
    email: string,
    banknumber: string,
    VATnumber: string,
    VATrate: number,
    gAccount: string,
    payterms: number,
    invoiceemail: string,
    createdby: string,
    ralationtype: string,
}

const AddProducts = () => {

    // All Const variables 
    //#region

    const { t } = useTranslation();
    const { register, handleSubmit, formState: { errors }, getValues, setValue, control } = useForm();
    const [activeTab, setactiveTab] = useState(1);
    const dispatch = useDispatch<AppDispatch>();
    const Addcustomers = useSelector((state: RootState) => state.customer);
    const { status, error: any, error } = Addcustomers
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggle = () => setTooltipOpen(!tooltipOpen);
    const [tooltipOpen2, setTooltipOpen2] = useState(false);
    const toggle2 = () => setTooltipOpen2(!tooltipOpen2);
    const [tooltipOpen3, setTooltipOpen3] = useState(false);
    const toggle3 = () => setTooltipOpen3(!tooltipOpen3);
    const [modal_standard, setmodal_standard] = useState(false);
    const navigate = useNavigate();
    const obj = JSON.parse(cookie.get("userData"));
    //#endregion

    //Submit FormData to database 
    //#region
    const onSubmit: SubmitHandler<IFormValues> = (data) => {
        console.log(data);

        let formDataObject = Object.fromEntries(Object.entries(data));
        // Format the plain form data as JSON
        let formDataJsonString = JSON.stringify(formDataObject);
        const datain: {} = { email: obj.email, token: obj.token, dataUpload: formDataJsonString, METHOD: "setAddCustomer" };
        const returning = dispatch(setAddCustomer(datain))
        dataloaded({ id: 15, status: status, error: errors.toString(), successful: t('successMessageAdding', { mail: obj.email, company: getValues('companyname') }), time: 1000 })
        if (returning && status === 'success') {
            const dataNotify: {} = {
                email: obj.email, token: obj.token, dataN: JSON.stringify({
                    action: t('successMessageAdding', { mail: obj.email, company: getValues('companyname') }),
                    user: obj.email,
                    readed: 0,
                    rawdata: datain.toString(),
                    status: "Success"
                }), METHOD: "setNotify"
            }
            dispatch(setNotifybyID(dataNotify));
            // console.log(dataNotify);
            navigate('/Customers/')
        }
    }
    //#endregion

    //success or error status view popupwindow
    //#region
    // useEffect(() => {
    //   //console.log(status);
    //   dataloaded({ id: 5, status: status, error: error, successful: t('successMessageAdding', {mail: obj.email, company: getValues('companyname')}), time: 1000 })
    // }, [status]);
    //#endregion

    //Toggle tab pages for wizzard 
    //#region
    function toggleTab(tab: any) {
        if (activeTab !== tab) {
            if (tab >= 1 && tab <= 4) {
                setactiveTab(tab);
            }
        }
    }
    //#endregion

    // Select form Controller
    //#region
    const RelationTypeoptions = [
        { label: "Klant", value: "Klant" },
        { label: "Leverancier", value: "Leverancier" },
        { label: "Sponsor", value: "Sponsor" },
    ];


    const { field: { value: langValue, onChange: langOnChange, ...restLangField } } = useController({ name: 'country', control, rules: { required: true } });
    const { field: { value: relationValue, onChange: relationOnChange, ...restRelationField } } = useController({ name: 'relationType', control, rules: { required: true } });

    const { field: { value: VATRateValue, onChange: VATrateOnChange, ...restVATrateField } } = useController({ name: 'VATrate', control, rules: { required: true } });
    const { field: { value: paytermsValue, onChange: paytermsOnChange, ...restpaytermsField } } = useController({ name: 'payterms', control, rules: { required: true } });

    console.log(restRelationField);

    //#endregion


    useMetaTag('Artikel toevoegen | AWEKA Systeem', 'Artikel toevoegen Systeem');

    return (
        <React.Fragment>
            <div className="page-content">

                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="Magazijn" breadcrumbItem={[{active: false, urlLink: "#", label: t('Products')},{active: true, urlLink: "#", label: t('Add')}]} />

                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader>
                                    <h4 className="card-title">{t("Products")}</h4>
                                </CardHeader>
                                <CardBody>
                                    <div>
                                        <ul className="wizard-nav mb-5">
                                            <li className="wizard-list-item">
                                                <div
                                                    className={classnames({ active: activeTab === 1 }, "list-item")}
                                                    id={"Tooltip-1"}
                                                >
                                                    <div className="step-icon">
                                                        <i className="uil uil-list-ul"></i>
                                                    </div>
                                                </div>
                                                <Tooltip
                                                    placement="top"
                                                    isOpen={tooltipOpen}
                                                    target={"Tooltip-1"}
                                                    toggle={toggle}
                                                >
                                                    {t("Products")} {t("Details")}
                                                </Tooltip>
                                            </li>
                                            <li className="wizard-list-item">
                                                <div
                                                    className={classnames({ active: activeTab === 2 }, "list-item")}
                                                    id={"Tooltip-2"}
                                                >
                                                    <div className="step-icon">
                                                        <i className="uil uil-clipboard-notes"></i>
                                                    </div>
                                                </div>
                                                <Tooltip
                                                    placement="top"
                                                    isOpen={tooltipOpen2}
                                                    target={"Tooltip-2"}
                                                    toggle={toggle2}
                                                >
                                                    {t("Financal")} {t("Details")}
                                                </Tooltip>
                                            </li>
                                        </ul>
                                        <form onSubmit={handleSubmit(onSubmit)}>
                                            <TabContent activeTab={activeTab}>
                                                <TabPane tabId={1}>
                                                    <div className="text-center mb-4">
                                                        <h5>{t("Product")} {t("Details")}</h5>
                                                        <p className="card-title-desc">{t("FillCustomerDetails")}</p>
                                                    </div>
                                                    <div>
                                                        <Row>
                                                            <Col lg={6}>
                                                                <Row>
                                                                    <Col lg={12}>
                                                                        <div className="mb-3">
                                                                            <Label
                                                                                htmlFor="basicpill-companyname-input"
                                                                                className="form-label"
                                                                            >
                                                                                {t("RelationType")}
                                                                            </Label>
                                                                            {/* <InputGroup> */}
                                                                            {/* {errors.relationType?.type === "required" && (
                                <div className="input-group-text-danger">
                                  !
                                </div>)} */}
                                                                            <Controller
                                                                                control={control}
                                                                                name="relationType"
                                                                                render={({
                                                                                    field: { onChange, onBlur, value, name, ref },
                                                                                }) => (
                                                                                    <Select
                                                                                        options={RelationTypeoptions}
                                                                                        // isLoading={isLoading}
                                                                                        onChange={onChange}
                                                                                        isMulti={true}
                                                                                        onBlur={onBlur}
                                                                                        value={value}
                                                                                        name={name}
                                                                                        ref={ref}
                                                                                    />
                                                                                )}
                                                                            />
                                                                            {/* <Select
                                defaultValue={[RelationTypeoptions[0]]}
                                isMulti={true}
                                options={RelationTypeoptions}
                                getOptionLabel={e => e.label}
                                getOptionValue={e => e.value}
                                classNamePrefix="select2-selection"
                                closeMenuOnSelect={false}
                                className="basic-multi-select"
                                // onChange={option => console.log(option)
                                // }
                                onChange={option => relationOnChange(option ? option.value : option)}
                                {...restRelationField}                               
                              /> */}
                                                                            {/* <input
                                {...register("relationType", { required: true })}
                                type="text"
                                required
                                className="form-control "
                                id="basicpill-relationType-input"
                                aria-invalid={errors.relationType ? "true" : "false"}
                              /> */}
                                                                            {/* </InputGroup> */}

                                                                            {errors.relationType?.type === "required" && (
                                                                                <p>
                                                                                    <Alert color="danger mt-2">{t('required', { labelreq: t("RelationType") })}</Alert>
                                                                                </p>
                                                                            )}
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col lg={12}>
                                                                        <div className="mb-3">
                                                                            <Label
                                                                                htmlFor="basicpill-companyname-input"
                                                                                className="form-label"
                                                                            >
                                                                                {t("CustomerName")}
                                                                            </Label>
                                                                            <InputGroup>
                                                                                {/* {errors.companyname?.type === "required" && (
                                <div className="input-group-text-danger">
                                  !
                                </div>)} */}
                                                                                <input
                                                                                    {...register("companyname", { required: true })}
                                                                                    type="text"
                                                                                    required
                                                                                    className="form-control "
                                                                                    id="basicpill-companyname-input"
                                                                                // aria-invalid={errors.companyname ? "true" : "false"}
                                                                                />
                                                                            </InputGroup>
                                                                            {errors.companyname?.type === "required" && (
                                                                                <p>
                                                                                    <Alert color="danger mt-2">{t('required', { labelreq: t("CustomerName") })}</Alert>
                                                                                </p>
                                                                            )}
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col lg={12}>
                                                                        <div className="mb-3">
                                                                            <Label
                                                                                htmlFor="basicpill-streetname-input"
                                                                                className="form-label"
                                                                            >
                                                                                {t("Streetname")}
                                                                            </Label>
                                                                            <InputGroup>
                                                                                {/* {errors.streetname?.type === "required" && (
                                <div className="input-group-text-danger">
                                  !
                                </div>)} */}
                                                                                <input
                                                                                    type="text"
                                                                                    required
                                                                                    {...register("streetname", { required: true })}
                                                                                    className="form-control"
                                                                                    id="basicpill-streetname-input"

                                                                                />
                                                                            </InputGroup>
                                                                            {errors.streetname?.type === "required" && (
                                                                                <p>
                                                                                    <Alert color="danger mt-2">{t('required', { labelreq: t("Streetname") })}</Alert>
                                                                                </p>
                                                                            )}
                                                                        </div>
                                                                    </Col>

                                                                </Row>

                                                                <Row>
                                                                    <Col lg={4}>
                                                                        <div className="mb-3">
                                                                            <Label
                                                                                htmlFor="basicpill-zipcode-input"
                                                                                className="form-label"
                                                                            >
                                                                                {t("Zipcode")}
                                                                            </Label>
                                                                            <InputGroup>
                                                                                {/* {errors.zipcode?.type === "required" && (
                                <div className="input-group-text-danger">
                                  !
                                </div>)} */}
                                                                                {getValues("country")?.mask ?
                                                                                    <InputMask
                                                                                        mask={getValues("country")?.mask}
                                                                                        {...register("zipcode", { required: true })}
                                                                                        required
                                                                                        className="form-control input-color"
                                                                                    /> :
                                                                                    <input
                                                                                        type="text"
                                                                                        required
                                                                                        className="form-control"
                                                                                        {...register("zipcode", { required: true })}
                                                                                        id="basicpill-zipcode-input"
                                                                                    />}

                                                                            </InputGroup>
                                                                            {errors.zipcode?.type === "required" && (
                                                                                <p>
                                                                                    <Alert color="danger mt-2">{t('required', { labelreq: t("Zipcode") })}</Alert>
                                                                                </p>
                                                                            )}
                                                                        </div>
                                                                    </Col>
                                                                    <Col lg={6}>
                                                                        <div className="mb-3">
                                                                            <Label
                                                                                htmlFor="basicpill-city-input"
                                                                                className="form-label"
                                                                            >
                                                                                {t("City")}
                                                                            </Label>
                                                                            <InputGroup>
                                                                                {/* {errors.city?.type === "required" && (
                                <div className="input-group-text-danger">
                                  !
                                </div>)} */}
                                                                                <input
                                                                                    required
                                                                                    type="text"
                                                                                    {...register("city", { required: true })}
                                                                                    className="form-control"
                                                                                    id="basicpill-city-input"
                                                                                    aria-invalid={errors.city ? "true" : "false"}
                                                                                />

                                                                            </InputGroup>
                                                                            {errors.city?.type === "required" && (
                                                                                <p>
                                                                                    <Alert color="danger mt-2">{t('required', { labelreq: t("City") })}</Alert>
                                                                                </p>
                                                                            )}
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col lg={12}>
                                                                        <div className="mb-3">
                                                                            <Label
                                                                                htmlFor="country"
                                                                                className="form-label"
                                                                            >
                                                                                {t("Country")}
                                                                            </Label>
                                                                            <Select
                                                                                className='select-input'
                                                                                placeholder={t("SelectCountry")}
                                                                                isClearable
                                                                                options={CountryData}
                                                                                getOptionLabel={e => e.label}
                                                                                getOptionValue={e => e.value}
                                                                                onChange={option => langOnChange(option ? option.value : option)}
                                                                                {...restLangField}
                                                                            />
                                                                            {errors.country?.type === "required" && (
                                                                                <p>
                                                                                    <Alert color="danger mt-2">{t('required', { labelreq: t("Country") })}</Alert>
                                                                                </p>
                                                                            )}
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            <Col lg={6}>
                                                                <Row>
                                                                    <Col lg={12}>
                                                                        <div style={{ paddingLeft: "5em" }} className="ml-4 mb-3">
                                                                            <Label
                                                                                htmlFor="basicpill-phonenumber-input"
                                                                                className="form-label"
                                                                            >
                                                                                {t("PhoneNumber")}
                                                                            </Label>
                                                                            <input
                                                                                type="number"
                                                                                className="form-control"
                                                                                {...register("phonenumber")}
                                                                                id="basicpill-phonenumber-input"
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col lg={12}>
                                                                        <div style={{ paddingLeft: "5em" }} className="ml-4 mb-3">
                                                                            <Label
                                                                                htmlFor="basicpill-mobilenumber-input"
                                                                                className="form-label"
                                                                            >
                                                                                {t("MobileNumber")}
                                                                            </Label>
                                                                            <input
                                                                                type="number"
                                                                                {...register("mobilenumber")}
                                                                                className="form-control"
                                                                                id="basicpill-mobilenumber-input"
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col lg={12}>
                                                                        <div style={{ paddingLeft: "5em" }} className="ml-4 mb-3">
                                                                            <Label
                                                                                htmlFor="basicpill-email-input"
                                                                                className="form-label"
                                                                            >
                                                                                {t("Email")}
                                                                            </Label>
                                                                            <input
                                                                                type="email"
                                                                                className="form-control"
                                                                                {...register("email")}
                                                                                id="basicpill-email-input"
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col lg={12}>
                                                                        <div style={{ paddingLeft: "5em" }} className="ml-4 mb-3">
                                                                            <Label
                                                                                htmlFor="basicpill-invoiceemail-input"
                                                                                className="form-label"
                                                                            >
                                                                                {t("InvoiceEmail")}
                                                                            </Label>
                                                                            <input
                                                                                type="email"
                                                                                className="form-control"
                                                                                {...register("invoiceemail")}
                                                                                id="basicpill-invoiceemail-input"
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </TabPane>
                                                <TabPane tabId={2}>
                                                    <div className="text-center mb-4">
                                                        <h5>{t("Financal")}</h5>
                                                        <p className="card-title-desc">{t("FinancalDetails")}</p>
                                                    </div>

                                                    <div>
                                                        <Row>
                                                            <Col lg={6}>
                                                                <div className="mb-3">
                                                                    <Label
                                                                        htmlFor="basicpill-pancard-input"
                                                                        className="form-label"
                                                                    >
                                                                        {t("FinancalBank")}
                                                                    </Label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        {...register("banknumber")}
                                                                        id="basicpill-pancard-input"
                                                                    />
                                                                </div>
                                                            </Col>

                                                            <Col lg={4}>
                                                                <div className="mb-3">
                                                                    <Label
                                                                        htmlFor="basicpill-vatno-input"
                                                                        className="form-label"
                                                                    >
                                                                        {t("FinancalVAT")}
                                                                    </Label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        {...getValues('country') !== "NL" ? { ...register("VATnumber", { required: true }) } : { ...register("VATnumber") }}
                                                                        id="basicpill-vatno-input"
                                                                    />
                                                                    {errors.VATnumber?.type === "required" && (
                                                                        <p>
                                                                            <Alert color="danger mt-2">{t('required', { labelreq: t("FinancalVAT") })}</Alert>
                                                                        </p>
                                                                    )}
                                                                </div>
                                                            </Col>
                                                            <Col lg={2}>
                                                                <div className="mb-3">
                                                                    <Label
                                                                        htmlFor="VATrate"
                                                                        className="form-label"
                                                                    >
                                                                        {t("FinancalVATRate")}
                                                                    </Label>
                                                                    <Select
                                                                        className='select-input'
                                                                        placeholder={t("SelectPayRate")}
                                                                        isClearable
                                                                        options={PayRateData}
                                                                        getOptionLabel={e => e.label}
                                                                        getOptionValue={e => e.value}
                                                                        onChange={option => VATrateOnChange(option ? option.value : option)}
                                                                        {...restVATrateField}
                                                                    />
                                                                    {/* <Select
                          id="VATrate"
                          name="VATrate"
                          value={getValues("VATrate")}
                          onChange={(e: any) => {
                            register('VATrate', e)
                          }}
                          {...register("VATrate", { required: true })}
                          options={PayRateData}
                          placeholder={t("SelectPayRate")}
                          classNamePrefix="select2-selection"
                        /> */}
                                                                    {errors.VATrate?.type === "required" && (
                                                                        <p>
                                                                            <Alert color="danger mt-2">{t('required', { labelreq: t("FinancalVATRate") })}</Alert>
                                                                        </p>
                                                                    )}
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg={6}>
                                                                <div className="mb-3">
                                                                    <Label
                                                                        htmlFor="basicpill-cstno-input"
                                                                        className="form-label"
                                                                    >
                                                                        {t("FinancalGAccount")}
                                                                    </Label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        {...register("gAccount")}
                                                                        id="basicpill-cstno-input"
                                                                    />
                                                                </div>
                                                            </Col>

                                                            <Col lg={6}>
                                                                <div className="mb-3">
                                                                    <Label
                                                                        htmlFor="payterms"
                                                                        className="form-label"
                                                                    >
                                                                        {t("FinancalPayTerms")}
                                                                    </Label>
                                                                    <Select
                                                                        className='select-input'
                                                                        placeholder={t("SelectPayRate")}
                                                                        isClearable
                                                                        options={PayTermsData}
                                                                        // value={paytermsValue ? PayTermsData.find(x => x.options.value === paytermsValue +
                                                                        // console.log(x)
                                                                        // ) : paytermsValue}
                                                                        getOptionLabel={e => e.label}
                                                                        getOptionValue={e => e.value}
                                                                        onChange={option => paytermsOnChange(option ? option.value : option)}
                                                                        {...restpaytermsField}
                                                                    />
                                                                    {/* <Select
                          id="payterms"
                          name="peyterms"
                          value={getValues("payterms")}
                          onChange={(e: any) => {
                            register('payterms', e)
                          }}
                          {...register("payterms", { required: true })}
                          options={PayTermsData}
                          placeholder={t("SelectPayTerms")}
                          classNamePrefix="select2-selection"
                        /> */}
                                                                    {errors.payterms?.type === "required" && (
                                                                        <p>
                                                                            <Alert color="danger mt-2">{t('required', { labelreq: t("FinancalPayTerms") })}</Alert>
                                                                        </p>
                                                                    )}
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg={6}>
                                                                <div className="mb-3">
                                                                    <Label
                                                                        htmlFor="basicpill-companyuin-input"
                                                                        className="form-label"
                                                                    >
                                                                        {t("FinancalEmailInvoice")}
                                                                    </Label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        {...register("invoiceemail")}
                                                                        // onChange={(e) => { SetFormData({ ...FormData, invoiceemail: e.target.value }) }}
                                                                        id="basicpill-companyuin-input"
                                                                    />
                                                                </div>
                                                            </Col>

                                                            <Col lg={6}>
                                                                <div className="mb-3">
                                                                    <Label
                                                                        htmlFor="basicpill-kvk-input"
                                                                        className="form-label"
                                                                    >
                                                                        {t("FinancalCRN")}
                                                                    </Label>
                                                                    <Input
                                                                        {...register("kvk_number")}
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="basicpill-kvk-input"
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </TabPane>
                                                {/* <TabPane tabId={3}>
              <div>
                <div className="text-center mb-4">
                  <h5>Koppel Locatie</h5>
                  <p className="card-title-desc">Fill all information below</p>
                </div>
                <div>
                  <Row>
                    <Col lg={6}>
                      <div className="mb-3">
                        <Label
                          htmlFor="basicpill-namecard-input"
                          className="form-label"
                        >
                          Name on Card
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="basicpill-namecard-input"
                        />
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="mb-3">
                        <Label className="form-label">Credit Card Type</Label>
                        <Input
                          type="select"
                          className="form-select"
                          defaultValue="select card type"
                        >
                          <option value="select card type">
                            Select Card Type
                          </option>
                          <option value="AE">American Express</option>
                          <option value="VI">Visa</option>
                          <option value="MC">MasterCard</option>
                          <option value="DI">Discover</option>
                        </Input>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <div className="mb-3">
                        <Label
                          htmlFor="basicpill-cardno-input"
                          className="form-label"
                        >
                          Credit Card Number
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="basicpill-cardno-input"
                        />
                      </div>
                    </Col>

                    <Col lg={6}>
                      <div className="mb-3">
                        <Label
                          htmlFor="basicpill-card-verification-input"
                          className="form-label"
                        >
                          Card Verification Number
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="basicpill-card-verification-input"
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <div className="mb-3">
                        <Label
                          htmlFor="basicpill-expiration-input"
                          className="form-label"
                        >
                          Expiration Date
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="basicpill-expiration-input"
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </TabPane> */}
                                            </TabContent>
                                        </form>
                                        <div className="d-flex align-items-start gap-3 mt-4">
                                            {activeTab !== 1 &&
                                                <Button type="button"
                                                    color="primary"
                                                    className="w-sm"
                                                    id="prevBtn"
                                                    onClick={() => {
                                                        toggleTab(activeTab - 1);
                                                    }}>{t("Previous")}</Button>
                                            }


                                            {activeTab !== 2 &&
                                                <Button type="button"
                                                    color="primary"
                                                    className="w-sm ms-auto"
                                                    id="nextBtn"
                                                    onClick={() => {
                                                        toggleTab(activeTab + 1);
                                                    }}>{t("Next")}</Button>
                                            }

                                            {activeTab === 2 &&
                                                <Button type="button"
                                                    color="primary"
                                                    onClick={handleSubmit(onSubmit)}
                                                    className="btn btn-primary w-sm ms-auto"
                                                    id="nextBtn">{t("Save")}</Button>
                                            }

                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );



}
export default AddProducts;