import { RootState } from "src";
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { selectNewReceiptsForCustomer, selectTotalReceiptsForCustomer } from 'src/routes/selectors/selectors';
import PropTypes from 'prop-types';
import { t } from 'i18next';


export const NewReceiptsByCustomer = ({ customerId }) => {
    // Gebruik de selector om de nieuwe bonnen op te halen
    const newReceipts = useSelector((state: RootState) =>
        selectNewReceiptsForCustomer(state, customerId)
    );

    return (
        <>
            {newReceipts.length}
        </>
    );
};

export const TotalReceiptsByCustomer = ({ customerId }) => {
    // Gebruik de selector om de nieuwe bonnen op te halen
    const newReceipts = useSelector((state: RootState) =>
        selectTotalReceiptsForCustomer(state, customerId)
    );

    return (
        <>
            {newReceipts.length}
        </>
    );
};

export const ListReceiptsByCustomerId = ({ customerId }) => {

    const navigate = useNavigate();
    const receipts = useSelector((state: RootState) =>
        selectTotalReceiptsForCustomer(state, customerId)
    );

    if (receipts.length === 0) {
        return (
            <ul className="list-group">
                <li className="list-group-item py-3 px-0">
                    <div className="d-flex align-items-center">
                        <div className="flex-shrink-0 me-3">
                            <div>
                                {/* <img src={img4} alt="blog img" className="avatar-lg h-auto rounded" /> */}
                            </div>
                        </div>
                        <div className="flex-grow-1 overflow-hidden">
                            <h5 className="font-size-14 mb-1 text-truncate"><div className="text-dark">Geen bon gevonden</div></h5>
                            <ul className="list-inline font-size-13 text-muted">
                            </ul>
                        </div>
                        <div className="flex-shrink-0 ms-2">
                        </div>
                    </div>
                </li>

            </ul>
        )
    }

    return (
        <ul className="list-group">
            {receipts.map((detail) => (
                <li key={detail.receiptNumber} className="list-group-item py-3 px-0">
                    <div className="d-flex align-items-center">
                        <div className="flex-shrink-0 me-3">
                            <div>
                                {/* <img src={img4} alt="blog img" className="avatar-lg h-auto rounded" /> */}
                            </div>
                        </div>
                        <div className="flex-grow-1 overflow-hidden">
                            <h5 className="font-size-14 mb-1 text-truncate"><Link to="#" className="text-dark">{detail.receiptNumber}</Link></h5>
                            <ul className="list-inline font-size-13 text-muted">
                                <li className="list-inline-item">
                                    <Link to="#" className="text-muted">
                                        Gekoppeld aan order {detail.ordernumberIntern}
                                    </Link>
                                </li>{" "}
                                <li className="list-inline-item">
                                    <i className="uil uil-calender me-1"></i> {t(detail.receiptStatus)},
                                </li>
                            </ul>
                        </div>
                        <div className="flex-shrink-0 mx-2">
                            <>
                                <button className="btn btn-soft-danger" onClick={() =>
                                    navigate(`/receipt-view/${detail.receiptNumber}`, {
                                        state: { receiptDetail: detail }
                                    })}>
                                    <i className="uil uil-eye" style={{ lineHeight: 1, fontSize: "1.5em" }}></i> {t('View')}
                                </button>
                            </>
                        </div>
                    </div>
                </li>
            ))}
        </ul>
    );
}

ListReceiptsByCustomerId.propTypes = {
    customerId: PropTypes.number.isRequired,
};
TotalReceiptsByCustomer.propTypes = {
    customerId: PropTypes.number.isRequired,
};
NewReceiptsByCustomer.propTypes = {
    customerId: PropTypes.number.isRequired,
};
