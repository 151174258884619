import { createSlice } from "@reduxjs/toolkit";
import { getProducts, getProduct } from "./thunk";


interface ProductsStateType {
    products: Array<any>;
    productDetail: object;
    status: string;
    error?: string | object | null | undefined | unknown;
    setAddproduct: Array<any>;
}
export const initialState: ProductsStateType = {
    products: [],
    productDetail: {},
    status: 'idle',
    error: {},
    setAddproduct: [],
};

const productsSlice = createSlice({
    name: "products",
    initialState,
    reducers: {
        // customersSuccessful(state, action) {
        //     state.status    = 'Success'
        //     state.customers = action.payload
        // },
        // customersFailed(state, action) {
        //     state.error     = action.payload
        //     state.customers = action.payload
        // },
    },
    extraReducers: builder => {
        //Get Orders ALL
        builder.addCase(getProducts.pending, (state: any, action: any) => {
            state.status = 'loading';
        });
        builder.addCase(getProducts.fulfilled, (state: any, action: any) => {
            state.products = action.payload;
            state.status = 'success';
        });
        builder.addCase(getProducts.rejected, (state, action) => {
            state.error = action.payload;
            state.status = 'error';
        });
        // Get Order by ID
        builder.addCase(getProduct.pending, (state: any, action: any) => {
            state.status = 'loading';
        });
        builder.addCase(getProduct.fulfilled, (state: any, action: any) => {
            state.orderDetail = action.payload;
            state.status = 'success';
        });
        builder.addCase(getProduct.rejected, (state, action) => {
            state.error = action.payload || null;
            state.status = 'error';
        });
    },
});
// export const {
//     // customersSuccessful,
//     // customersFailed
// } = productsSlice.actions

export default productsSlice.reducer;