

export const PayTermsData = [
    {
      label: "Standaard betaaltermijnen",
      options: [
        { label: "14 dagen", value: "14" },
        { label: "30 dagen", value: "30" },
      ],
    },
    {
      label: "Afwijkende betaaltermijnen",
      options: [
        { label: "Contant", value: "contant" },
        { label: "Pin", value: "pin" },
        { label: "60 dagen", value: "60" },
        { label: "180 dagen", value: "180" },
      ],
    }
  ];

  export const PayRateData = [
    {
      label: "Standaard in Nederland",
      options: [
        { label: "9% BTW", value: "9" },
        { label: "21% BTW", value: "21" },
        { label: "0% BTW (Verlegt)", value: "0" },
      ],
    },
    {
      label: "Buiten Nederland",
      options: [
        { label: "0% BTW", value: "0" }
      ],
    }
  ];