import { createAsyncThunk } from "@reduxjs/toolkit";
// import { customersFailed, customersSuccessful } from "./reducer";

import { getProducts as getProductsApi,  getProductbyID as getProductApi } from "../../helpers/real_helper";

export const getProducts = createAsyncThunk('products/getProducts', async (product:{}, {rejectWithValue}) => {
    try {
      const res:any = await getProductsApi(product);
      console.log("Resssssponse => "+JSON.stringify(res.response));
      
      if (res?.APIResponseStatus === 'success') {
        //Success Loaded
        return res.response;
      } else {
        //Failed Loaded
        return rejectWithValue(res.response)
      }
    } catch (err) {
      return rejectWithValue({ error: err.message })
    }
  });

  export const getProduct = createAsyncThunk('products/Product', async (product:{}, {rejectWithValue}) => {
    try {
      const res:any = await getProductApi(product);
      if (res?.APIResponse === 'success') {
        //Success Loaded
        return res.response;
      } else {
        //Failed Loaded
        return rejectWithValue(res?.response)
      }
    } catch (err) {
      return rejectWithValue({ error: err.message })
    }
  })




