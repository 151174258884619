import { createAsyncThunk } from "@reduxjs/toolkit";
// import { customersFailed, customersSuccessful } from "./reducer";

import { getReceipts as getReceiptsApi,  getReceiptbyID as getReceiptApi,  updateReceiptItembyID as updateReceiptItemApi,  newReceipt as newReceiptApi } from "../../helpers/real_helper";

export const getReceipts = createAsyncThunk('receipts/getReceipts', async (receipt:{}, {rejectWithValue}) => {
    try {
      const res = await getReceiptsApi(receipt);
      //console.log(res);
      return res;
    } catch (err) {
      return rejectWithValue({ error: err.message })
    }
  });

  export const getReceipt = createAsyncThunk('receipts/getReceipt', async (receipt:{}, {rejectWithValue}) => {
    try {
      const res = await getReceiptApi(receipt);
      return res;
    } catch (err) {
      return rejectWithValue({ error: err.message })
    }
  })

  export const updateReceiptItem = createAsyncThunk('receipts/updateReceiptItem', async (receipt:{}, {rejectWithValue}) => {
    try {
      const res = await updateReceiptItemApi(receipt);
      return res;
    } catch (err) {
      return rejectWithValue({ error: err.message })
    }
  })

  export const newReceipt = createAsyncThunk('receipts/newReceipt', async (receipt:{}, {rejectWithValue}) => {
    try {
      const res = await newReceiptApi(receipt);
      return res;
    } catch (err) {
      return rejectWithValue({ error: err.message })
    }
  })




