import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";

//import Breadcrumbs
import Breadcrumbs from "../../components/Common/Breadcrumb";

import { Col, Container, Row } from "reactstrap";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import CustomTable from "src/components/CustomTable";
import { RootState } from "src";
import useMetaTag from "src/components/Common/useMetaTag";
import { Loader } from "rsuite";
import { useParams } from "react-router-dom";
import { minWidth, width } from "@mui/system";

const Customers = () => {

    const { t } = useTranslation();
    const customers = useSelector((state: RootState) => state.customer);
    const isLoading = customers.customers.length === 0; 
    const { kindRelation } = useParams();
    
    const tableDataColumns = [
        { label: "Id", dataName: "UUID", props: { fixed: true, align: "center" } },
        { label: t('Active'), dataName: "Active", props: { fixed: false, flexGrow: 0, resizable: false, sortable: true, fullText: false, badge: true } },
        { label: t('RelationType'), dataName: "relationType", props: { fixed: false, flexGrow: 0, resizable: true, sortable: true, fullText: false, minWidth: 150, width: 150, badge: true } },
        { label: t('CustomerName'), dataName: "companyname", props: { fixed: false, flexGrow: 1, resizable: true, sortable: true, fullText: true, style: { fontWeight: "700" }, minWidth: 300, } },
        { label: t('Streetname'), dataName: "streetname", props: { fixed: false, flexGrow: 1, resizable: true, sortable: true, fullText: true } },
        { label: t('Zipcode'), dataName: "zipcode", props: { fixed: false, flexGrow: 1, resizable: true, sortable: true, fullText: false } },
        { label: t('City'), dataName: "city", props: { fixed: false, flexGrow: 1, resizable: true, sortable: true, fullText: false } },
    ]

    //#endregion
    const handleClick = (e) => {
        console.log("Gegevenes => "+e);
    }
    // console.log(JSON.stringify(data));
    
    useMetaTag('Klanten | AWEKA Systeem', 'Klanten AWEKA');
    if (isLoading) {
        return <Loader content="Loading..." />;
    }
    return (
        <React.Fragment>
            <div className="page-content">

                {/* <MetaTags>
                    <title>{t("Customers")} | AWEKA Energy Solutions</title>
                </MetaTags> */}

                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="Algemeen" breadcrumbItem={[{active: false, urlLink: "#", label: t('Relations')},{active: true, urlLink: "#", label: t('Customers')}]}  />

                    <Row>

                        <Col xl={12}>
                            {customers.customers &&
                                <CustomTable
                                // keyRow={"UUID"}
                                    PageSize={15}
                                    dataRows={customers.customers}
                                    onClickEvent={handleClick}
                                    columns={tableDataColumns}
                                    isEdit={false}
                                    isModal={{ enable: true, modal: "Customers" }}
                                    filterStart={kindRelation ? kindRelation : ""}
                                />
                            }
                            {/* <OrdersTable PageSize={15} /> */}
                        </Col>

                    </Row>

                </Container>
            </div>
        </React.Fragment>
    );
}

export default Customers;