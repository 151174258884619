import React, { useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Badge, Card, CardBody, Dropdown, DropdownMenu, DropdownToggle, Form, Input, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Table, Pagination, CustomProvider } from 'rsuite';
import "rsuite/dist/rsuite.css";
import * as locales from 'rsuite/locales';
import { Grid, Row, Col } from 'rsuite';
import i18n from 'src/i18n';
import { getLocationbyCustomerID } from 'src/slices/thunks';
import { useDispatch, useSelector } from 'react-redux';
import Cookie from "js-cookie";
import { Link, useNavigate } from 'react-router-dom';
import DeleteModal from '../Common/UseModalDelete';
import { RootState, AppDispatch } from 'src';
import { getCustomers, getReceipts } from 'src/routes/selectors/selectors';
import { NewReceiptsByCustomer, TotalReceiptsByCustomer } from '../Receipts/NewReceiptsByCustomer';



interface CustomTableProps {
    PageSize: number,
    dataRows: any,
    onClickEvent?: Function,
    columns: any,
    isEdit?: boolean,
    isModal?: {
        enable: boolean,
        modal: "Customers" | "Orders" | "Installations" | "Locations"
    },
    keyRow?,
    isLoading?,
    filterStart?,
}


const CustomTable = (props: CustomTableProps) => {
    const {
        PageSize,
        dataRows,
        onClickEvent,
        columns,
        isModal,
        isLoading,
        filterStart,
    } = props;



    //#region
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const customers = useSelector(getCustomers);
    const receipts = useSelector(getReceipts);

    const obj = JSON.parse(Cookie.get("userData"));
    const [deletemodal, setDeleteModal] = useState(false);
    const [deleteData, setDeleteData] = useState(null);
    const Deletetoggle = () => setDeleteModal(!deletemodal);
    //#endregion



    //SORT 
    //#region
    const { Column, HeaderCell, Cell } = Table;
    const [sortColumn, setSortColumn] = useState('');
    const [sortType, setSortType] = useState();
    const [loading, setLoading] = useState(isLoading);
    const [userInput, setUserInput] = useState('');
    const [rowData, setRowData] = useState(dataRows);
    // Toggle searchF
    const toggleSearch = () => {
        setsearch_Menu(!search_Menu);
    };
    const [search_Menu, setsearch_Menu] = useState<boolean>(false);


    const handleSortColumn = (sortColumn, sortType) => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
            setSortColumn(sortColumn);
            setSortType(sortType);
        }, 500);
    };


    const handleChangeLimit = dataKey => {
        setPage(1);
        setLimit(dataKey);
        // console.log("DataKey => " + dataKey);

    };


    function sortData(rowData, sortingColumn, sortingDirection) {
        if (sortingColumn === null) {
            return rowData;
        }

        return rowData.slice().sort((a, b) => {
            const aValue = a[sortingColumn];
            const bValue = b[sortingColumn];

            if (aValue === bValue) {
                return 0;
            }

            if (sortingDirection === "asc") {
                return aValue < bValue ? -1 : 1;
            } else {
                return aValue < bValue ? 1 : -1;
            }
        });
    }

    const [page, setPage] = React.useState(1);
    const [limit, setLimit] = React.useState(PageSize);
    const [filteredDataBySearch, setfilteredDataBySearch] = useState(rowData);

    const [filter, setFilter] = useState(filterStart ? filterStart : "");

    useEffect(() => {
        if (filterStart) {
            filterBySearch({ target: { value: filterStart } });
        } else {
            filterBySearch({ target: { value: "" } });
        }
    }, [filterStart]);


    //let PageSize = 10;
    const filterBySearch = (event) => {
        // Access input value
        const query = event.target.value;
        // Set filter value
        setFilter(query);
        // Create copy of item list
        var updatedList = [...rowData];
        // Include all elements which includes the search query
        updatedList = updatedList.filter((o) => Object.keys(o).some((k) => o[k]?.toLowerCase().includes(query.toLowerCase())));
        //console.log(updatedList.filter((o) => Object.keys(o).some((k) => o[k]?.toLowerCase().includes(query.toLowerCase()))));

        setfilteredDataBySearch(updatedList)
        // Trigger render with updated values
        // filterData(updatedList, "");
        //reset by search to first page 
        setPage(1);
    };


    //pagination filter 
    function filterData(rowData, userInput) {
        //console.log(userInput);

        return rowData.filter((v, i) => {
            const start = limit * (page - 1);
            const end = start + limit;
            //console.log(end);
            return i >= start && i < end;
        })
    }


    const sortedAndFilteredData = useMemo(() => {
        const filteredData = filterData(filteredDataBySearch, userInput);
        const sortedData = sortData(filteredData, sortColumn, sortType);

        return sortedData;
    }, [rowData, userInput, sortColumn, sortType, filterData, filterBySearch, filteredDataBySearch, filterStart]);



    const datalang = Object.keys(locales).map(key => ({
        key,
        value: locales[key]
    }));


    const locale = datalang.find(item => item.key === i18n.language);


    //#endregion

    //Push History
    //#region
    const navigate = useNavigate();
    const GoTo = (e) => {
        // console.log("to UUID => " + e);

        navigate('/customer-profile/' + e, {
            state: { customerDetail: customers, }
        })
    }
    //#endregion

    //Modal Selected Customer 
    //#region
    // const [isOpen, toggle, ModalData] = useModal();
    const [modal, setModal] = useState(false);
    const [modalData, setModalData] = useState(null);
    const toggle = () => setModal(!modal);
    const locationsbycustomerID = useSelector((state: RootState) => state.locations);
    const [locationsData, setlocationsData] = useState([]);

    useEffect(() => {
        setlocationsData([]);
        if (modalData?.UUID) {
            const datain: {} = { email: obj.email, token: obj.token, id: modalData?.UUID, METHOD: "GetLocationbyCustomerID" };
            dispatch(getLocationbyCustomerID(datain))
            console.log(locationsbycustomerID);

        } else if (locationsbycustomerID.status === 'success') {
            setlocationsData(locationsbycustomerID.locations);
        }

    }, [modal, modalData])
    const convertDate = (selectDate: Date, landString: string) => {

        if (selectDate) {
            const date = new Date(selectDate);
            return date.toLocaleDateString(landString);
        } else {
            return "Geen tijd";
        }


    }


    const CustomerModal = () => {

        return (
            <Modal isOpen={modal} fade={false} size='lg' toggle={toggle} >
                <ModalHeader>{t("Customer")}</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col xl={12}>
                            <b>{modalData?.companyname}</b><br />
                            {modalData?.streetname}<br />
                            {modalData?.zipcode} {modalData?.city}<br />
                            {modalData?.country}
                        </Col>
                        <Col xl={12}>
                            <Row>
                                <p><small>{t('Email')}</small> : <Link target='blank' to='#' onClick={() => window.location.href = "mailto:" + modalData?.email}>{modalData?.email}</Link></p>
                            </Row>
                            <Row>
                                <p><small>{t('InvoiceEmail')}</small> : <Link target='blank' to='#' onClick={() => window.location.href = "mailto:" + modalData?.invoiceemail}>{modalData?.invoiceemail}</Link></p>
                            </Row>
                            <Row>
                                <p><small>{t('PhoneNumber')}</small> : {modalData?.phonenumber}</p>
                            </Row>
                            <Row>
                                <p><small>{t('MobileNumber')}</small> : {modalData?.mobilenumber} </p>
                            </Row>
                            <Row>
                                <p><small>{t('Active')}</small> : {modalData?.Active}</p>
                            </Row>
                        </Col>

                    </Row>


                    <div className='mt-5'>
                        <Row>
                            <b>Analystics</b>
                        </Row>
                        <Row>

                            <Col xl={12}>
                                <Card>
                                    <CardBody>
                                        <div className="d-flex align-items-center">
                                            <div className="flex-shrink-0 me-3">
                                                <div className="avatar-sm">
                                                    <div className="avatar-title bg-soft-primary text-primary rounded-circle font-size-18">
                                                        <i className="uil uil-list-ul"></i>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="flex-grow-1 overflow-hidden">
                                                <p className="mb-1 text-truncate text-muted">{t('Receipts')} {t('new')}</p>
                                                <h5 className="font-size-16 mb-0"><NewReceiptsByCustomer customerId={modalData?.UUID} /></h5>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col xl={12}>
                                <Card>
                                    <CardBody>
                                        <div className="d-flex align-items-center">
                                            <div className="flex-shrink-0 me-3">
                                                <div className="avatar-sm">
                                                    <div className="avatar-title bg-soft-primary text-primary rounded-circle font-size-18">
                                                        <i className="uil uil-list-ul"></i>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="flex-grow-1 overflow-hidden">
                                                <p className="mb-1 text-truncate text-muted">{t('Amount')} {t('Receipts')}</p>
                                                <h5 className="font-size-16 mb-0"><TotalReceiptsByCustomer customerId={modalData?.UUID} /></h5>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col xl={12}>
                                <Card>
                                    <CardBody>

                                        <div className="d-flex align-items-center">
                                            <div className="flex-shrink-0 me-3">
                                                <div className="avatar-sm">
                                                    <div className="avatar-title bg-soft-primary text-primary rounded-circle font-size-18">
                                                        <i className="uil uil-list-ul"></i>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="flex-grow-1 overflow-hidden">
                                                <p className="mb-1 text-truncate text-muted">{t('Amount')} {t('Locations')}</p>
                                                <h5 className="font-size-16 mb-0">{locationsbycustomerID.locations.length}</h5>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className="d-flex flex-wrap gap-2">
                        <button className="btn btn-soft-success" onClick={() => GoTo(modalData?.UUID)}>
                            <i className="uil uil-user me-2"></i>{t("View")}
                        </button>
                        <button className="btn btn-soft-primary" onClick={toggle}>
                            <i className="uil uil-archive me-2"></i>{t("Receipts")}
                        </button>
                        <button className="btn btn-soft-danger" onClick={() => { setDeleteData(modalData); setDeleteModal(!deletemodal); setModal(!modal) }}>
                            <i className="uil uil-trash-alt me-2"></i>{t("Delete")}
                        </button>
                        <button className="btn btn-soft-warning" onClick={toggle}>
                            <i className="uil uil-times me-2"></i>{t('Close')}
                        </button>
                    </div>
                    <p><small> {t('CreationDate')} : <b>{convertDate(modalData?.creationdate, 'nl-NL')}</b> by <b>{modalData?.createdby}</b></small></p>
                </ModalFooter>
            </Modal>
        )
    }
    //#endregion

    const generateUUID = () => {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            const r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    };


    const CustomCell = ({ rowData, dataKey, mask, calculation, ...props }: any) => {
        // Haal de data op voor de gegeven dataKey
        let data = rowData[dataKey];

        // Controleer of de data een string is die lijkt op een array (begint met "[" en eindigt met "]")
        if (typeof data === 'string' && data.startsWith('[') && data.endsWith(']')) {
            try {
                // Probeer de string te parsen naar een array
                data = JSON.parse(data);
            } catch (e) {
                console.error('Error parsing JSON:', e);
            }
        }

        // Controleer of de data nu een array is en voeg de elementen samen tot een string
        const displayValue = Array.isArray(data) ? data.join(', ') : data;

        // Functie om de eerste letter van elke woord met een hoofdletter te maken
        const capitalizeFirstLetter = (str: string) => {
            // Controleer of de input geen null of undefined is en of het een string is
            if (!str || typeof str !== 'string') {
                return ''; // Geef een lege string terug of een default waarde
            }

            // Controleer of er meer dan één woord is (door te kijken naar de aanwezigheid van spaties)
            if (str.includes(' ')) {
                return str
                    .split(' ') // Split de string op spaties om woorden te krijgen
                    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Hoofdletter eerste letter, rest kleine letters
                    .join(' '); // Voeg de woorden weer samen met een spatie
            } else {
                // Als er maar één woord is, maak alleen de eerste letter hoofdletter
                return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
            }
        };

        const badgeColors = ['info', 'primary', 'danger', 'warning'];

        let assignedColor;
        if (Array.isArray(data)) {
            if (data.includes('leverancier')) {
                assignedColor = 'badge-soft-info'; // Kleur voor leverancier
            } else if (data.includes('klant')) {
                assignedColor = 'badge-soft-danger'; // Kleur voor klant
            } else {
                assignedColor = 'badge-soft-primary'; // Gebruik de random kleur
            }
        } else {
            if (data.toLowerCase() === 'leverancier') {
                assignedColor = 'badge-soft-info'; // Kleur voor leverancier
            } else if (data.toLowerCase() === 'klant') {
                assignedColor = 'badge-soft-purple'; // Kleur voor klant
            } else if (data.toLowerCase() === 'ja') {
                assignedColor = 'badge-soft-success'; // Kleur voor klant
            } else if (data.toLowerCase() === 'nee') {
                assignedColor = 'badge-soft-danger'; // Kleur voor klant
            } else {
                assignedColor = 'badge-soft-primary'; // Standaardkleur voor niet-array data
            }
        }

        return (
            <Cell {...props} key={generateUUID()}>
                {calculation ? dataKey : (
                    props.badge ? (
                        Array.isArray(data) ? (
                            data.map((item, index) => {
                                let itemColor;

                                if (item.toLowerCase() === 'leverancier') {
                                    itemColor = 'badge-soft-primary'; // Kleur voor leverancier
                                } else if (item.toLowerCase() === 'klant') {
                                    itemColor = 'badge-soft-purple'; // Kleur voor klant
                                } else if (item.toLowerCase() === 'ja') {
                                    itemColor = 'badge-soft-success'; // Kleur voor klant
                                } else if (item.toLowerCase() === 'nee') {
                                    itemColor = 'badge-soft-danger'; // Kleur voor klant
                                } else {
                                    itemColor = 'badge-soft-info'; // Standaardkleur voor overige items
                                }
                                console.log("itemcolor => " + itemColor);
                                
                                return (
                                    <span className={`badge rounded-pill ${itemColor}`}  key={index}>
                                        {capitalizeFirstLetter(item) + " " + mask}
                                    </span>
                                );
                            })
                        ) : (
                            <span className={`badge rounded-pill ${assignedColor}`}>
                                {capitalizeFirstLetter(displayValue) + " " + mask}
                            </span>
                        )
                    ) : (
                        capitalizeFirstLetter(displayValue) + " " + mask
                    )
                )}
            </Cell>
        )
    };

    //View part 
    //#region
    return (
        <React.Fragment>
            <CustomerModal />
            <DeleteModal
                isOpen={deletemodal}
                toggle={Deletetoggle}
                data={deleteData}
                from='Customer'
            />
            <Card>
                <CardBody>



                    <Grid fluid>
                        <Row className="show-grid">
                            <Col xs={8} xsPush={18} xl={6} xlPush={22}>
                                <Dropdown isOpen={search_Menu} toggle={toggleSearch} className="d-inline">
                                    <DropdownToggle className="text-muted me-3 mb-3 align-middle" tag="a">
                                        <i className="bx bx-search font-size-16" />{t('Search')}
                                    </DropdownToggle>
                                    <DropdownMenu className="dropdown-menu-end p-0 dropdown-menu-lg">
                                        <Form className="p-2">
                                            <div className="search-box">
                                                <div className="position-relative">
                                                    <Input
                                                        onChange={filterBySearch}
                                                        type="text"
                                                        className="form-control rounded bg-light border-0"
                                                        placeholder={t("Search")}
                                                        aria-label="Recipient's username"
                                                        value={filter.length >= 0 ? filter : ""}
                                                    />
                                                    <i className="bx bx-search font-size-16 search-icon"></i>
                                                </div>
                                            </div>
                                        </Form>
                                    </DropdownMenu>
                                </Dropdown>
                            </Col>
                        </Row>
                    </Grid>
                    {dataRows &&
                        <CustomProvider locale={locale.value}>
                            <Table
                                // isTree
                                key={generateUUID()}
                                autoHeight
                                data={sortedAndFilteredData}
                                sortColumn={sortColumn}
                                sortType={sortType}
                                onSortColumn={handleSortColumn}
                                loading={loading}
                                shouldUpdateScroll={false}
                                onRowClick={rowData => {
                                    if (isModal.enable) {
                                        setModal(!modal)
                                        setModalData(rowData)
                                        console.log(rowData);
                                        onClickEvent(rowData)
                                        // return rowData
                                    } else {
                                        console.log(rowData);
                                        onClickEvent(rowData);
                                        // return rowData

                                    }


                                }}
                                onExpandChange={(isOpen, rowData) => {
                                    console.log(isOpen, rowData);
                                }}

                            >
                                {columns && columns.map((column) => {
                                    return (
                                        <>
                                            <Column {...column.props} key={generateUUID()}>
                                                <HeaderCell>{column.label} {column.labelasset ? column.labelasset : ""}</HeaderCell>
                                                <CustomCell mask={column.props?.mask ? column.props.mask : ""} calculation={column.props?.calculation} dataKey={column.dataName}></CustomCell>
                                            </Column>
                                        </>
                                    )
                                })}


                            </Table>
                        </CustomProvider>
                    }
                    <div>
                        <Pagination
                            prev
                            next
                            first
                            last
                            ellipsis
                            boundaryLinks
                            maxButtons={5}
                            size="xs"
                            layout={['total', '-', 'limit', '|', 'pager', 'skip']}
                            total={filteredDataBySearch.length}
                            limitOptions={[10, 30, 50, 100]}
                            limit={limit}
                            // locale={locale}
                            // locale={{total: t('Total') + " " + filteredDataBySearch.length, last: t('Last'), first: t('First')}}
                            activePage={page}
                            onChangePage={setPage}
                            onChangeLimit={handleChangeLimit}
                        />
                    </div>



                </CardBody>
            </Card>
        </React.Fragment >
    );
    //#endregion
};

export default CustomTable;