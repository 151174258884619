import { createSlice } from "@reduxjs/toolkit";
import { getSettings } from "./thunk";
import { UpdateSettings } from "../thunks";

interface SettingsStateType {
  settings: Array<any>;
  status: string;
  error?: string | object | null | undefined | unknown;
}
export const initialState: SettingsStateType = {
  settings: [],
  status: "idle",
  error: {},
};

const SettingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getSettings.pending, (state: any, action: any) => {
      state.status = 'loading';
    });
    builder.addCase(getSettings.fulfilled, (state: any, action: any) => {
      state.settings = action.payload
      state.status = 'success';
    });
    builder.addCase(getSettings.rejected, (state, action) => {
      state.error = action.payload || null;
      state.status = 'error';
    });
    builder.addCase(UpdateSettings.pending, (state: any, action: any) => {
      state.status = 'loading';
    });
    builder.addCase(UpdateSettings.fulfilled, (state: any, action: any) => {
      state.settings = action.payload
      state.status = 'success';
    });
    builder.addCase(UpdateSettings.rejected, (state, action) => {
      state.error = action.payload || null;
      state.status = 'error';
    });
  },
});

export default SettingsSlice.reducer;