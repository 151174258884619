import { createSlice } from "@reduxjs/toolkit";
import { getInstallations, getInstallation } from "./thunk";


interface InstallationStateType {
    installations: Array<any>;
    installationDetail: object;
    status: string;
    error?: string | object | null | undefined | unknown;
    setAddinstallation: Array<any>;
}
export const initialState: InstallationStateType = {
    installations: [],
    installationDetail: {},
    status: 'idle',
    error: {},
    setAddinstallation: [],
};

const installationsSlice = createSlice({
    name: "installations",
    initialState,
    reducers: {
        // customersSuccessful(state, action) {
        //     state.status    = 'Success'
        //     state.customers = action.payload
        // },
        // customersFailed(state, action) {
        //     state.error     = action.payload
        //     state.customers = action.payload
        // },
    },
    extraReducers: builder => {
        //Get Installations ALL
        builder.addCase(getInstallations.pending, (state: any, action: any) => {
            state.status = 'loading';
        });
        builder.addCase(getInstallations.fulfilled, (state: any, action: any) => {
            state.installations = action.payload;
            state.status = 'success';
        });
        builder.addCase(getInstallations.rejected, (state, action) => {
            state.error = action.payload || null;
            state.status = 'error';
        });
        // Get Installation by ID
        builder.addCase(getInstallation.pending, (state: any, action: any) => {
            state.status = 'loading';
        });
        builder.addCase(getInstallation.fulfilled, (state: any, action: any) => {
            state.installationDetail = action.payload;
            state.status = 'success';
        });
        builder.addCase(getInstallation.rejected, (state, action) => {
            state.error = action.payload || null;
            state.status = 'error';
        });
    },
});
// export const {
//     // customersSuccessful,
//     // customersFailed
// } = installationsSlice.actions

export default installationsSlice.reducer;