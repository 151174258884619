import React from 'react';
import classnames from 'classnames';
import { usePagination, DOTS } from './usePagination';
import './pagination.scss';
import { Col, Pagination, PaginationItem, PaginationLink, } from 'reactstrap';

interface PaginationProps {
    onPageChange: any
    totalCount: number
    siblingCount: number
    currentPage: number
    pageSize: number
    className: string
}

const PaginationCustom = (props: PaginationProps) => {
    const {
        onPageChange,
        totalCount,
        siblingCount = 1,
        currentPage,
        pageSize,
        className
    } = props;

    const paginationRange = usePagination({
        currentPage,
        totalCount,
        siblingCount,
        pageSize
    });

    if (currentPage === 0 || paginationRange.length < 2) {
        return null;
    }

    const onNext = () => {
        onPageChange(currentPage + 1);
    };

    const onPrevious = () => {
        onPageChange(currentPage - 1);
    };

    let lastPage = paginationRange[paginationRange.length - 1];

    return (
        <Col className='mt-4'>
            <Pagination aria-label="Page navigation example">

                <PaginationItem disabled={currentPage === 1}>

                    <PaginationLink
                        onClick={onPrevious}
                        previous
                        href="#"
                    />

                </PaginationItem>

                {paginationRange.map(pageNumber => {
                    if (pageNumber === DOTS) {
                        return (<PaginationItem key={pageNumber + Math.random()}>
                            <PaginationLink>
                                &#8230;
                            </PaginationLink>
                        </PaginationItem>);
                    }

                    return (
                        <PaginationItem active={pageNumber === currentPage} key={pageNumber}>
                            <PaginationLink onClick={() => onPageChange(pageNumber)} href="#">
                                {pageNumber}
                            </PaginationLink>
                        </PaginationItem>
                    );
                })}

                <PaginationItem disabled={currentPage === lastPage}>

                    <PaginationLink
                        onClick={onNext}
                        next
                        href="#"
                    />

                </PaginationItem>

            </Pagination>
        </Col>
    );
};

export default PaginationCustom;