import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Col, Container, Row } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import CustomTable from "src/components/CustomTable";
import { RootState } from "src";
import useMetaTag from "src/components/Common/useMetaTag";
import { Loader } from "rsuite";

const Installations = () => {
    const { t } = useTranslation();
    const installations = useSelector((state: RootState) => state.installations);
    const isLoading = installations.installations.length === 0; 
    const tableDataColumns = [
        { label: "Id", dataName: "uuid", props: { fixed: true, align: "center" } },
        { label: t('Active'), dataName: "active", props: { fixed: false, flexGrow: 0, resizable: true, sortable: true, fullText: false } },
        { label: t('InstallationType'), dataName: "installationType", props: { fixed: false, flexGrow: 1, resizable: false, sortable: true, fullText: false } },
        { label: t('BoilerBrand'), dataName: "boilerBrand", props: { fixed: false, flexGrow: 1, resizable: true, sortable: true, fullText: false, style: { fontWeight: "700" } } },
        { label: t('BoilerType'), dataName: "boilerType", props: { fixed: false, flexGrow: 1, resizable: true, sortable: true, fullText: true } },
        { label: t('BoilerNumber'), dataName: "boilerNumber", props: { fixed: false, flexGrow: 1, resizable: true, sortable: true, fullText: true } },
        { label: t('BoilerBuildyear'), dataName: "boilerBuildyear", props: { fixed: false, flexGrow: 1, resizable: true, sortable: true, fullText: true } },
        { label: t('BoilerPower'), labelasset: "(KW)", dataName: "boilerPower", props: { fixed: false, flexGrow: 1, resizable: true, sortable: true, fullText: true } },
        // { label: t('City'), dataName: "city", props: { fixed: false, flexGrow: 1, resizable: true, sortable: true, fullText: false } },
    ]

    const handleClick = (e) => {
        console.log("Gegevenes => "+e);
    }

useMetaTag('Installaties | AWEKA Systeem', 'Installaties AWEKA');
if (isLoading) {
    return <Loader content="Loading..." />;
}
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="Algemeen" breadcrumbItem={[{active: false, urlLink: "#", label: t('Installations')},{active: true, urlLink: "#", label: t('View')}]} />

                    <Row>

                        <Col xl={12}>
                            {installations.installations &&
                                <CustomTable
                                    PageSize={10}
                                    dataRows={installations.installations}
                                    onClickEvent={handleClick}
                                    columns={tableDataColumns}
                                    keyRow={"uuid"}
                                    isEdit={false}
                                    isModal={{ enable: false, modal: "Customers" }}
                                />
                            }
                            {/* <OrdersTable PageSize={15} /> */}
                        </Col>

                    </Row>

                </Container>
            </div>
        </React.Fragment>
    );
}

export default Installations;