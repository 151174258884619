import React, { useState } from 'react';
import { Form, Link, useNavigate } from 'react-router-dom';
import Cookie from "js-cookie";
import { Container, Row, Col, Input, Label, Button, Card } from 'reactstrap';

import logolight from "../../assets/images/logo-light-aweka.png";
import logoDark from "../../assets/images/logo-dark-aweka.png";

import avatar10 from "../../assets/images/users/avatar-10.jpg";

const parseCookie = (cookieName) => {
    const cookieValue = Cookie.get(cookieName);
    if (cookieValue) {
        try {
            return JSON.parse(cookieValue);
        } catch (e) {
            console.error('Error parsing JSON from cookie:', e);
            return null; // Of een andere veilige default waarde
        }
    }
    return null;
};

const LockScreen = () => {
    const navigate = useNavigate();


    //const obj = JSON.parse(Cookie.get("userData"));    
    const [pin, setPin] = useState('');
    const [error, setError] = useState('');
    const [data, setData] = useState(() => parseCookie("userData") || "")
    const handleUnlock = () => {
        if (pin === '1234') { // Vervang door je eigen PIN validatie
            // Bij succesvolle ontgrendeling, stuur de gebruiker terug naar de vorige pagina
            Cookie.remove('lock')
            navigate('/sales') // Of een andere route waar je de gebruiker naar wilt sturen
        } else {
            setError('Onjuiste PIN. Probeer het opnieuw.');
        }
    };

    return (
        <React.Fragment>
            <div className="authentication-bg min-vh-100">
                <div className="bg-overlay bg-white"></div>
                <Container>
                    <div className="d-flex flex-column min-vh-100 px-3 pt-4">
                        <Row className="justify-content-center my-auto ">
                            <Col lg={10}>
                                <div className="py-5">
                                    <Card className="auth-cover-card overflow-hidden">
                                        <Row className="g-0">
                                            <Col lg={6}>
                                                <div className="auth-img">
                                                </div>
                                            </Col>
                                            <Col lg={6}>
                                                <div className="p-4 p-lg-5 bg-danger h-100 d-flex align-items-center justify-content-center">
                                                    <div className="w-100 text-center">
                                                        <div className="mb-4 mb-md-5">
                                                            {/* <Link to="/sales" className="d-block auth-logo">
                                                                <img src={logolight} alt="" />
                                                            </Link> */}
                                                        </div>
                                                        <div className="user-thumb mb-4 mb-md-5">
                                                            <img src={data?.profileimg} className="rounded-circle img-thumbnail avatar-lg" alt="thumbnail" />
                                                            <h5 className="font-size-16 text-white mt-3">{data?.first_name} {data?.last_name}</h5>
                                                        </div>
                                                        <form>
                                                        {error && <p className='text-white'>{error}</p>}

                                                            <div className="form-floating form-floating-custom mb-3">
                                                                <input type="password" className="form-control" id="input-password" value={pin}
                                                                    onChange={(e) => setPin(e.target.value)}
                                                                    placeholder="Voer PIN in" />
                                                                <label htmlFor="input-password">Voer PIN in</label>
                                                                <div className="form-floating-icon">
                                                                    <i className="uil uil-padlock"></i>
                                                                </div>
                                                            </div>

                                                            <div className="mt-3">
                                                                <button className="btn btn-danger w-100" onClick={handleUnlock} type="button">Ontgrendel</button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Card>
                                    <div className="mt-5 text-center text-muted">
                                        <p>Ben jij dit niet ? <Link to="/logout" className="fw-medium text-decoration-underline">Opnieuw aanmelden </Link></p>
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col lg={12}>
                                <div className="text-center text-muted p-4">
                                    <p className="mb-0">&copy; {" "}{new Date().getFullYear()} AWEKA Energy Solutions</p>
                                </div>
                            </Col>
                        </Row>

                    </div>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default LockScreen;