import React from 'react';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';


function AuthGuard() {
  const auth = Cookies.get("userData") != null;
  const location = useLocation();

  if (!auth) {
    console.log('Not Authenticated, redirecting to login');
    return (
      <Navigate 
        to="/login" 
        replace 
        state={{ from: location }} 
      />
    );
  }

  return <Outlet />;
}

export default AuthGuard;