import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import MetaTags from "react-meta-tags";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";

//Import Image
import logo from "../../../assets/images/logo-sm.png";

import { Link } from 'react-router-dom';
import LocationSelectModal from 'src/components/Location/LocationSelectModal';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getLocations } from 'src/slices/thunks';
import dataloaded from 'src/components/Common/DataLoaded';
import Cookie from "js-cookie";
import A4Page from 'src/components/Common/A4pageComponent/A4Page';
import { AppDispatch, RootState } from 'src';

function AddReceipt() {
    const dispatch = useDispatch<AppDispatch>();
    const { t } = useTranslation(); 
    const locations = useSelector((state: RootState) => state.locations);
    const { status, error: any, error } = locations
    const obj = JSON.parse(Cookie.get("userData"));
    const [data, setData] = useState();
    useEffect(() => {
        const datain: {} = { email: obj.email, token: obj.token, METHOD: "GetLocations" };
        dispatch(getLocations(datain))
    }, [])
    useEffect(() => {
        dataloaded({ id: 1, status, successful: t('LocationsLoaded'), time: 1000, notify: true, notifyData: "test data send to notifysystem!" })
        if (status === 'success') {
            // setData(locations.locations);
        }
    }, [status]);

    const [receiptItems, setReceiptItems] = useState([
        { name: '', price: 0, quantity: 1 },
    ]);

    const addReceiptItem = () => {
        setReceiptItems([...receiptItems, { name: '', price: 0, quantity: 1 }]);
    };

    const removeReceiptItem = (index) => {
        const updatedItems = [...receiptItems];
        updatedItems.splice(index, 1);
        setReceiptItems(updatedItems);
    };

    const calculateSubtotal = (item) => {
        return item.price * item.quantity;
    };

    const handleItemChange = (index, field, value) => {
        const updatedItems = [...receiptItems];
        updatedItems[index][field] = value;
        setReceiptItems(updatedItems);
    };

    const calculateTotal = () => {
        return receiptItems.reduce((total, item) => {
            return total + item.price * item.quantity;
        }, 0);
    };

    const totalAmount = receiptItems.reduce((total, item) => {
        return total + calculateSubtotal(item);
    }, 0);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedLocation, setSelectedLocation] = useState(null);

    // const locations = [
    //     { id: 1, name: 'Location 1' },
    //     { id: 2, name: 'Location 2' },
    //     // Add more locations as needed
    // ];

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const handleLocationSelect = (location) => {
        setSelectedLocation(location);
        closeModal();
    };

    return (
        <>
            <React.Fragment>
                {data && 
                <LocationSelectModal
                    isOpen={isModalOpen}
                    onRequestClose={closeModal}
                    locations={data}
                    onSelectLocation={handleLocationSelect}
                />}
                <div className="page-content">
                    <MetaTags>
                        <title>Invoice Detail | Dashonic - React Admin & Dashboard Template</title>
                    </MetaTags>
                    <Container fluid>
                        {/* Render Breadcrumbs */}
                        <Breadcrumbs title="Invoices" breadcrumbItem={[{ active: true, urlLink: "#", label: "Invoice Details" }]} />

                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody>
                                    
                                        <div className="invoice-title">
                                            <h4 className="float-end font-size-15">Invoice #DS0204 <span className="badge bg-success font-size-12 ms-2">Paid</span></h4>
                                            <div className="mb-4">
                                                <img src={logo} alt="logo" height="28" />
                                            </div>
                                            <div className="text-muted">
                                                <p className="mb-1">3184 Spruce Drive Pittsburgh, PA 15201</p>
                                                <p className="mb-1"><i className="uil uil-envelope-alt me-1"></i> xyz@987.com</p>
                                                <p><i className="uil uil-phone me-1"></i> 012-345-6789</p>
                                            </div>
                                        </div>

                                        <hr className="my-4" />

                                        <Row>
                                            <Col sm={6}>
                                                <button onClick={openModal}>Select Location</button>
                                                {selectedLocation && <p>Selected Location: {selectedLocation.name}</p>}
                                                <div className="text-muted">
                                                    <h5 className="font-size-16 mb-3">Billed To: </h5>
                                                    <h5 className="font-size-15 mb-2">Preston Miller</h5>
                                                    <p className="mb-1">4068 Post Avenue Newfolden, MN 56738</p>
                                                    <p className="mb-1">PrestonMiller@armyspy.com</p>
                                                    <p>001-234-5678</p>
                                                </div>
                                            </Col>

                                            <Col sm={6}>
                                                <div className="text-muted text-sm-end">
                                                    <div>
                                                        <h5 className="font-size-15 mb-1">Invoice No: </h5>
                                                        <p>#DZ0112</p>
                                                    </div>
                                                    <div className="mt-4">
                                                        <h5 className="font-size-15 mb-1">Invoice Date: </h5>
                                                        <p>12 Oct, 2020</p>
                                                    </div>
                                                    <div className="mt-4">
                                                        <h5 className="font-size-15 mb-1">Order No: </h5>
                                                        <p>#1123456</p>
                                                    </div>
                                                </div>
                                            </Col>

                                        </Row>


                                        <div className="py-2">
                                            <h5 className="font-size-15">Order Summary <button onClick={addReceiptItem}>Add Item</button></h5>

                                            <div className="table-responsive">
                                                <table className="table align-middle table-nowrap table-centered mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th style={{ width: "70px" }}>No.</th>
                                                            <th>Item</th>
                                                            <th>Price</th>
                                                            <th>Quantity</th>
                                                            <th className="text-end" style={{ width: "120px" }}>Total</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {receiptItems.map((item, index) => (
                                                            <tr key={index}>
                                                                <th scope="row">0{index + 1}</th>
                                                                <td><button onClick={() => console.log("Search product")}>Search Product</button></td>
                                                                <td>
                                                                    <div>
                                                                        <h5 className="text-truncate font-size-14 mb-1"><input
                                                                            type="text"
                                                                            placeholder="Item Name"
                                                                            value={item.name}
                                                                            onChange={(e) => handleItemChange(index, 'name', e.target.value)}
                                                                        /></h5>
                                                                        <p className="text-muted mb-0"></p>
                                                                    </div>
                                                                </td>
                                                                <td><input
                                                                    type="number"
                                                                    placeholder="Price"
                                                                    value={item.price}
                                                                    onChange={(e) => handleItemChange(index, 'price', parseFloat(e.target.value))}
                                                                /></td>
                                                                <td><input
                                                                    type="number"
                                                                    placeholder="Quantity"
                                                                    value={item.quantity}
                                                                    onChange={(e) => handleItemChange(index, 'quantity', parseFloat(e.target.value))}
                                                                /></td>
                                                                <td className="text-end">${calculateSubtotal(item).toFixed(2)}</td>
                                                                <td><button onClick={() => removeReceiptItem(index)}>Remove</button></td>
                                                            </tr>
                                                        ))}
                                                        <tr>
                                                            <th scope="row">02</th>
                                                            <td>
                                                                <div>
                                                                    <h5 className="text-truncate font-size-14 mb-1">Stainless Steel S010</h5>
                                                                    <p className="text-muted mb-0">Watch, Gold</p>
                                                                </div>
                                                            </td>
                                                            <td>$ 245.50</td>
                                                            <td>2</td>
                                                            <td className="text-end">$491.00</td>
                                                        </tr>

                                                        <tr>
                                                            <th scope="row" colSpan={4} className="text-end">Sub Total</th>
                                                            <td className="text-end">$732.50</td>
                                                        </tr>

                                                        <tr>
                                                            <th scope="row" colSpan={4} className="border-0 text-end">
                                                                Discount : </th>
                                                            <td className="border-0 text-end">- $25.50</td>
                                                        </tr>

                                                        <tr>
                                                            <th scope="row" colSpan={4} className="border-0 text-end">
                                                                Shipping Charge : </th>
                                                            <td className="border-0 text-end">$20.00</td>
                                                        </tr>

                                                        <tr>
                                                            <th scope="row" colSpan={4} className="border-0 text-end">
                                                                Tax</th>
                                                            <td className="border-0 text-end">$12.00</td>
                                                        </tr>

                                                        <tr>
                                                            <th scope="row" colSpan={4} className="border-0 text-end">Total</th>
                                                            <td className="border-0 text-end"><h4 className="m-0 fw-semibold">$739.00</h4></td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="d-print-none mt-4">
                                                <div className="float-end">
                                                    <Link to="#" className="btn btn-success me-1"><i className="fa fa-print"></i></Link>
                                                    <Link to="#" className="btn btn-primary w-md">Send</Link>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
            {/* <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Bon toevoegen | AWEKA Energy Solutions</title>
                    </MetaTags>

                    <Card>
                        <CardBody>
                            <Container fluid>
                                <h2>Receipt</h2>

                                <div>
                                    <strong>Total: ${calculateTotal().toFixed(2)}</strong>
                                </div>
                            </Container>
                        </CardBody>
                    </Card>
                </div>
            </React.Fragment> */}
        </>
    );
}

export default AddReceipt;