import React from 'react';
import GrapesEditor from './GrapesEditor';

function LayoutBuilder() {
    return (
        <React.Fragment>
            <div className="page-content">
                <GrapesEditor />
            </div>
        </React.Fragment>
    );
}

export default LayoutBuilder;