import { useEffect } from "react";

const useMetaTag = (title, description) =>{
    const defaultTitle = "app-name";
    const defaultDesc = "meta description";

    useEffect(() => {
        document.title = title || defaultTitle;
        document.querySelector("meta[name='description']").setAttribute("content", description || defaultDesc);
    }, [defaultTitle, title, defaultDesc, description]);
};

export default useMetaTag;