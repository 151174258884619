import { createAsyncThunk } from "@reduxjs/toolkit";
import { getSettings as getSettingsApi } from "../../helpers/real_helper";




export const getSettings = createAsyncThunk('Settings/getSettings', async (settings:{}, {rejectWithValue}) => {
    try {
      const res = await getSettingsApi(settings);
      //console.log(res);
      return res;
    } catch (err) {
      return rejectWithValue({ error: (err as Error).message })
    }
  });