import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";

//import Breadcrumbs
import Breadcrumbs from "../../components/Common/Breadcrumb";

import { Col, Container, Row } from "reactstrap";

import { useTranslation } from "react-i18next";

import { useDispatch, useSelector } from "react-redux";
import dataloaded from "src/components/Common/DataLoaded";
import Cookie from "js-cookie";
import CustomTable from "src/components/CustomTable";
import { getProducts } from "src/slices/thunks";
import { RootState, AppDispatch } from "src";
import useMetaTag from "src/components/Common/useMetaTag";


const Products = () => {
    const { t } = useTranslation();
    const products = useSelector((state: RootState) => state.products);
    const tableDataColumns = [
        { label: "Id", dataName: "uuid", props: { fixed: true, align: "center" } },
        { label: t('ProductDescription'), dataName: "productDescription", props: { fixed: false, flexGrow: 1, resizable: true, sortable: true, fullText: false } },
        { label: t('ProductMinStock'), dataName: "minStock", props: { fixed: false, flexGrow: 1, resizable: false, sortable: true, fullText: false, style: { fontWeight: "700" } } },
        { label: t('ProductOnStock'), dataName: "onStock", props: { fixed: false, flexGrow: 1, resizable: true, sortable: true, fullText: false } },
        { label: t('ProductPurchasePriceBruto'), dataName: "purchasePriceBruto", props: { fixed: false, flexGrow: 1, resizable: true, sortable: true, fullText: true, mask: "€" } },
        { label: "Berekening", dataName: "Bedenken hoe dat hier moet", props: { fixed: false, flexGrow: 1, resizable: true, sortable: true, fullText: true, calculation: "true" } },
    ]
    //#endregion
    const handleClick = (e) => {
        console.log("Gegevenes => "+e);
    }

    useMetaTag('Artikelen | AWEKA Systeem', 'Artikelen Systeem');

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="Magazijn" breadcrumbItem={[{active: false, urlLink: "#", label: t('Products')},{active: true, urlLink: "#", label: t('View')}]} />

                    <Row>

                        <Col xl={12}>
                            {products.products &&
                                <CustomTable
                                    PageSize={15}
                                    dataRows={products.products}
                                    onClickEvent={handleClick}
                                    columns={tableDataColumns}
                                    keyRow={"uuid"}
                                    isEdit={false}
                                    isModal={{ enable: false, modal: "Customers" }}
                                />
                            }
                            {/* <OrdersTable PageSize={15} /> */}
                        </Col>

                    </Row>

                </Container>
            </div>
        </React.Fragment>
    );
}

export default Products;