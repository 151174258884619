import React, { MouseEventHandler, useEffect, useRef, useState } from "react";
import MetaTags from "react-meta-tags";
import "./OrderDashboard.css";
//import Breadcrumbs
import Breadcrumbs from "../../components/Common/Breadcrumb";

import { AutoComplete, Grid, IconButton, Pagination, Table, Tag, TagPicker } from 'rsuite';
import { Button, Card, CardBody, Container, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Form, Input, Modal, ModalBody, ModalFooter, ModalHeader, Popover, PopoverBody, PopoverHeader, UncontrolledDropdown } from "reactstrap";

import { Row, Col } from 'rsuite';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from "react-redux";
import { getOrders, getReports, getReceipts, getUser, preAddNewOrder, getLastOrderNumber, getCustomers, getLocations, newReceipt } from "src/slices/thunks";
import dataloaded from "src/components/Common/DataLoaded";
import Cookie from "js-cookie";


import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToMarkdown from 'draftjs-to-markdown';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
// import randomColor from 'randomcolor';
import '../../assets/scss/custom/modal/modal.css'
import { Link } from "react-router-dom";
import Dropzone from "react-dropzone";
import moment from "moment";
import axios from "axios";
import './styles.css';
import CurrencyInput from "react-currency-input-field";
import { AppDispatch, RootState } from "src";
import useMetaTag from "src/components/Common/useMetaTag";


const { Column, HeaderCell, Cell } = Table;


const Orders = () => {

    const navigate = useNavigate();
    // Get translation function
    const { t } = useTranslation();

    // Redux state and dispatch
    const dispatch = useDispatch<AppDispatch>();
    const orders = useSelector((state: RootState) => state.orders);
    const reports = useSelector((state: RootState) => state.reports);
    const receipts = useSelector((state: RootState) => state.receipts);
    const user = useSelector((state: RootState) => state.users);
    const customer = useSelector((state: RootState) => state.customer);
    const location = useSelector((state: RootState) => state.locations);







    // Loading state
    const [isLoading, setIsLoading] = useState(true);

    // User data
    const obj = JSON.parse(Cookie.get("userData"));

    // Modal state
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    const [rowData, setRowData] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [isCollapsed, setIsCollapsed] = useState(true);


    // const colors = ['red', 'blue', 'green', 'yellow', 'purple', 'orange'];

    // const getRandomColor = () => {
    //     const randomIndex = Math.floor(Math.random() * colors.length);
    //     return colors[randomIndex];
    // };

    // const CustomTag = ({ label }) => {
    //     const color = getRandomColor();

    //     return (
    //         <div
    //             style={{
    //                 backgroundColor: color,
    //                 padding: '4px 8px',
    //                 borderRadius: '4px',
    //                 color: 'white',
    //                 marginRight: '8px',
    //                 display: 'inline-block',
    //             }}
    //         >
    //             {label}
    //         </div>
    //     );
    // };

    const toggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };

    // Data Loading
    useEffect(() => {
        const datainUser = { email: obj.email, token: obj.token, id: obj.id, METHOD: "GetUserbyId" };
        dispatch(getUser(datainUser));

    }, [dispatch, isLoading, obj.email, obj.token, obj.id, orders.setAddorder, receipts.dump]);

    const [componentYear, SetcomponentYear] = useState();

    const AddOrderModal = () => {

        // const tableDataColumns = [
        //     { label: "Id", dataName: "UUID", props: { fixed: true, align: "center" } },
        //     { label: t('Active'), dataName: "Active", props: { fixed: false, flexGrow: 0, resizable: false, sortable: true, fullText: false } },
        //     { label: t('RelationType'), dataName: "relationType", props: { fixed: false, flexGrow: 0, resizable: false, sortable: true, fullText: false } },
        //     { label: t('CustomerName'), dataName: "companyname", props: { fixed: false, flexGrow: 1, resizable: true, sortable: true, fullText: true, style: { fontWeight: "700" } } },
        //     { label: t('Streetname'), dataName: "streetname", props: { fixed: false, flexGrow: 1, resizable: true, sortable: true, fullText: true } },
        //     { label: t('Zipcode'), dataName: "zipcode", props: { fixed: false, flexGrow: 1, resizable: true, sortable: true, fullText: false } },
        //     { label: t('City'), dataName: "city", props: { fixed: false, flexGrow: 1, resizable: true, sortable: true, fullText: false } },
        // ]

        const [valueSearchCustomer, setValueSearchCustomer] = React.useState('');
        const [valueSelectedCustomer, setValueSelectedCustomer] = React.useState('');
        const [valueSearchLocation, setValueSearchLocation] = React.useState('');
        const [valueSelectedLocation, setValueSelectedLocation] = React.useState(null);
        const handleClick = (e) => {
            console.log("Gegevenes => " + JSON.stringify(valueSelectedCustomer));
            console.log("Gegevenes Location => " + JSON.stringify(valueSelectedLocation));
            if (JSON.stringify(valueSelectedLocation) === null) {
                console.log("No Location selected");

                setValueSelectedLocation(null)
            }

            if (JSON.stringify(valueSelectedCustomer).length > 1 && valueSelectedCustomer) {
                const datainpreAddNewOrder = { email: obj.email, token: obj.token, data: { ordernumberIntern: componentYear, customerInfo: JSON.stringify(valueSelectedCustomer), locationInfo: JSON.stringify(valueSelectedLocation) ? JSON.stringify(valueSelectedLocation) : null }, METHOD: "preAddNewOrder" };
                dispatch(preAddNewOrder(datainpreAddNewOrder));
                dataloaded({
                    id: 5,
                    status: "success",
                    error: "",
                    successful: "Order met succes toegevoegt",
                    time: 500,
                    notify: true,
                    notifyData: "AddOrder",
                });
                setModal(false);
            }

        }
        const styles = {
            width: 300,
            marginBottom: 10,
            zIndex: "1300 !important"
        };


        const selectedCustomer = customer.customers.find((el) => el.UUID === valueSearchCustomer);
        if (selectedCustomer && !valueSelectedCustomer) {
            //console.log(laced);
            setValueSelectedCustomer(selectedCustomer);
            //   return;
        }

        const selectedLocation = location.locations.find((el) => el.locationname === valueSearchLocation);
        if (selectedLocation && !valueSelectedLocation) {
            //console.log(laced);
            setValueSelectedLocation(selectedLocation);
            //   return;
        }
        const isMatch = (uuids, selectedUUID) => {
            if (Array.isArray(uuids)) {
                console.log("is array => " + uuids);

                return uuids.includes(selectedUUID);
            } else {
                return uuids === selectedUUID || uuids === String(selectedUUID);
            }
        };

        const filteredLocations = location.locations
            .filter((loc) =>
                isMatch(loc.locationcustomerUUID, selectedCustomer?.UUID))
            .map((loc) => loc.locationname);

        // console.log(filteredLocations);
        //MODAL ADD NEW ORDER
        return (
            <Modal isOpen={modal} toggle={toggle} size="xl" style={{ width: "100vw" }}>
                <ModalHeader></ModalHeader>
                <ModalBody>
                    <Row>
                        <Col>
                            {t("Customer")}
                            {/* {console.log(customer.customers.map(name => name.companyname))} */}
                            <AutoComplete data={customer.customers.map((name, index) => ({
                                value: name.UUID,
                                label: name.companyname,
                                item: name,
                                key: index, // Ensure a unique key for each item
                            }))} onSelect={setValueSearchCustomer} onChange={() => setValueSelectedCustomer('')} style={{ width: 224 }} />
                            {valueSearchCustomer &&

                                customer.customers.map((values, index) => {
                                    if (values.UUID === valueSearchCustomer) {

                                        return (
                                            <>
                                                <div key={index} style={{ listStyle: 'none', marginTop: "5px" }}>
                                                    Geselecteerde relatie
                                                    <li><strong>{values.companyname}</strong></li>
                                                    <li>{values.streetname}</li>
                                                    <li>{values.zipcode}, {values.city}</li>
                                                    <li>{values.country}</li>

                                                </div>
                                            </>
                                        )
                                    }

                                })



                            }
                        </Col>

                        <Col>
                            {t("Location")}
                            <AutoComplete data={filteredLocations} onSelect={setValueSearchLocation} onChange={() => setValueSelectedLocation('')} style={{ width: 224 }} />
                            {valueSearchLocation &&

                                location.locations.map((values, index) => {
                                    if (values.locationname === valueSearchLocation) {

                                        return (
                                            <>
                                                <div style={{ listStyle: 'none', marginTop: "5px" }}>
                                                    Geselecteerde locatie
                                                    <li><strong>{values.locationname}</strong></li>
                                                    <li>{values.locationadress}</li>
                                                    <li>{values.locationzipcode}, {values.locationcity}</li>
                                                    <li>{values.locationcountry}</li>

                                                </div>
                                            </>
                                        )
                                    }

                                })



                            }
                        </Col>
                        {/* <CustomTable
                            keyRow={"UUID"}
                            PageSize={10}
                            dataRows={customer.customers}
                            onClickEvent={(e) => handleClick(e)}
                            columns={tableDataColumns}
                            isEdit={false}
                            isModal={{ enable: false, modal: "Customers" }}

                        /> */}
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <div className="d-flex flex-wrap gap-2">
                        <button className="btn btn-soft-success" onClick={handleClick}>
                            <i className="uil uil-times me-2"></i>Maak aan
                        </button>
                        <button className="btn btn-soft-warning" onClick={toggle}>
                            <i className="uil uil-times me-2"></i>{t('Close')}
                        </button>
                    </div>
                </ModalFooter>
            </Modal>
        )

    }


    const handleNewOrderPreload = () => {

        const datainpreAddNewOrder = { email: obj.email, token: obj.token, data: { ordernumberIntern: componentYear }, METHOD: "preAddNewOrder" };
        dispatch(preAddNewOrder(datainpreAddNewOrder));
        // }

    }



    // Reproduce data function
    const ReproducedData = (ParentData, childrenReportData, childrenReceiptData) => {
        const clonedObj = JSON.parse(JSON.stringify(ParentData));
        let outputData = clonedObj;

        outputData.map((value, index) => {
            // Set plain Children and Categories
            outputData[index]["children"] = [
                { uuid: value.uuid + "-1", DisplayName: t('Reports'), children: [], customer: [], location: [], ButtonID: "Category" },
                { uuid: value.uuid + "-2", DisplayName: t('Receipts'), children: [], customer: [], location: [], ButtonID: "Category" }
            ];

            // Get customer details
            const printCustomer = outputData[index].customerInfo ? JSON.parse(outputData[index].customerInfo) : null;
            if (printCustomer) {
                outputData[index]["customer"] = {
                    companyname: printCustomer.companyname,
                    streetname: printCustomer.streetname,
                    zipcode: printCustomer.zipcode
                };
            }

            // Get location details
            const printLocation = outputData[index].locationInfo ? JSON.parse(outputData[index].locationInfo) : { locationname: "No Location Name", locationadress: "No Location Address", locationzipcode: "No Location Zipcode" };
            if (printLocation) {
                outputData[index]["location"] = {
                    locationname: printLocation.locationname || "No Location Name",
                    locationadress: printLocation.locationadress || "No Location Address",
                    locationzipcode: printLocation.locationzipcode || "No Location Zipcode"
                };
            }

            // Process childrenReportData
            childrenReportData && childrenReportData?.map((childrenValue, childrenIndex) => {
                if (value.ordernumberIntern === childrenValue.ordernumberIntern) {
                    // Create the first Report Entry
                    if (outputData[index]["children"][0]["children"] === undefined) {
                        outputData[index]["children"][0] = {
                            uuid: value.uuid + "-1",
                            DisplayName: t('Reports'),
                            ButtonID: "Category",
                            countReport: outputData[index]["children"][0].length,
                            children: [{ ...childrenValue, uuid: value.uuid + "-1-" + (childrenIndex + 1) }]
                        };
                    } else {
                        // Create other Report entries including the previous ones
                        let tempReportStore = outputData[index]["children"][0]["children"];
                        tempReportStore.push({ ...childrenValue, uuid: value.uuid + "-1-" + (childrenIndex + 1) });
                        outputData[index]["children"][0] = {
                            uuid: value.uuid + "-1",
                            DisplayName: t('Reports'),
                            ButtonID: "Category",
                            countReport: tempReportStore.length,
                            children: tempReportStore
                        };
                    }
                }
            });

            // Check if there are no children for Reports
            if (outputData[index]["children"][0]["children"].length === 0) {
                delete outputData[index]["children"][0];
            }

            // Process childrenReceiptData
            childrenReceiptData && childrenReceiptData?.map((childrenValue, childrenIndex) => {
                if (value.ordernumberIntern === childrenValue.ordernumberIntern) {
                    // Create the first Receipts Entry
                    if (outputData[index]["children"][1]["children"] === undefined) {
                        outputData[index]["children"][1] = {
                            uuid: value.uuid + "-2",
                            DisplayName: t('Receipts'),
                            ButtonID: "Category",
                            countReport: outputData[index]["children"][1].length,
                            children: [{ ...childrenValue, uuid: value.uuid + "-2-" + (childrenIndex + 1) }],
                            ordernumberExtern: "test"
                        };
                        //console.log(outputData[index]["children"][1].length);

                    } else {
                        // Create other Receipts entries including the previous ones
                        let tempStore = outputData[index]["children"][1]["children"];
                        tempStore.push({ ...childrenValue, uuid: value.uuid + "-2-" + (childrenIndex + 1) });
                        // console.log(tempStore);

                        // const orderValue = tempStore?.map((element) => {
                        //     let values = element.receiptProducts;

                        //     // Check if values is an array before calling map
                        //     if (Array.isArray(values)) {
                        //         const receiptItemsValues = values.map((receiptItem) => {
                        //             console.log(receiptItem);
                        //             // perform operations on receiptItem
                        //         });
                        //         // return the result if needed
                        //     } else {
                        //         console.error("receiptProducts is not an array:", values);
                        //     }
                        // });
                        // //console.log(orderValue);
                        outputData[index]["children"][1] = {
                            uuid: value.uuid + "-2",
                            DisplayName: t('Receipts'),
                            ButtonID: "Category",
                            countReport: tempStore.length,
                            children: tempStore,
                            ordernumberExtern: tempStore.length === 1 ? (tempStore.length + " Bon") : (tempStore.length + " Bonnen"),
                            styles: { color: "#ffffff" }
                        };
                        // console.log(tempStore[0]['receiptProducts']);

                    }
                }
            });

            // Check if there are no children for Receipts
            if (outputData[index]["children"][1]["children"].length === 0) {
                delete outputData[index]["children"][1];
            }

            outputData.forEach((item, index) => {
                const children = outputData[index]["children"];
                //console.log("OutputData children:", children);

                if (Array.isArray(children) && children.length > 0) {
                    let allChildrenEmpty = true;

                    for (let i = 0; i < children.length; i++) {
                        if (!isEmpty(children[i])) {
                            allChildrenEmpty = false;
                            break;
                        }
                    }

                    if (allChildrenEmpty) {
                        //console.log("Before deletion - outputData[index][\"children\"]:", outputData[index]["children"]);
                        delete outputData[index]["children"];
                        //console.log("After deletion - outputData[index][\"children\"]:", outputData[index]["children"]);
                    }
                }
            });

            function isEmpty(value) {
                return value == null || (typeof value === 'object' && Object.keys(value).length === 0) || (typeof value === 'string' && value.trim() === '');
            }


        });

        // console.log(JSON.stringify(outputData));
        return outputData;
    };

    useEffect(() => {
        setRowData(ReproducedData(orders.orders, reports.reports, receipts.receipts))
        setfilteredDataBySearch(ReproducedData(orders.orders, reports.reports, receipts.receipts))
        setSortedAndFilteredData(ReproducedData(orders.orders, reports.reports, receipts.receipts))
    }, [isLoading, dispatch, orders.orders, reports.reports, receipts.receipts])

    const [sortedAndFilteredData, setSortedAndFilteredData] = useState(rowData);
    // Sorting and Filtering
    const [sortColumn, setSortColumn] = useState('');
    const [sortType, setSortType] = useState();


    // Toggle Search
    const toggleSearch = () => {
        setsearch_Menu(!search_Menu);
    };

    const [search_Menu, setsearch_Menu] = useState<boolean>(false);

    const handleSortColumn = (sortColumn, sortType) => {
        setTimeout(() => {
            setSortColumn(sortColumn);
            setSortType(sortType);
        }, 500);
    };

    // Handle Change Limit
    const handleChangeLimit = dataKey => {
        setPage(1);
        setLimit(dataKey);
        // console.log("DataKey => " + dataKey);
    };

    // Sort Data
    function sortData(rowData, sortingColumn, sortingDirection) {
        if (sortingColumn === null) {
            return rowData;
        }

        const sortedData = rowData.slice().sort((a, b) => {
            const aValue = getNestedValue(a, sortingColumn);
            const bValue = getNestedValue(b, sortingColumn);

            if (typeof aValue === 'number' && typeof bValue === 'number') {
                // If both values are numbers, perform numeric comparison
                return sortingDirection === 'asc' ? aValue - bValue : bValue - aValue;
            } else if (typeof aValue === 'string' && typeof bValue === 'string') {
                // If both values are strings, perform alphabetic comparison
                return sortingDirection === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
            } else {
                // One is a number and the other is not
                // Numbers come first, so the numeric value comes first in the sorted order
                return typeof aValue === 'number' ? -1 : 1;
            }
        });

        return sortedData;
    }

    // Helper function to get nested values
    function getNestedValue(obj, key) {
        const keys = key.split("['").map(k => k.replace("']", ''));
        return keys.reduce((acc, k) => (acc && acc[k] !== undefined ? acc[k] : undefined), obj);
    }

    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [filteredDataBySearch, setfilteredDataBySearch] = useState(rowData);

    const deepSearch = (obj, query) => {
        if (typeof query === 'string') {
            query = [query]; // Convert a plain text query to an array
        }

        if (Array.isArray(query)) {
            if (typeof obj === 'string') {
                return query.some(q => obj.toLowerCase().includes(q.toLowerCase()));
            } else if (Array.isArray(obj)) {
                return obj.some(item => deepSearch(item, query));
            } else if (typeof obj === 'object' && obj !== null) {
                return Object.values(obj).some(value => deepSearch(value, query));
            }
        }
        return false;
    };

    const [filter, setFilter] = useState('');

    // Filter By Search
    const filterBySearch = event => {
        // Access input value
        const query = event.target.value;
        // Set filter value
        setFilter(query);
        // Create a copy of the item list
        var updatedList = [...rowData];

        updatedList = updatedList.filter(o => deepSearch(o, query));

        setfilteredDataBySearch(updatedList);
        setPage(1);
    };

    // Filter Data

    function filterData(rowDataFunction) {
        let filteredData = [...rowDataFunction];



        if (selectedFilters?.[1]?.length > 0) {
            // Combine filters using OR logic
            const filterFunction = item => selectedFilters.some(filter => deepSearch(item, filter));
            selectedFilters.shift();
            console.log("Selected Filters:", selectedFilters);
            // Apply the combined filter function to the data
            filteredData = filteredData.filter(filterFunction);
            if (filteredData) {
                setPage(1);
            }
        }

        // Apply pagination logic
        const start = limit * (page - 1);
        const end = start + limit;

        return filteredData.slice(start, end);
    }

    const [selectedFilters, setSelectedFilters] = useState([]);
    // console.log("filteredDataBySearch => " + JSON.stringify(filteredDataBySearch));

    // const uniqueData = [...new Map(filteredDataBySearch.map(item => [(item?.customer && item.customer['companyname']) || null, item])).values()];

    // const uniqueDataWithLabelAndKey = uniqueData.map(item => ({
    //     group: "Klanten",
    //     label: item.customer['companyname'],
    //     key: item.uuid,
    // }));

    // const uniqueDataLocation = [...new Map(filteredDataBySearch.map(item => [(item?.location && item.location['locationname']) || null, item])).values()];
    // const uniqueDataWithLabelAndKeyLocation = uniqueDataLocation.map(item => ({
    //     group: "Locaties",
    //     label: item?.location['locationname'],
    //     key: item?.uuid,
    // }));

    function createMultiUseMap(data, labelKey, keyKey, groupLabel) {
        const uniqueData = new Map();

        data.forEach(item => {
            const labelValue = getNestedValueMulti(item, labelKey);
            const keyValue = getNestedValueMulti(item, keyKey);

            if (labelValue !== undefined && keyValue !== undefined) {
                uniqueData.set(labelValue, keyValue);
            }
        });

        const multiUseMap = [...uniqueData.entries()].map(([label, key]) => ({
            group: groupLabel,
            label: label,
            key: key,
        }));

        return multiUseMap;
    }

    function getNestedValueMulti(obj, path) {
        return path.split('.').reduce((acc, key) => acc && acc[key], obj);
    }

    const handleFilterChange = (name) => {
        // Create a new array with the current selected filters
        const updatedFilters = [...selectedFilters];

        // Remove all previous filters
        updatedFilters.splice(-1, updatedFilters.length - 1);

        // Add the new filter
        updatedFilters.push(name);

        // Update the state with the new array
        setSelectedFilters(updatedFilters);
    };

    useEffect(() => {
        const filteredData = filterData(filteredDataBySearch);
        const sortedData = sortData(filteredData, sortColumn, sortType);
        setSortedAndFilteredData(sortedData);

        //setRowData(ReproducedData(orders.orders, reports.reports, receipts.receipts))
    }, [sortColumn, sortType, selectedFilters, filteredDataBySearch, page]);

    const tableRef = useRef(null);
    //Push History
    //#region
    
    //#endregion


    const ActionCell = ({ rowData, dataKey, ...props }) => {
        const shouldShowViewButton = rowData.ButtonID !== 'Category';
        const shouldShowAddButton = rowData.ButtonID !== 'Category' && rowData.DisplayName.charAt(0) === 'O';
        const shouldShowAddReceipt = rowData.ButtonID === 'Category' && rowData.DisplayName.charAt(0) === 'W';
        const shouldShowAddReport = rowData.ButtonID === 'Category' && rowData.DisplayName.charAt(0) === 'R';

        // const shouldShowAddbutton = rowData.displayName.startWith('O');

        const handleButtonClick = () => {
            const displayName = rowData.DisplayName;
            if (displayName.startsWith('O')) {
                // console.log("rowData => " + JSON.stringify(rowData));
                // console.log("dataKey => " + dataKey);

                openViewOrderModal(rowData)
                console.log(JSON.parse(rowData.customerInfo));

                hanldeDistance(JSON.parse(rowData?.customerInfo))
                //history.push({ pathname: `/order-view/${displayName}`, state: { receiptDetail: receipts } });
            } else if (displayName.startsWith('R')) {
                navigate(`/report-view/${displayName}`, {state: { receiptDetail: receipts } });
            } else if (displayName.startsWith('W')) {
                navigate(`/receipt-view/${displayName}`, {state: { receiptDetail: receipts } });
            }
        };
        const handleNewReceipt = () => {
            const datain: {} = { email: obj.email, token: obj.token, data: { orderNumber: rowData.DisplayName, customerInfo: rowData.customerInfo, locationInfo: rowData.locationInfo }, METHOD: "AddNewReceipt" };
            dispatch(newReceipt(datain))
        }
        const handleNewReport = () => {
            console.log("New Report adding to : " + JSON.stringify(rowData));
        }

        return (
            <Cell {...props} style={{ padding: '4px' }}>
                {shouldShowViewButton && (
                    <>
                        <button className="btn btn-soft-info" onClick={handleButtonClick}>
                            <i className="uil uil-eye" style={{ lineHeight: 1, fontSize: "1.5em" }}></i> {/*{t('View')}*/}
                        </button>
                    </>
                )}
                {shouldShowAddButton && (
                    <>
                        <button className="btn btn-soft-success" onClick={handleNewReceipt}>
                            <i className="uil uil-receipt" style={{ lineHeight: 1, fontSize: "1.5em" }}></i>{/*{t('View')}*/}
                        </button>
                        <button className="btn btn-soft-success" onClick={handleNewReport}>
                            <i className="uil uil-clipboard-notes" style={{ lineHeight: 1, fontSize: "1.5em" }}></i>{/*{t('View')}*/}
                        </button>
                    </>
                )}


            </Cell>
        );
    };


    ActionCell.propTypes = {
        rowData: PropTypes.object,
        dataKey: PropTypes.string.isRequired,
    };
    let selectedColumns;


    //Load user Preff table colums
    //#region
    if (user.userDetail[0]) {
        selectedColumns = JSON.parse(user.userDetail[0].defaultColumnsOrders);
        // console.log(defaultColumns);
    } else {
        selectedColumns = [
            {
                key: 'ordernumberExtern',
                label: t('OrdernumberExtern'),
                fixed: false,
                fullText: false,
                sortable: true,
                resizable: true,
                flexGrow: 0,
                minWidth: 150,
                width: 200,
                styles: 'styles'
            },
            {
                key: 'orderDescription',
                label: t('OrderDescription'),
                fixed: false,
                fullText: true,
                sortable: true,
                resizable: true,
                flexGrow: 1
            },
            {
                key: 'orderStatus',
                label: t('OrderStatus'),
                fixed: false,
                fullText: false,
                sortable: true,
                resizable: true,
                // flexGrow: 0,
                minWidth: 80,
                width: 150
            },
            {
                key: "customer['companyname']",
                label: t('Customer'),
                fixed: false,
                fullText: true,
                sortable: true,
                resizable: true,
                flexGrow: 1
            },
            {
                key: "customer['streetname']",
                label: t('Streetname'),
                fixed: false,
                fullText: true,
                sortable: true,
                resizable: true,
                flexGrow: 1
            },
            {
                key: "location['locationname']",
                label: t('LocationName'),
                fixed: false,
                fullText: true,
                sortable: true,
                resizable: true,
                flexGrow: 1
            },
            {
                key: "location['locationadress']",
                label: t('Location') + " " + t('Streetname'),
                fixed: false,
                fullText: true,
                sortable: true,
                resizable: true,
                flexGrow: 1
            }
        ];
    }
    //#endregion

    //Default Columns when no preff
    //#region 
    const defaultColumns = [
        {
            key: 'ordernumberExtern',
            label: t('OrdernumberExtern'),
            fixed: false,
            fullText: false,
            sortable: true,
            resizable: true,
            flexGrow: 0,
            minWidth: 150,
            width: 200,
            styles: { color: 'ordernumberExtern' }
        },
        {
            key: 'orderDescription',
            label: t('OrderDescription'),
            fixed: false,
            fullText: true,
            sortable: true,
            resizable: true,
            flexGrow: 1
        },
        {
            key: 'orderStatus',
            label: t('OrderStatus'),
            fixed: false,
            fullText: false,
            sortable: true,
            resizable: true,
            // flexGrow: 0,
            minWidth: 80,
            width: 150
        },
        {
            key: "customer['companyname']",
            label: t('Customer'),
            fixed: false,
            fullText: true,
            sortable: true,
            resizable: true,
            flexGrow: 1
        },
        {
            key: "customer['streetname']",
            label: t('Streetname'),
            fixed: false,
            fullText: true,
            sortable: true,
            resizable: true,
            flexGrow: 1
        },
        {
            key: "location['locationname']",
            label: t('LocationName'),
            fixed: false,
            fullText: true,
            sortable: true,
            resizable: true,
            flexGrow: 1
        },
        {
            key: "location['locationadress']",
            label: t('Location') + " " + t('Streetname'),
            fixed: false,
            fullText: true,
            sortable: true,
            resizable: true,
            flexGrow: 1
        },
        {
            key: "children['1']['children']['0']['receiptStatus']",
            label: 'Bon Status',
            fixed: false,
            fullText: true,
            sortable: true,
            resizable: true,
            flexGrow: 1
        }
    ];
    //#endregion

    const CompactCell = props => <Cell {...props} style={{ padding: 4 }} />;
    const CompactHeaderCell = props => <HeaderCell {...props} style={{ padding: 4 }} />;
    const [compact, setCompact] = React.useState(false);
    const [columnKeys, setColumnKeys] = React.useState(defaultColumns.map(column => column.key));
    const columns = defaultColumns.filter(column => columnKeys.some(key => key === column.key));
    const CustomCell = compact ? CompactCell : (props, style) => <Cell {...props} />;
    const CustomHeaderCell = compact ? CompactHeaderCell : HeaderCell;

    // CustomTag.propTypes = {
    //     label: PropTypes.any.isRequired,
    //     item: PropTypes.any.isRequired,
    // };

    //Filter values Groups creation

    const customername = createMultiUseMap(filteredDataBySearch, 'customer.companyname', 'uuid', 'Klant naam');
    const customeradress = createMultiUseMap(filteredDataBySearch, 'customer.streetname', 'uuid', 'Klant straat');


    const locationadress = createMultiUseMap(filteredDataBySearch, 'location.locationadress', 'uuid', 'Locatie adres');
    const locationname = createMultiUseMap(filteredDataBySearch, 'location.locationname', 'uuid', 'Locatie naam');


    const combinedData = [
        ...customername.map(item => ({ ...item })),
        ...customeradress.map(item => ({ ...item })),
        ...locationname.map(item => ({ ...item })),
        ...locationadress.map(item => ({ ...item })),
    ];

    const [hideIcons, setHideIcons] = useState([]);

    // Function to determine if a row should hide its dropdown arrow icon
    const shouldHideIcon = (rowData) => {

        // Implement your logic here to determine if the row should hide its icon
        // For example, if the row has no children, return true
        //return rowData.length === 2 && isEmpty(rowData[0]) && isEmpty(rowData[1]);
        return rowData.children[0] === 0;
    };

    // Callback to update the state when the table data changes
    const handleDataChange = (newData) => {
        // Update the hideIcons array based on the new data
        const newHideIcons = newData.map(row => shouldHideIcon(row));
        setHideIcons(newHideIcons);
    };

    const [expandedKeys, setExpandedKeys] = useState([]);
    const handleToggleRow = (rowData) => {
        setExpandedKeys(prevKeys => {
            const index = prevKeys.indexOf(rowData.uuid);
            if (index !== -1) {
                // If the key already exists, remove it
                return [...prevKeys.slice(0, index), ...prevKeys.slice(index + 1)];
            } else {
                // If the key doesn't exist, add it
                return [...prevKeys, rowData.uuid];
            }
        });
    };

    const handleToggleAll = () => {
        if (expandedKeys.length === 0) {
            // If all nodes are collapsed, expand all nodes
            const keys = sortedAndFilteredData.map(node => node.uuid);
            setExpandedKeys(keys);
        } else {
            // If any node is expanded, collapse all nodes
            setExpandedKeys([]);
        }
    };

    const dropdownRef = useRef(null);
    const handleOpenDropdown = () => {
        // Ensure the dropdownRef is available
        if (dropdownRef.current) {
            // Open the TagPicker dropdown
            dropdownRef.current.open();

            // Wait a short moment for the dropdown to open before toggling the folding state of group items
            setTimeout(() => {
                // Find all group item elements and toggle their folding state
                const groupItems = document.querySelectorAll('.rs-picker-menu-group-title');
                groupItems.forEach(groupItem => {
                    groupItem.dispatchEvent(new MouseEvent('click', { bubbles: true }));
                });
            }, 0); // Adjust the timeout duration as needed
        }
    };


    //# ViewOrderModal //

    const [isViewOrderModalOpen, setIsViewOrderModalOpen] = useState(false);
    const [ViewOrderModalData, setDataViewOrderModel] = useState([{ DisplayName: 99999, customerInfo: { streetname: "No Street" } }]);


    const openViewOrderModal = (rowData) => {
        setDataViewOrderModel(rowData);
        setIsViewOrderModalOpen(true);

    };

    const closeViewOrderModal = () => {
        setIsViewOrderModalOpen(false);
    };

    const [updateMap, setSetUpdateMap] = useState();
    const hanldeDistance = (Adress) => {


        let hasRequested = false;
        function encodeURIComponents(obj) {
            for (const key in obj) {
                if (obj.hasOwnProperty(key)) {
                    obj[key] = encodeURIComponent(obj[key]);
                }
            }
            return obj;
        }

        const encodedCustomerinf = encodeURIComponents(Adress);

        var config = {
            method: 'get',
            url: `https://api.geoapify.com/v1/geocode/search?text=${encodedCustomerinf?.streetname}%20${encodedCustomerinf?.zipcode}%20${encodedCustomerinf?.city}%20${encodedCustomerinf?.country}&apiKey=03ee9ad7dc9c4e1596fecf18b1763e01`,
            headers: {}
        };
        if (!hasRequested) {
            // Your Axios request code here
            axios(config)
                .then(function (response) {
                    console.log(response);
                })
                .catch(function (error) {
                    console.log(error);
                });
            hasRequested = true;
        }

    }


    const ViewOrderModal = ({ isOpen, onRequestClose, data }): any => {
        const { t } = useTranslation();
        const customerinf = data?.customerInfo ? JSON.parse(data?.customerInfo) : null;
        const locationinf = data?.locationInfo ? JSON.parse(data?.locationInfo) : null;
        const installinf = data?.installationInfo ? JSON.parse(data?.installationInfo) : null;




        const [selectedFiles, setselectedFiles] = useState<any>([]);

        function handleAcceptedFiles(files: any) {
            const filteredFiles = files.filter(file => {
                const fileType = file.type;
                return fileType === 'application/pdf' || fileType.startsWith('image/');
            });
            files.map((file: any) =>
                Object.assign(file, {
                    preview: URL.createObjectURL(file),
                    formattedSize: formatBytes(file.size),
                })
            );
            if (filteredFiles.length > 0) {
                setselectedFiles(files)
                console.log("Success");
            } else {
                console.log("File not supported");

            }
        }

        /**
         * Formats the size
         */
        function formatBytes(bytes: any, decimals = 2) {
            if (bytes === 0) return "0 Bytes";
            const k = 1024;
            const dm = decimals < 0 ? 0 : decimals;
            const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

            const i = Math.floor(Math.log(bytes) / Math.log(k));
            return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
        }



        const [orderDescription, setOrderDescription] = useState(EditorState.createEmpty());
        const [EditorderDescription, setEditOrderDescription] = useState(false);
        const toggleEditOrderDescription = () => {
            setEditOrderDescription(prevState => !prevState);
        };
        let totalAllPrices = 0;
        const [openPopoverId, setOpenPopoverId] = useState(null);
        const containerRef = useRef(null);
        const togglePopover = (popoverId) => {
            setOpenPopoverId(openPopoverId === popoverId ? null : popoverId);
            console.log(popoverId);
            console.log("Popover open");

        };

        useEffect(() => {
            const handleBodyClick = (event) => {
                if (containerRef.current && !containerRef.current.contains(event.target)) {
                    setOpenPopoverId(null);
                }
            };

            document.body.addEventListener('click', handleBodyClick);

            return () => {
                document.body.removeEventListener('click', handleBodyClick);
            };
        }, []);

        const [receiptItems, setReceiptItems] = useState([]);

        const addReceiptItem = () => {
            setReceiptItems([...receiptItems, { name: '', price: 0, quantity: 1, discount: 0, canEdit: true }]);
        };

        const removeReceiptItem = (index) => {
            const updatedItems = [...receiptItems];
            updatedItems.splice(index, 1);
            setReceiptItems(updatedItems);
        };

        const calculateSubtotal = (item) => {
            if (item.discount) {
                return item.price * item.quantity - calculateDiscount(item)
            } else {
                return item.price * item.quantity;
            }
        };

        const calculateDiscount = (item) => {
            if (item.discount) {
                return (item.quantity * item.price) / 100 * item.discount;
            }
        };

        const handleItemChange = (index, field, value) => {
            const updatedItems = [...receiptItems];
            updatedItems[index][field] = value;
            // console.log(updatedItems);
            setReceiptItems(updatedItems);
            // updateItems(updatedItems);
        };

        const formatCurrency = (value) => {
            return new Intl.NumberFormat("nl-NL", {
                style: 'currency',
                currency: 'EUR', // You can change the currency code as needed
            }).format(value);
        };


        return (
            <div>
                <Modal
                    style={{ border: "0px" }}
                    isOpen={isOpen}
                    toggle={onRequestClose}
                    contentlabel="view order modal"
                    fullscreen={true}
                    size="xl">
                    <ModalHeader toggle={onRequestClose}>Ordernummer :  <strong>{data?.DisplayName}</strong></ModalHeader>
                    <ModalBody ref={containerRef}>
                        <Row>
                            <Card>
                                <CardBody>
                                    <div className="d-flex justify-content-between">
                                        <h4 className="card-title mb-4">Algemene gegevens</h4>
                                    </div>
                                    <Col xl={12}>
                                        <h5>Klant</h5>
                                        <b>{customerinf?.companyname}</b><br />
                                        {customerinf?.streetname}<br />
                                        {customerinf?.zipcode} {customerinf?.city}<br />
                                        {customerinf?.country}
                                    </Col>
                                    <Col xl={12}>
                                        <h5>Locatie</h5>
                                        <b>{locationinf?.locationname}</b><br />
                                        {locationinf?.locationadress}<br />
                                        {locationinf?.locationzipcode} {locationinf?.locationcity}<br />
                                        {locationinf?.locationcountry}
                                    </Col>
                                </CardBody>
                            </Card>
                        </Row>
                        <Row>
                            <Col className="mb-3" xs={24} xl={24}>
                                <Row>
                                    <Card>
                                        <CardBody>
                                            <div className="d-flex justify-content-between">
                                                <h4 className="card-title mb-4">Omschrijving <button className="btn-sm btn-soft-primary" onClick={toggleEditOrderDescription}>{EditorderDescription ? 'Opslaan' : 'Wijzigen'}</button></h4>
                                            </div>
                                            {orderDescription && !EditorderDescription &&
                                                <div>
                                                    <div className="editor-wrapper mt-3">
                                                        <Editor
                                                            disabled
                                                            readOnly={true}
                                                            editorState={orderDescription}
                                                            toolbarHidden={true}
                                                        />
                                                    </div>
                                                </div>
                                            }
                                            {EditorderDescription &&
                                                <Editor
                                                    editorState={orderDescription}
                                                    toolbarClassName="toolbarClassName"
                                                    // toolbarClassName="demo-toolbar-absolute"
                                                    // wrapperClassName="wrapperClassName"
                                                    editorClassName="editorClassName"
                                                    onEditorStateChange={setOrderDescription}
                                                />}
                                        </CardBody>
                                    </Card>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Card>
                                <CardBody>
                                    <div className="py-2">
                                        <Row>
                                            <Col xl={6}>
                                                <h5 className="font-size-16">Artikelen: </h5>
                                            </Col>
                                            <Col className='pull-right text-end'>
                                                <h5><button className='btn btn-soft-success' onClick={addReceiptItem}><i className="uil-comment-alt-plus"></i> Toevoegen</button> | <button className='btn btn-soft-primary' onClick={() => console.log("Search product")}><i className="uil-comment-alt-search"></i> Zoeken</button></h5>
                                            </Col>
                                        </Row>
                                        <div className="table-responsive">
                                            <table className="table align-middle table-nowrap table-centered mb-0">
                                                <thead>
                                                    <tr>
                                                        <th style={{ width: "70px" }}>Nr.</th>
                                                        <th style={{ flexGrow: 0 }}>Aantal</th>
                                                        <th>Artikel</th>
                                                        <th>Prijs</th>
                                                        <th>Korting (%)</th>
                                                        <th className="text-end">Korting (€)</th>
                                                        <th className="text-end" style={{ width: "120px" }}>Totaal</th>
                                                        <th>Aktie</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {receiptItems.map((item, index) => (
                                                        <tr key={index}>
                                                            <th scope="row">0{index + 1}</th>
                                                            <td><div onClick={(e) => handleItemChange(index, 'isEditing', true)}>
                                                                {item.isEditing & item.canEdit ? (<input
                                                                    style={{ width: 50, flexGrow: 0 }}
                                                                    maxLength={3}
                                                                    type="number"
                                                                    placeholder="Quantity"
                                                                    value={item.quantity}
                                                                    onBlur={(e) => handleItemChange(index, 'isEditing', false)}
                                                                    onChange={(e) => handleItemChange(index, 'quantity', parseFloat(e.target.value))}
                                                                />) : (
                                                                    <span>{item.quantity}</span>
                                                                )}
                                                            </div></td>
                                                            <td>
                                                                <div>
                                                                    <h5 className="text-truncate font-size-14 mb-1">
                                                                        <div onClick={(e) => handleItemChange(index, 'isEditing', true)}>
                                                                            {item.isEditing & item.canEdit ? (<input
                                                                                style={{ width: "100%" }}
                                                                                maxLength={600}
                                                                                type="text"
                                                                                placeholder="Artikel omschrijving"
                                                                                value={item.name}
                                                                                onBlur={(e) => handleItemChange(index, 'isEditing', false)}
                                                                                onChange={(e) => handleItemChange(index, 'name', e.target.value)}
                                                                            />
                                                                            ) : (
                                                                                item.name ? (<span style={item.canEdit ? { textDecoration: "underline" } : {}}>{item.name}</span>) : (<span style={item.canEdit ? { textDecoration: "underline" } : {}}>Geen artikel omschrijving</span>)
                                                                            )}
                                                                        </div></h5>
                                                                    <p className="text-muted mb-0"></p>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <CurrencyInput
                                                                    id="input-example"
                                                                    name="Price"
                                                                    placeholder="Price"
                                                                    defaultValue={item.price}
                                                                    decimalsLimit={2}
                                                                    style={{ width: 80 }}
                                                                    onValueChange={(value, name) => handleItemChange(index, 'price', value)}
                                                                />
                                                            </td>
                                                            <td><input
                                                                style={{ width: 80 }}
                                                                maxLength={3}
                                                                type="number"
                                                                placeholder="Discount"
                                                                max={100}
                                                                min={0}
                                                                value={item.discount}
                                                                onChange={(e) => handleItemChange(index, 'discount', parseFloat(e.target.value))}
                                                            /></td>
                                                            <td className="text-end">{item.discount && formatCurrency(calculateDiscount(item).toFixed(2))}</td>
                                                            <td className="text-end">{formatCurrency(calculateSubtotal(item).toFixed(2))}</td>
                                                            <td><button onClick={() => removeReceiptItem(index)}><i className="uil-trash-alt"></i></button></td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Row>
                        <Row>
                            <Col xs={24} sm={24} xl={16}>
                                <Row>
                                    <Col xs={24} sm={24} xl={24}>
                                        {data && data.children && data.children[1]?.children?.length > 0 && (
                                            <Card>
                                                <CardBody>
                                                    <div className="d-flex justify-content-between">
                                                        <h4 className="card-title mb-4">Gekoppelde werkbonnen ({data.children[1]?.children?.length})</h4>
                                                    </div>
                                                    {(data.children[1].children || []).map((item: any, key: number) => {
                                                        const parsedReceiptProducts = JSON.parse(item['receiptProducts']);
                                                        const totalPrice = Array.isArray(parsedReceiptProducts) ? parsedReceiptProducts.reduce((total, product) => total + ((product?.quantity * product?.price) - (((product?.quantity * product?.price) / 100) * product?.discount)), 0)
                                                            : 0;
                                                        const isOpen = openPopoverId === item['receiptNumber'];

                                                        totalAllPrices += totalPrice;
                                                    })}

                                                    < Table data={data.children[1].children} autoHeight>
                                                        <Column flexGrow={1}>
                                                            <HeaderCell>Bonnummer</HeaderCell>
                                                            <Cell>
                                                                {rowData => (

                                                                    <div className="d-flex fw-medium" id={rowData.receiptNumber} onClick={() => togglePopover(rowData.receiptNumber)}>
                                                                        <div>
                                                                            <p className="mb-0">{rowData.receiptNumber}</p>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </Cell>
                                                        </Column>

                                                        <Column flexGrow={1}>
                                                            <HeaderCell>Datum</HeaderCell>
                                                            <Cell>
                                                                {rowData => (
                                                                    <div className="d-flex">
                                                                        <div>
                                                                            <p className="mb-0">{moment(rowData.receiptDate).format("DD-MM-YYYY")}</p>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </Cell>
                                                            {/* <Cell dataKey="receiptDate" /> */}
                                                        </Column>

                                                        <Column flexGrow={1}>
                                                            <HeaderCell>Status</HeaderCell>
                                                            <Cell>
                                                                {rowData => (
                                                                    <div className="d-flex">
                                                                        <div className="me-2">
                                                                            <i className={rowData.icon}></i>
                                                                        </div>
                                                                        <div>
                                                                            <p className="mb-0">{rowData.receiptStatus}</p>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </Cell>
                                                        </Column>

                                                        <Column flexGrow={1}>
                                                            <HeaderCell>Medewerker</HeaderCell>
                                                            <Cell>
                                                                {rowData => (
                                                                    <div className="d-flex align-items-center">
                                                                        <div className="me-2">
                                                                            <p className="mb-0">{rowData.receiptMechanic}</p>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </Cell>
                                                        </Column>

                                                        <Column flexGrow={1}>
                                                            <HeaderCell>Actions</HeaderCell>
                                                            <Cell>
                                                                {rowData => (
                                                                    <div>
                                                                        <Button
                                                                            id={rowData.receiptNumber}
                                                                            type="button"
                                                                            color="primary"
                                                                            className="btn-sm mb-2"
                                                                            onClick={() => togglePopover(rowData.receiptNumber)}
                                                                        > Bekijk
                                                                        </Button> 
                                                                        <Button
                                                                            id={rowData.receiptNumber}
                                                                            type="button"
                                                                            color="primary"
                                                                            className="btn-sm mb-2"
                                                                            // onClick={() => togglePopover(rowData.receiptNumber)}
                                                                        > Import
                                                                        </Button>
                                                                        <Popover
                                                                            placement="right"
                                                                            isOpen={openPopoverId === rowData.receiptNumber}
                                                                            target={rowData.receiptNumber}
                                                                            toggle={() => togglePopover(rowData.receiptNumber)}
                                                                            className="popover-overright"
                                                                        >
                                                                            <PopoverHeader style={{ backgroundColor: "rgba(243, 78, 78, 0.1)" }}>
                                                                                {rowData.receiptNumber} / {rowData.receiptStatus} / {rowData.receiptMechanic}
                                                                            </PopoverHeader>
                                                                            <PopoverBody style={{ maxWidth: "1200px" }} className="popover-overright">
                                                                                <div>
                                                                                    <div className="mb-3">{rowData.receiptDescription}</div>
                                                                                    <ul style={{ listStyleType: "none", padding: 0 }}>
                                                                                        {rowData.receiptProducts &&
                                                                                            JSON.parse(rowData.receiptProducts).map((product, index) => (
                                                                                                <li
                                                                                                    key={index}
                                                                                                    style={{
                                                                                                        backgroundColor:
                                                                                                            index % 2 === 0
                                                                                                                ? "rgba(243, 78, 78, 0.1)"
                                                                                                                : "#ffffff"
                                                                                                    }}
                                                                                                >
                                                                                                    <span>
                                                                                                        <strong>{index + 1}: </strong>{" "}
                                                                                                    </span>
                                                                                                    <span>
                                                                                                        <strong>Aantal: </strong> {product?.quantity}{" "}
                                                                                                    </span>
                                                                                                    <span>
                                                                                                        <strong>Artikel: </strong> {product?.name}{" "}
                                                                                                    </span>
                                                                                                    <span>
                                                                                                        <strong>Prijs: </strong>{" "}
                                                                                                        {product?.quantity *
                                                                                                            (product?.price -
                                                                                                                (product?.price / 100) * product.discount)}{" "}
                                                                                                    </span>
                                                                                                </li>
                                                                                            ))}
                                                                                    </ul>
                                                                                    <div className="mb-3">
                                                                                        <strong>Prijs totaal bon: € </strong>
                                                                                    </div>
                                                                                    <button
                                                                                        className="btn-sm btn-soft-primary"
                                                                                        onClick={() =>
                                                                                            navigate(`/receipt-view/${rowData.receiptNumber}`, {
                                                                                                state: { receiptDetail: receipts }
                                                                                            })
                                                                                        }
                                                                                    >
                                                                                        Ga naar bon
                                                                                    </button>
                                                                                </div>
                                                                            </PopoverBody>
                                                                        </Popover>
                                                                    </div>
                                                                )}
                                                            </Cell>
                                                        </Column>

                                                    </Table>
                                                </CardBody>
                                            </Card>

                                        )}
                                    </Col>
                                    <Col className="mt-3" xl={24}>
                                        {data && data.children && data.children[0]?.children?.length > 0 && (
                                            <Card>
                                                <CardBody>
                                                    <div className="d-flex justify-content-between">
                                                        <h4 className="card-title mb-4">Rapporten ({data.children[0]?.children?.length})</h4>
                                                    </div>
                                                    <Table data={data.children[0].children} autoHeight>
                                                        {/* <Column width={50}>
                                                    <HeaderCell>
                                                        <div className="form-check">
                                                            <input className="form-check-input font-size-16" type="checkbox" value="" id="flexCheckexampleone" />
                                                            <label className="form-check-label" htmlFor="flexCheckexampleone"></label>
                                                        </div>
                                                    </HeaderCell>
                                                    <Cell width={50}>
                                                        <div className="form-check">
                                                            <input className="form-check-input font-size-16" type="checkbox" value="" id="flexCheckexampleone" />
                                                            <label className="form-check-label" htmlFor="flexCheckexampleone"></label>
                                                        </div>
                                                    </Cell>
                                                </Column> */}

                                                        <Column flexGrow={1}>
                                                            <HeaderCell>Rapportnummer</HeaderCell>
                                                            <Cell>
                                                                {rowData => (

                                                                    <div className="d-flex fw-medium" id={rowData.Displayname} onClick={() => togglePopover(rowData.DisplayName)}>
                                                                        <div>
                                                                            <p className="mb-0">{rowData.DisplayName}</p>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </Cell>
                                                        </Column>

                                                        <Column flexGrow={1}>
                                                            <HeaderCell>Datum</HeaderCell>
                                                            <Cell>
                                                                {rowData => (
                                                                    <div className="d-flex">
                                                                        <div>
                                                                            <p className="mb-0">{moment(rowData.createdDate).format("DD-MM-YYYY")}</p>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </Cell>
                                                            {/* <Cell dataKey="receiptDate" /> */}
                                                        </Column>

                                                        <Column flexGrow={1}>
                                                            <HeaderCell>Status</HeaderCell>
                                                            <Cell>
                                                                {rowData => (
                                                                    <div className="d-flex">
                                                                        <div className="me-2">
                                                                            <i className={rowData.icon}></i>
                                                                        </div>
                                                                        <div>
                                                                            <p className="mb-0">{rowData.reportData}</p>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </Cell>
                                                        </Column>

                                                        <Column flexGrow={1}>
                                                            <HeaderCell>Medewerker</HeaderCell>
                                                            <Cell>
                                                                {rowData => (
                                                                    <div className="d-flex align-items-center">
                                                                        <div className="me-2">
                                                                            <p className="mb-0">{rowData.createdBy}</p>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </Cell>
                                                        </Column>
                                                    </Table>
                                                </CardBody>
                                            </Card>
                                        )}
                                    </Col>
                                </Row>
                            </Col>
                            <Col xl={8} xs={24} sm={24} style={{ minHeight: "500px" }}>
                                <Card>
                                    <CardBody>
                                        <div className="d-flex justify-content-between">
                                            <h4 className="card-title mb-4">Documenten</h4>
                                        </div>
                                        <Form>
                                            <Dropzone
                                                onDrop={acceptedFiles => {
                                                    handleAcceptedFiles(acceptedFiles);
                                                }}
                                            >
                                                {({ getRootProps, getInputProps }) => (
                                                    <div className="dropzone">
                                                        <div
                                                            className="dz-message needsclick"
                                                            {...getRootProps()}
                                                        >
                                                            <input {...getInputProps()} />
                                                            <div className="mb-3">
                                                                <i className="display-4 text-muted uil uil-cloud-upload"></i>
                                                            </div>
                                                            <h4>Sleep in of druk om te uploaden.</h4>
                                                        </div>
                                                    </div>
                                                )}
                                            </Dropzone>
                                            <div className="dropzone-previews mt-3" id="file-previews">
                                                {selectedFiles.map((f: any, i: number) => {
                                                    return (
                                                        <Card
                                                            className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                            key={i + "-file"}
                                                        >
                                                            <div className="p-2">
                                                                <Row className="align-items-center">
                                                                    <Col className="col-auto">
                                                                        <img
                                                                            data-dz-thumbnail=""
                                                                            height="80"
                                                                            className="avatar-sm rounded bg-light"
                                                                            alt={f.name}
                                                                            src={f.preview}
                                                                        />
                                                                    </Col>
                                                                    <Col>
                                                                        <Link
                                                                            target="_blank"
                                                                            to={{ pathname: `./${f.preview}` }}
                                                                            className="text-muted font-weight-bold"
                                                                        >
                                                                            {f.name}
                                                                        </Link>
                                                                        <p className="mb-0">
                                                                            <strong>{f.formattedSize}</strong>
                                                                        </p>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        </Card>
                                                    );
                                                })}
                                            </div>
                                        </Form>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row>

                        </Row>

                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={onRequestClose}>
                            Do Something
                        </Button>{' '}
                        <Button color="secondary" onClick={onRequestClose}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }

    ViewOrderModal.propTypes = {
        isOpen: PropTypes.bool.isRequired,
        onRequestClose: PropTypes.func.isRequired,
        data: PropTypes.shape({
            customerInfo: PropTypes.any,
            locationInfo: PropTypes.any,
            installationInfo: PropTypes.any,
            DisplayName: PropTypes.any.isRequired,
            children: PropTypes.arrayOf(
                PropTypes.shape({
                    children: PropTypes.arrayOf(
                        PropTypes.shape({
                            // Define the PropTypes for nested children if needed
                        })
                    ),
                    // Define other PropTypes for children if needed
                })
            ),
            // Define other PropTypes for the data object if needed
        }),
    };

    useMetaTag('Orders | AWEKA Systeem', 'Orders AWEKA');

    return (
        <React.Fragment>
            <ViewOrderModal
                isOpen={isViewOrderModalOpen}
                onRequestClose={closeViewOrderModal}
                data={ViewOrderModalData}
            />
            <AddOrderModal />
            <div className="page-content">
                {/* <MetaTags>
                    <title>{t("Orders")} | AWEKA Energy Solutions</title>
                </MetaTags> */}

                <Card>
                    <CardBody>
                        <Container fluid>
                            {/* Render Breadcrumbs */}
                            <Breadcrumbs title="Algemeen" breadcrumbItem={[{ active: false, urlLink: "#", label: t('Orders') }, { active: true, urlLink: "#", label: t('View') }]} />

                            <Row>
                                <button className="btn btn-soft-primary mb-3 mr-3" style={{ marginRight: "5px" }} onClick={handleToggleAll}>
                                    {expandedKeys.length === 0 ? <i className="uil-expand-left" style={{ lineHeight: 1, fontSize: "1.5em" }}></i> : <i className="uil-compress-alt-left" style={{ lineHeight: 1, fontSize: "1.5em" }}></i>}
                                    {expandedKeys.length === 0 ? ' Alles uitklappen ' : ' Alles inklappen'}
                                </button>
                                <button className="btn btn-soft-primary mb-3 mr-3" style={{ marginRight: "5px" }} onClick={toggleCollapse}>
                                    {isCollapsed ? (
                                        <span>
                                            <i className="uil uil-eye me-2" style={{ lineHeight: 1, fontSize: "1.5em" }}></i>
                                            Filters weergeven
                                        </span>
                                    ) : (
                                        <span>
                                            <i className="uil uil-eye-slash me-2" style={{ lineHeight: 1, fontSize: "1.5em" }}></i>
                                            Filters verbergen
                                        </span>
                                    )}
                                </button>
                                <button className="btn btn-soft-success mb-3" onClick={toggle}>
                                    <span>
                                        <i className="uil uil-plus me-2" style={{ lineHeight: 1, fontSize: "1.5em" }}></i>
                                        Nieuwe Order
                                    </span>
                                </button>
                                {!isCollapsed && (
                                    <div>
                                        <Row>
                                            <Col className="mt-2">
                                                {/* {t('Relations')} ： */}
                                                <TagPicker
                                                    ref={dropdownRef}
                                                    groupBy="group"
                                                    style={{ minWidth: 200, maxWidth: 500, marginRight: "10px" }}
                                                    data={combinedData}
                                                    labelKey="label"
                                                    valueKey="label"
                                                    // value={columnKeys}
                                                    // renderValue={(label) => <CustomTag label={label} />}
                                                    onChange={(item) => handleFilterChange(item)}
                                                    cleanable={true}
                                                    placeholder={t('Filter')}
                                                    onOpen={handleOpenDropdown}
                                                />
                                            </Col>
                                            <Col className="mt-2">
                                                <div className="search-box" style={{ display: 'inline-flex' }}>
                                                    <div className="position-relative">
                                                        <Input
                                                            onChange={filterBySearch}
                                                            type="text"
                                                            className="form-control rounded bg-light border-0"
                                                            placeholder={t("Search")}
                                                            aria-label="Recipient's username"
                                                            value={filter.length >= 0 ? filter : ""}
                                                        />
                                                        <i className="bx bx-search font-size-16 search-icon"></i>
                                                    </div>

                                                    {filter.length > 0 ?
                                                        <button className="btn btn-soft-primary mb-3" onClick={() => {
                                                            setfilteredDataBySearch(rowData);
                                                            setPage(1);
                                                            setFilter('')
                                                        }}>
                                                            <span>
                                                                <i className="uil-search-minus me-2"></i>
                                                                Reset zoeken
                                                            </span>
                                                        </button> : null}
                                                </div>
                                            </Col>
                                        </Row>

                                        <hr />
                                        <TagPicker
                                            style={{ minWidth: 200, maxHeight: 100 }}
                                            data={defaultColumns}
                                            labelKey="label"
                                            valueKey="key"
                                            placeholder="Columns"
                                            value={columnKeys}
                                            onChange={setColumnKeys}
                                            cleanable={true}
                                            defaultOpen={false}
                                        />
                                        <hr />

                                    </div>
                                )}

                            </Row>
                            <Row>
                                <Col xl={24} xs={24}>
                                    {sortedAndFilteredData &&
                                        <div>
                                            {isLoading && !sortedAndFilteredData && !orders && !reports && !receipts ? (<div>Loading Data</div>) : (
                                                // {sortedAndFilteredData.length === 0 ? (
                                                //     renderEmptyTable()
                                                // ) : (
                                                <div>

                                                    <Table
                                                        id="rsuite-table-tree"
                                                        isTree
                                                        //   defaultExpandAllRows
                                                        // renderEmpty={renderEmptyTable}
                                                        key={sortedAndFilteredData.length}
                                                        sortColumn={sortColumn}
                                                        sortType={sortType}
                                                        onSortColumn={handleSortColumn}
                                                        autoHeight
                                                        rowKey="uuid"
                                                        ref={tableRef}
                                                        data={sortedAndFilteredData}
                                                        shouldUpdateScroll={true}
                                                        onChange={handleDataChange}
                                                        expandedRowKeys={expandedKeys}
                                                        renderTreeToggle={(icon, rowData) => {
                                                            // Determine whether to show or hide the icon based on state
                                                            const shouldHide = hideIcons[rowData.children];
                                                            return shouldHide ? null : icon;
                                                        }}
                                                        onExpandChange={(isOpen, rowData) => {
                                                            handleToggleRow(rowData);
                                                            //console.log(isOpen, rowData);
                                                        }}
                                                    >
                                                        <Column flexGrow={0} width={200} minWidth={200} fixed fullText sortable resizable>
                                                            <HeaderCell>{t('OrdernumberIntern')}</HeaderCell>
                                                            <Cell style={{ fontWeight: 700 }} dataKey="DisplayName" />
                                                        </Column>
                                                        <Column flexGrow={0} width={170} minWidth={80} resizable fixed>
                                                            <HeaderCell>{t('View')}</HeaderCell>
                                                            <ActionCell rowData={undefined} dataKey="ButtonID" onClick={undefined} />
                                                        </Column>
                                                        {columns.map(column => {
                                                            const { key, label, styles, ...rest } = column;
                                                            return (
                                                                <Column {...rest} key={key}>
                                                                    <CustomHeaderCell>{label}</CustomHeaderCell>
                                                                    <CustomCell style={styles} dataKey={key} />
                                                                </Column>
                                                            );
                                                        })}
                                                    </Table>

                                                    <div>
                                                        <Pagination
                                                            prev
                                                            next
                                                            first
                                                            last
                                                            ellipsis
                                                            boundaryLinks
                                                            maxButtons={5}
                                                            size="xs"
                                                            layout={['total', '-', 'limit', '|', 'pager', 'skip']}
                                                            total={filteredDataBySearch.length}
                                                            limitOptions={[10, 30, 50, 100]}
                                                            limit={limit}
                                                            activePage={page}
                                                            onChangePage={setPage}
                                                            onChangeLimit={handleChangeLimit}
                                                        />
                                                    </div></div>)}
                                        </div>
                                    }
                                </Col>
                            </Row>
                        </Container>
                    </CardBody>
                </Card>
            </div>
        </React.Fragment>
    );
}

export default Orders;