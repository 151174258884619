import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Col, Container, Row } from "reactstrap";
import WidgetData from "./Widgets";
import SalesAnalytics from './SalesAnalytics';
import EarningReports from './EarningReports';
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import CustomTable from "src/components/CustomTable";
import { AppDispatch, RootState } from "src";
import { Loader } from "rsuite";

const Sales = () => {

    const { t } = useTranslation();
    const customers = useSelector((state: RootState) => state.customer);
    const isLoading = customers.customers.length === 0; 
    const tableDataColumns = [
        { label: "Id", dataName: "UUID", props: { fixed: true, align: "center" } },
        { label: t('Active'), dataName: "Active", props: { fixed: false, flexGrow: 0, resizable: false, sortable: true, fullText: false, badge: true } },
        { label: t('RelationType'), dataName: "relationType", props: { fixed: false, flexGrow: 0, resizable: false, sortable: true, fullText: false, minWidth: 150, width: 150, badge: true } },
        { label: t('CustomerName'), dataName: "companyname", props: { fixed: false, flexGrow: 1, resizable: true, sortable: true, fullText: true, style: { fontWeight: "700" }, minWidth: 300, } },
        { label: t('Streetname'), dataName: "streetname", props: { fixed: false, flexGrow: 1, resizable: true, sortable: true, fullText: true } },
        { label: t('Zipcode'), dataName: "zipcode", props: { fixed: false, flexGrow: 1, resizable: true, sortable: true, fullText: false } },
        { label: t('City'), dataName: "city", props: { fixed: false, flexGrow: 1, resizable: true, sortable: true, fullText: false } },
    ]

    //#endregion
    const handleClick = (e) => {
        console.log("Gegevenes => "+e);
    }

    if (isLoading) {
        return <Loader content="Loading..." />;
    }
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Algemeen overzicht" breadcrumbItem={[{active: true, urlLink: "#", label: "Verkoop"}]} />

                    <Row>

                        <Col xl={12}>
                        {customers.customers &&
                                <CustomTable
                                    PageSize={15}
                                    dataRows={customers.customers}
                                    onClickEvent={handleClick}
                                    columns={tableDataColumns}
                                    isEdit={false}
                                    isModal={{ enable: true, modal: "Customers" }}
                                />
                            }
                        </Col>

                    </Row>

                    <Row>
                        <WidgetData />
                    </Row>

                    <Row>
                        <Col xl={4}>
                            <EarningReports />
                        </Col>

                        <Col xl={8}>
                            <SalesAnalytics />
                        </Col>
                    </Row>

                    <Row>
                        <Col xl={8}>
                            {/* <Orders /> */}
                        </Col>

                        <Col xl={4}>
                            {/* <SalesByCountry /> */}
                        </Col>
                    </Row>

                </Container>
            </div>
        </React.Fragment>
    );
}

export default Sales;
