import { createAsyncThunk } from "@reduxjs/toolkit";
// import { customersFailed, customersSuccessful } from "./reducer";

import { getReports as getReportsApi,  getReportbyID as getReportApi } from "../../helpers/real_helper";

export const getReports = createAsyncThunk('reports/getReports', async (report:{}, {rejectWithValue}) => {
    try {
      const res = await getReportsApi(report);
      //console.log(res);
      return res;
    } catch (err) {
      return rejectWithValue({ error: err.message })
    }
  });

  export const getReport = createAsyncThunk('reports/getReport', async (report:{}, {rejectWithValue}) => {
    try {
      const res = await getReportApi(report);
      return res;
    } catch (err) {
      return rejectWithValue({ error: err.message })
    }
  })




