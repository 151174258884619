import { createSlice } from "@reduxjs/toolkit";
import { UserLoginReal } from "./thunk";
import Cookies from "js-cookie";



interface LoginStateType {
    users: Array<any>,
    userProfile: object,
    status: String,
    error?: string | object | null | undefined | unknown,
    user?: any,
    history?: any
}
export const initialState: LoginStateType = {
    users: [],
    userProfile: {},
    status: 'idle',
    error: '',
    user: '',
    history: ''
};
const loginSlice = createSlice({
    name: "login",
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(UserLoginReal.pending, (state: any, action: any) => {
            state.status = 'loading';
        });
        builder.addCase(UserLoginReal.fulfilled, (state: any, action: any) => {
            if (action.payload.APIResponseStatus === 'success') {
                Cookies.set("isLogged", "true", {
                    expires: 1,
                    secure: true,
                    sameSite: "strict",
                    path: "/",
                });
                Cookies.set("userData", JSON.stringify(action.payload), {
                    expires: 1,
                    secure: true,
                    sameSite: "strict",
                    path: "/",
                });
                console.log("action payload => " + action.payload);
                state.user = action.payload;
                state.status = 'success';
            }

        });
        builder.addCase(UserLoginReal.rejected, (state, action) => {
            state.error = action.payload;
            state.status = 'error';
            //const history = useHistory();
            //UserLogoutReal(history);
        });
    }
});

export default loginSlice.reducer;