import React, { useState } from "react";
import Draggable from 'react-draggable'; // The default
import any from "react/jsx-runtime";
import { Card } from "reactstrap";
import "./style.css";


const ToolsDemo = () => {

    const [activeDrags, setactiveDrags] = useState();
    const [deltaPosition, setdeltaPosition] = useState({x:any , y:any});
    const [controlledPosition, setcontrolledPosition] = useState({});
  
    const handleDrag = (e, ui) => {
      // const {x, y} = deltaPosition;
      console.log("e => "+ e);
      console.log("ui => "+ ui);
      setdeltaPosition({
        x: deltaPosition.x + ui.deltaX,
        y: deltaPosition.y + ui.deltaY,
      })
      console.log("DeltaPosition X => "+Number.parseFloat(deltaPosition.x));
      console.log("DeltaPosition Y => "+deltaPosition.y);
    };
  
    const onStart = (e:any, data:any) => {
      console.log("e => "+ e);
      console.log("Data => "+ data.x);
      console.log(activeDrags);
      console.log("DeltaPosition => "+deltaPosition);
      
      
      setactiveDrags(activeDrags)
      // setState({activeDrags: ++state.activeDrags});
    };
  
    const onStop = (e:any, data:any) => {
      console.log("e => "+ e);
      console.log("Data => "+ data.x);
      setactiveDrags(activeDrags)
      //setState({activeDrags: --state.activeDrags});
    };
    const onDrop = (e) => {
      setactiveDrags(activeDrags)
      // setState({activeDrags: --this.state.activeDrags});
      if (e.target.classList.contains("drop-target")) {
        alert("Dropped!");
        e.target.classList.remove('hovered');
      }
    };
    const onDropAreaMouseEnter = (e) => {
      if (activeDrags) {
        e.target.classList.add('hovered');
      }
    }
    const onDropAreaMouseLeave = (e) => {
      e.target.classList.remove('hovered');
    }
  
    // For controlled component
    const adjustXPos = (e, position) => {
      e.preventDefault();
      e.stopPropagation();
      const {x, y} = position;
      setcontrolledPosition({x: x - 10, y});
    };
  
    const adjustYPos = (e, position) => {
      e.preventDefault();
      e.stopPropagation();
      // const {controlledPosition} = ;
      const {x, y} = position;
      setcontrolledPosition({x: x, y: y - 10});
    };
  
    const onControlledDrag = (e, position) => {
      const {x, y} = position;
      setcontrolledPosition({x: x, y: y});
    };
  
    const onControlledDragStop = (e, position) => {
      onControlledDrag(e, position);
      onStop(e, position);
    };
  
  
      const dragHandlers = {onStart: onStart, onStop: onStop};
      const states = {deltaPosition, controlledPosition};
      //const {deltaPosition, controlledPosition} = state;
      return (
        <React.Fragment>
          <Card>
        <div className="page-content">
          <h1>React Draggable</h1>
          <div>Active DragHandlers: {activeDrags}</div>
           <Draggable grid={[10, 10]} {...dragHandlers}>
            <div className="box">I can be dragged anywhere</div>
          </Draggable> 
          <Draggable {...dragHandlers}>
            <div className="box cursor-x">I can only be dragged horizonally (x axis)</div>
          </Draggable>
         {/* <Draggable axis="y" {...dragHandlers}>
            <div className="box cursor-y">I can only be dragged vertically (y axis)</div>
          </Draggable>
          <Draggable onStart={() => false}>
            <div className="box">I don't want to be dragged</div>
          </Draggable> */}
          <Draggable onDrag={handleDrag} {...dragHandlers}>
            <div className="box">
              <div>I track my deltas</div>
              {/* <div>x: {states.deltaPosition.x}, y: {states.deltaPosition.y}</div> */}
            </div>
          </Draggable>
          {/* <Draggable handle="strong" {...dragHandlers}>
            <div className="box no-cursor">
              <strong className="cursor"><div>Drag here</div></strong>
              <div>You must click my handle to drag me</div>
            </div>
          </Draggable>
          <Draggable handle="strong">
            <div className="box no-cursor" style={{display: 'flex', flexDirection: 'column'}}>
              <strong className="cursor"><div>Drag here</div></strong>
              <div style={{overflow: 'scroll'}}>
                <div style={{background: 'yellow', whiteSpace: 'pre-wrap'}}>
                  I have long scrollable content with a handle
                  {'\n' + Array(40).fill('x').join('\n')}
                </div>
              </div>
            </div>
          </Draggable>
          <Draggable cancel="strong" {...dragHandlers}>
            <div className="box">
              <strong className="no-cursor">Can't drag here</strong>
              <div>Dragging here works</div>
            </div>
          </Draggable>
          <Draggable grid={[25, 25]} {...dragHandlers}>
            <div className="box">I snap to a 25 x 25 grid</div>
          </Draggable>
          <Draggable grid={[50, 50]} {...dragHandlers}>
            <div className="box">I snap to a 50 x 50 grid</div>
          </Draggable>
          <Draggable bounds={{top: -100, left: -100, right: 100, bottom: 100}} {...dragHandlers}>
            <div className="box">I can only be moved 100px in any direction.</div>
          </Draggable>
          <Draggable {...dragHandlers}>
            <div className="box drop-target" onMouseEnter={onDropAreaMouseEnter} onMouseLeave={onDropAreaMouseLeave}>I can detect drops from the next box.</div>
          </Draggable>
          {/* <Draggable {...dragHandlers} onStop={onDrop}>
            <div className={`box ${activeDrags ? "no-pointer-events" : ""}`}>I can be dropped onto another box.</div>
          </Draggable> 
          <div className="box" style={{height: '500px', width: '500px', position: 'relative', overflow: 'auto', padding: '0'}}>
            <div style={{height: '1000px', width: '1000px', padding: '10px'}}>
              <Draggable bounds="parent" {...dragHandlers}>
                <div className="box">
                  I can only be moved within my offsetParent.<br /><br />
                  Both parent padding and child margin work properly.
                </div>
              </Draggable>
              <Draggable bounds="parent" {...dragHandlers}>
                <div className="box">
                  I also can only be moved within my offsetParent.<br /><br />
                  Both parent padding and child margin work properly.
                </div>
              </Draggable>
            </div>
          </div>
          <Draggable bounds="body" {...dragHandlers}>
            <div className="box">
              I can only be moved within the confines of the body element.
            </div>
          </Draggable>
          <Draggable {...dragHandlers}>
            <div className="box" style={{position: 'absolute', bottom: '100px', right: '100px'}}>
              I already have an absolute position.
            </div>
          </Draggable>
          <Draggable defaultPosition={{x: 25, y: 25}} {...dragHandlers}>
            <div className="box">
              {"I have a default position of {x: 25, y: 25}, so I'm slightly offset."}
            </div>
          </Draggable>
          <Draggable positionOffset={{x: '-10%', y: '-10%'}} {...dragHandlers}>
            <div className="box">
              {'I have a default position based on percents {x: \'-10%\', y: \'-10%\'}, so I\'m slightly offset.'}
            </div>
          </Draggable>
          <Draggable position={controlledPosition} {...dragHandlers} onDrag={onControlledDrag}>
            <div className="box">
              My position can be changed programmatically. <br />
              I have a drag handler to sync state.
              <div>
                <a href="#" onClick={adjustXPos}>Adjust x ({controlledPosition.x})</a>
              </div>
              <div>
                <a href="#" onClick={adjustYPos}>Adjust y ({controlledPosition.y})</a>
              </div>
            </div>
          </Draggable>
          <Draggable position={controlledPosition} {...dragHandlers} onStop={onControlledDragStop}>
            <div className="box">
              My position can be changed programmatically. <br />
              I have a dragStop handler to sync state.
              <div>
                <a href="#" onClick={adjustXPos}>Adjust x ({controlledPosition.x})</a>
              </div>
              <div>
                <a href="#" onClick={adjustYPos}>Adjust y ({controlledPosition.y})</a>
              </div>
            </div>
          </Draggable> */}
  
        </div>
        </Card>
        </React.Fragment>
      );
  }
  export default ToolsDemo;
  