import React, { useEffect, useRef, useState } from 'react';
import { UncontrolledDropdown, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Card, CardBody, Form, Input, CardHeader, Row, Col, Toast, Container, Modal, ModalHeader, ModalBody, ModalFooter, Button, Label } from "reactstrap";
import { Link, useNavigate } from 'react-router-dom';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Customers from "../Customer/CustomersTable";
import MetaTags from "react-meta-tags";

import cookie from "js-cookie";
import { useDispatch, useSelector } from 'react-redux';
import { getToolsLayouts, getToolsLayoutsbyID, setToolsLayoutbyID } from 'src/slices/thunks';
import { useTranslation } from 'react-i18next';
import dataloaded from "../Common/DataLoaded";
import GridLayout from "react-grid-layout";
import "../../../node_modules/react-grid-layout/css/styles.css";
import "../../../node_modules/react-resizable/css/styles.css";
import Logo from "../../assets/images/logo-light-aweka.png";
import BackgroundPDF from "../../assets/images/backgrounds/backgroundAwekaRapport.png";
import "./style.css";
import { toast } from 'react-toastify';
import { url } from 'inspector';
import { RootState, AppDispatch } from 'src';
interface IState {
    items: any;
}

const Tools = () => {
    const layoutDefault = [
        { i: "text1", x: 0, y: 0, w: 5, h: 1, static: false, content: 'textbox' },
        { i: "text2", x: 1, y: 0, w: 5, h: 1, minW: 2, content: 'textbox' },
    ];
    const [search_Menu, setsearch_Menu] = useState<boolean>(false);
    const [items, setItems] = useState({});
    const [cols, setCols] = useState();
    const [layout, setLayout] = useState([]);
    const [modal, setModal] = useState(false);
    const [sortModal, setSortModal] = useState();
    const [SelectLayout, setSelectedLayout] = useState([]);
    const [UUID, setUUID] = useState(0);

    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const toolLayouts = useSelector((state: RootState) => state.tools);

    const getFromDatabase = async (key: string, selected: any) => {
        try {
            //console.log("Selected => " + selected);

            const result = await dispatch(getToolsLayoutsbyID(toolLayouts?.toolsLayouts[selected - 1]))

            let ls = {};
            // const obj = JSON.parse(cookie.get("userData"));
            // const datain: {} = { email: obj.email, token: obj.token, id: selected, METHOD: "GetLayoutbyID" };

            return subGetFromDatabas(result, key);
            // layoutparse

            // ls = JSON.parse(toolLayouts?.toolsLayoutDetail?.layoutcontent) || {};
            // console.log(ls[key]);

            // return ls[key];

        } catch (e) {
            // nothing to do
        }
    }
    const subGetFromDatabas = (result, key) => {
        console.log("result => " + JSON.stringify(result.payload));
        let ls = {};
        ls = JSON.parse(result.payload.layoutcontent) || {};
        console.log(ls[key]);

        return ls[key];

    }
    const saveToDatabase = (key, value) => {

        const jsonData = JSON.stringify({
            [key]: value
        });
        const obj = JSON.parse(cookie.get("userData"));
        const datain: {} = { email: obj.email, token: obj.token, id: UUID + 1, newData: jsonData, METHOD: "UpdateToolLayoutbyID" };
        setLayout(value)
        dispatch(setToolsLayoutbyID(datain))
    }




    const { error } = useSelector((state: any) => ({
        error: state.tools.error,

    }));



    useEffect(() => {
        const obj = JSON.parse(cookie.get("userData"));
        const datain: {} = { email: obj.email, token: obj.token, METHOD: "GetLayouts" };
        dispatch(getToolsLayouts(datain))
    }, [UUID, layout])

    // Toggle searchF
    const toggleSearch = () => {
        setsearch_Menu(!search_Menu);
    };
    const { status, error: any } = toolLayouts



    const onAddItem = (kind: string, ID) => {

        console.log(layout);

        // Add a new item. It must have a unique key!
        const id = layout.length + 1;
        const item = {
            i: '' + kind + '' + id + '',
            x: (Object.values(items).length * 2) % (cols || 20),
            y: Infinity, // puts it at the bottom Infinity
            w: 5,
            h: 1,
            content: 'textbox'
        }
        setLayout(prevList => [...prevList, item])
        console.log(layout);

        //saveToLS("layout", layout);
        //console.log(layout);
    }

    const onLayoutChange = (layout, layouts) => {
        setLayout(layout);
        //saveToDatabase("layout", layout);
    }
    const handleRemoveItem = i => {
        setLayout(layout.filter(layout => layout.i !== i))
    }

    const divList = () => {
        const rowList = [];
        //console.log("selected layout => "+SelectLayout)
        // console.log("LAyout change => "+JSON.stringify(layout));
        if (layout.length > 0) {
            // console.log("LAyout change => " + layout.length);
            for (let i = 0; i < layout.length; i++) {
                //console.log("Layout => "+JSON.stringify(layout[i].i));

                let match = layout[i].i.replace(/[^A-Za-z]/g, '');

                const removeStyle = {
                    position: "absolute",
                    right: "2px",
                    top: 0,
                    cursor: "pointer"
                };

                //rowList.push(<div key={layout[i].i}>NewDiv layoutnr {i}</div>);
                switch (match) {
                    case 'text':

                        rowList.push(<div key={layout[i].i} onClick={event => handleClick(event, 'text')}>NewDiv layoutnr {i}<span className="remove" id='nopdf' onClick={() => handleRemoveItem(layout[i].i)}>x </span></div>);
                        break;
                    case 'input':
                        rowList.push(<div key={layout[i].i} onClick={event => handleClick(event, 'input')}><Input type="email" name="email" id="exampleEmail" placeholder="with a placeholder" /><span className="remove" onClick={() => handleRemoveItem(layout[i].i)}>x </span></div>);
                        break;
                    case 'img':
                        rowList.push(<div key={layout[i].i} onClick={event => handleClick(event, 'img')}><img style={{ maxWidth: "100%" }} src={Logo} /><span className="remove" id='nopdf' onClick={() => handleRemoveItem(layout[i].i)}>x </span></div>);
                        break;
                    case undefined:
                        rowList.push(<div key={layout[i].i} onClick={event => handleClick(event, 'text')}>NewDiv layoutnr {i}<span className="remove" onClick={() => handleRemoveItem(layout[i].i)}>x </span></div>);
                        break;
                    default:
                        rowList.push(<div key={layout[i].i} onClick={event => handleClick(event, 'text')}>NewDiv layoutnr {i}<span className="remove" onClick={() => handleRemoveItem(layout[i].i)}>x </span></div>);
                        break;
                }


            }
            return rowList;
        } else {
            return rowList.push(<div key='1'>Loading</div>);
        }
    }


    // Double Click handler
    //#region
    const handleClick = (event, kind) => {
        //console.log(event.detail);
        switch (event.detail) {
            case 1: {
                // console.log('single click');
                break;
            }
            case 2: {
                setModal(!modal)
                //console.log('double click and open modal');
                //console.log(kind);
                setSortModal(kind);

                break;
            }
            case 3: {
                console.log('triple click');
                break;
            }
            default: {
                break;
            }
        }
    };
    //#endregion

    const toggle = () => setModal(!modal);
    const handleSelect = async (e) => {
        const Selector = JSON.parse(e);
        const layoutparse = getFromDatabase("layout", Selector.uuid)
        setSelectedLayout(await layoutparse)
    }

    useEffect(() => {
        setLayout(SelectLayout)
    }, [SelectLayout]);
    useEffect(() => {
        saveToDatabase("layout", layout);
    }, [layout]);

    useEffect(() => {
        // dataloaded({ toolLayouts, successful: 'Werkt wel' });
        dataloaded({ id: 1, status, error, successful: "Layouts geladen", time: 1000 })
    }, [status]);

    const printContent = () => {
        const printableContent = document.getElementById("printable-content");
        const printWindow = window.open("", "", "height=1000,width=1000");
        printWindow.document.write(printableContent.innerHTML);
        printWindow.print();
    };
    const breadItems = [
        {active: true, urlLink: "#", label: "Tools"}
    ]

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Tools | AWEKA Energy Solutions</title>
                </MetaTags>
                <Modal isOpen={modal} toggle={toggle} >
                    <ModalHeader>{sortModal}</ModalHeader>
                    <ModalBody>
                        <b>Look at the top right of the page/viewport!</b>
                        <br />
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                        culpa qui officia deserunt mollit anim id est laborum.
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={toggle}>
                            Do Something
                        </Button>{' '}
                        <Button color="secondary" onClick={toggle}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>
                <Container fluid>
                    <Breadcrumbs title="Dashboards" breadcrumbItem={breadItems} />

                    <Row>
                        <Col xl={12}>
                            <Row>
                                <Col>
                                    <div className="form-floating mb-3">
                                        <Input type="select"
                                            id='nopdf'
                                            className="form-select"
                                            //id="floatingSelectGrid"
                                            aria-label="Floating label select example"
                                            defaultValue=""
                                            onChange={e => handleSelect(e.target.value)}
                                        > {(toolLayouts?.toolsLayouts || []).map((order: any, key: number) => (
                                            <>
                                                <option value={JSON.stringify({ testid: key, uuid: order['UUID'] })}>{order['layoutname']}</option>
                                            </>
                                        ))}
                                        </Input>
                                        <Label id='nopdf' htmlFor="floatingSelectGrid">Selecteer Layout</Label>
                                    </div><button onClick={printContent}>Print Page</button><button id='nopdf' onClick={() => onAddItem('text', '1')} >Add Item</button>  <button id='nopdf' onClick={() => onAddItem('input', '1')} >Add input</button>  <button id='nopdf' onClick={() => onAddItem('img', '1')} >Add picture</button>

                                </Col>
                            </Row>

                            <Row>


                                <div className='my-auto mx-auto pdfShow' style={{ backgroundColor: "#FFFFFF", backgroundSize: "cover", backgroundImage: `url(${BackgroundPDF})`, maxWidth: "595px", minHeight: "842px", pageBreakAfter: "always" }}>
                                    <div id='printable-content' className='pdfShow' style={{ backgroundSize: "cover", backgroundImage: `url(${BackgroundPDF})` }}>
                                        {layout ? <GridLayout
                                            className="layout"
                                            layout={layout}
                                            cols={20}
                                            rowHeight={20}
                                            width={595}
                                            height={842}
                                            autoSize={true}
                                            onLayoutChange={(layout, layouts) =>
                                                onLayoutChange(layout, layouts)
                                            }
                                        >
                                            {/* <div key="a"><img style={{ maxWidth: "100%" }} src={Logo} /></div>
                                        <div key="b"><hr></hr></div>
                                        <div key="c">Eerste Bijzondere Inspectie</div>
                                        <div key="d" style={{ overflow: "hidden" }}><Customers /></div> */}
                                            {divList()}
                                        </GridLayout> : ""}</div>
                                </div>
                            </Row>

                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment >
    );
};

export default Tools;
