import { createSlice } from "@reduxjs/toolkit";
import { getUsers, getUser, addUser, getAuth, UpdateUser } from "./thunk";


interface UsersStateType {
    users: Array<any>;
    userDetail: object;
    statusUsers: string;
    errorUsers?: string | object | null | undefined | unknown;
    setAdduser: Array<any>;
    authState: Array<any>;
}
export const initialState: UsersStateType = {
    users: [],
    userDetail: {},
    statusUsers: 'idle',
    errorUsers: {},
    setAdduser: [],
    authState: [],
};

const usersSlice = createSlice({
    name: "users",
    initialState,
    reducers: {
        // customersSuccessful(state, action) {
        //     state.status    = 'Success'
        //     state.customers = action.payload
        // },
        // customersFailed(state, action) {
        //     state.error     = action.payload
        //     state.customers = action.payload
        // },
    },
    extraReducers: builder => {
        //getUsers
        builder.addCase(getUsers.pending, (state: any, action: any) => {
            state.statusUsers = 'loading';
        });
        builder.addCase(getUsers.fulfilled, (state: any, action: any) => {
            state.users = action.payload;
            state.statusUsers = 'success';
        });
        builder.addCase(getUsers.rejected, (state, action) => {
            state.errorUsers = action.payload || null;
            state.statusUsers = 'error';
        });

        //getUser
        builder.addCase(getUser.pending, (state: any, action: any) => {
            state.statusUsers = 'loading';
        });
        builder.addCase(getUser.fulfilled, (state: any, action: any) => {
            state.userDetail = action.payload;
            state.statusUsers = 'success';
        });
        builder.addCase(getUser.rejected, (state, action) => {
            state.errorUsers = action.payload || null;
            state.statusUsers = 'error';
        });

         //addUser
         builder.addCase(addUser.pending, (state: any, action: any) => {
            state.statusUsers = 'loading';
        });
        builder.addCase(addUser.fulfilled, (state: any, action: any) => {
            state.setAdduser = action.payload;
            state.statusUsers = 'success';
        });
        builder.addCase(addUser.rejected, (state, action) => {
            state.errorUsers = action.payload || null;
            state.statusUsers = 'error';
        });
        //getAuth User
        builder.addCase(getAuth.pending, (state: any, action: any) => {
            state.statusUsers = 'loading';
        });
        builder.addCase(getAuth.fulfilled, (state: any, action: any) => {
            state.authState = action.payload;
            state.statusUsers = 'success';
        });
        builder.addCase(getAuth.rejected, (state, action) => {
            state.errorUsers = action.payload || null;
            state.statusUsers = 'error';
        });
        
        //Update user
        builder.addCase(UpdateUser.pending, (state: any, action: any) => {
            state.statusUsers = 'loading';
        });
        builder.addCase(UpdateUser.fulfilled, (state: any, action: any) => {
            state.userDetail = action.payload;
            state.statusUsers = 'success';
        });
        builder.addCase(UpdateUser.rejected, (state, action) => {
            state.errorUsers = action.payload || null;
            state.statusUsers = 'error';
        });
    },
});
// export const {
//     // customersSuccessful,
//     // customersFailed
// } = usersSlice.actions

export default usersSlice.reducer;