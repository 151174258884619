import { createAsyncThunk } from "@reduxjs/toolkit";
import { getLocations as getLocationsApi,  getLocationbyID as getLocationbyIDApi, getLocationbyCustomerID as getLocationbyCustomerIDApi } from "../../helpers/real_helper";

export const getLocations = createAsyncThunk('locations/getLocations', async (locations:{}, {rejectWithValue}) => {
    try {
      const res = await getLocationsApi(locations);
      //console.log(res);
      return res;
    } catch (err) {
      return rejectWithValue({ error: err.message })
    }
  });

  export const getLocationbyID = createAsyncThunk('locations/getLocationbyID', async (locationbyID:{}, {rejectWithValue}) => {
    try {
      const res = await getLocationbyIDApi(locationbyID);
      //console.log(res);
      return res;
    } catch (err) {
      return rejectWithValue({ error: err.message })
    }
  });

  export const getLocationbyCustomerID = createAsyncThunk('locations/getLocationbyCustomerID', async (locationbyCustomerID:{}, {rejectWithValue}) => {
    try {
      const res = await getLocationbyCustomerIDApi(locationbyCustomerID);
      //console.log(res);
      return res;
    } catch (err) {
      return rejectWithValue({ error: err.message })
    }
  });

