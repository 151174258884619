import { createSlice } from "@reduxjs/toolkit";
import { getCustomers, getCustomer, setAddCustomer, deleteCustomer } from "./thunk";


interface CustomerStateType {
    customers: Array<any>;
    customerDetail: object;
    status: string;
    error?: string | object | null | undefined | unknown;
    setAddcustomer: Array<any>;
}
export const initialState: CustomerStateType = {
    customers: [],
    customerDetail: {},
    status: 'idle',
    error: {},
    setAddcustomer: [],
};

const customersSlice = createSlice({
    name: "customers",
    initialState,
    reducers: {
        // customersSuccessful(state, action) {
        //     state.status    = 'Success'
        //     state.customers = action.payload
        // },
        // customersFailed(state, action) {
        //     state.error     = action.payload
        //     state.customers = action.payload
        // },
    },
    extraReducers: builder => {
        builder.addCase(getCustomers.pending, (state: any, action: any) => {
            state.status = 'loading';
        });
        builder.addCase(getCustomers.fulfilled, (state: any, action: any) => {
            state.customers = action.payload;
            state.status = 'success';
        });
        builder.addCase(getCustomers.rejected, (state, action) => {
            state.error = action.payload || null;
            state.status = 'error';
        });
        // Get Customer by ID
        builder.addCase(getCustomer.pending, (state: any, action: any) => {
            state.status = 'loading';
        });
        builder.addCase(getCustomer.fulfilled, (state: any, action: any) => {
            state.customerDetail = action.payload;
            state.status = 'success';
        });
        builder.addCase(getCustomer.rejected, (state, action) => {
            state.error = action.payload || null;
            state.status = 'error';
        });
         // Set ADD Customer
         builder.addCase(setAddCustomer.pending, (state: any, action: any) => {
            state.status = 'loading';
        });
        builder.addCase(setAddCustomer.fulfilled, (state: any, action: any) => {
            state.setAddCustomer = action.payload;
            state.status = 'success';
        });
        builder.addCase(setAddCustomer.rejected, (state, action) => {
            state.error = action.payload;
            state.status = 'error';
        });
         // DELETE Customer
         builder.addCase(deleteCustomer.pending, (state: any, action: any) => {
            state.status = 'loading';
        });
        builder.addCase(deleteCustomer.fulfilled, (state: any, action: any) => {
            state.deleteCustomer = action.payload;
            state.status = 'success';
        });
        builder.addCase(deleteCustomer.rejected, (state, action) => {
            state.error = action.payload;
            state.status = 'error';
        });
    },
});
// export const {
//     // customersSuccessful,
//     // customersFailed
// } = customersSlice.actions

export default customersSlice.reducer;