import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "src";
import Cookie from "js-cookie";
import { getCustomers, getInstallations, getLocations, getOrders, getReceipts, getReports, getProducts } from "../../slices/thunks";
import React from "react";
import { Container, Row, Col, Card, CardBody, Progress } from "reactstrap";



export const LoadAllData = () => {

    const dispatch = useDispatch<AppDispatch>();
    const [loading, setLoading] = useState(true);
    const [progress, setProgress] = useState(0);
    const [loadingText, setLoadingText] = useState<string[]>([]);

    useEffect(() => {
        if (Cookie.get("isLogged")) {
            const obj = JSON.parse(Cookie.get("userData"));

            const totalDispatches = 7;
            let completedDispatches = 0;

            const incrementProgress = (action: string) => {
                completedDispatches += 1;
                setProgress((completedDispatches / totalDispatches) * 100);

                setLoadingText(prev => [...prev, `${action} geladen`]);

                if (completedDispatches === totalDispatches) {
                    setLoading(false);
                }
            };

            const actions = [
                { name: "Klantgegevens", method: getCustomers, info: { email: obj.email, token: obj.token, METHOD: "GetCustomers" } },
                { name: "Locaties", method: getLocations, info: { email: obj.email, token: obj.token, METHOD: "GetLocations" } },
                { name: "Installaties", method: getInstallations, info: { email: obj.email, token: obj.token, METHOD: "GetInstallations" } },
                { name: "Bestellingen", method: getOrders, info: { email: obj.email, token: obj.token, METHOD: "GetOrders" } },
                { name: "Rapporten", method: getReports, info: { email: obj.email, token: obj.token, METHOD: "GetReports" } },
                { name: "Bonnetjes", method: getReceipts, info: { email: obj.email, token: obj.token, METHOD: "GetReceipts" } },
                { name: "Producten", method: getProducts, info: { email: obj.email, token: obj.token, METHOD: "GetProducts" } },
            ];

            actions.forEach(action => {
                setLoadingText(prev => [...prev, `${action.name} wordt geladen...`]);
                dispatch(action.method(action.info)).then(() => incrementProgress(action.name));
            });
        }
    }, [dispatch, Cookie.get("userData")]);

    if (Cookie.get("isLogged")) {
        if (loading) {
          return (
            <React.Fragment>
              <div className="page-content">
                <Container fluid>
                  <Row>
                    <Col lg={12}>
                      <Card>
                        <CardBody className="pb-0">
                          <Row>
                            <div>
                              <h2>Data aan het laden...</h2>
                              <Progress
                                animated
                                className="my-3"
                                style={{ height: '30px', borderRadius: '20px' }}
                                color="danger"
                                value={progress}
                              >
                                <strong>{Math.round(progress)}%</strong>
                              </Progress>
                              <ul>
                                {loadingText.map((text, index) => (
                                  <li key={index}>{text}</li>
                                ))}
                              </ul>
                            </div>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Container>
              </div>
            </React.Fragment>
          );
        }
      }

}
