import { createSelector } from 'reselect';
import { RootState } from '../../index'; // Adjust the import path as needed


//#### LAYOUT ######//
const selectLayout = (state: RootState) => state.Layout;

export const getLayoutValues = createSelector(
  [selectLayout],
  (layout) => ({
    layoutMode: layout.layoutMode,
    leftSideBarType: layout.leftSideBarType,
    layoutWidth: layout.layoutWidth,
    topbarTheme: layout.topbarTheme,
    layoutType: layout.layoutType,
    leftSideBarTheme: layout.leftSideBarTheme,
  })
);


//#### Customers ######//
//#region
const selectCustomers = (state: RootState) => state.customer;
const selectCustomerId = (state: RootState, customerId) => customerId;

export const getCustomers = createSelector(
  [selectCustomers],
  (customers) => customers.customerDetail
);
//#endregion

//#### Receipts ######//
//#region
const selectReceipts = (state: RootState) => state.receipts;

export const getReceipts = createSelector(
  [selectReceipts],
  (receipts) => receipts.receipts
);
//#endregion

//#### Count New Receipt by Customer ID ####
//#region
export const selectNewReceiptsForCustomer = createSelector(
  [selectReceipts, selectCustomerId],
  (receipts, customerId) => {
      const newReceipts = receipts.receipts.filter((receipt) => {
          let parsedCustomer;
          
          try {
              // Probeer de JSON te parsen
              parsedCustomer = JSON.parse(receipt.receiptCustomer);
              
              // Controleer of parsedCustomer een object is en een UUID bevat
              if (!parsedCustomer || !parsedCustomer.UUID) {
                  console.warn('Invalid or empty customer JSON:', receipt.receiptCustomer);
                  return false;
              }
              
          } catch (error) {
              // Log de fout en sla deze receipt over
              console.error('Failed to parse receiptCustomer JSON:', receipt.receiptCustomer, error);
              return false;
          }
          console.log(parsedCustomer);
          console.log(receipt);
          
          // Controleer of de UUID overeenkomt en de status "new" is
          return parsedCustomer.UUID === customerId && receipt.receiptStatus === 'new';
      });

      return newReceipts;
  }
);
//#endregion

//#### View All Receipts by Customer ID ####
//#region
export const selectTotalReceiptsForCustomer = createSelector(
  [selectReceipts, selectCustomerId],
  (receipts, customerId) => {
      const newReceipts = receipts.receipts.filter((receipt) => {
          let parsedCustomer;
          
          try {
              // Probeer de JSON te parsen
              parsedCustomer = JSON.parse(receipt.receiptCustomer);
              
              // Controleer of parsedCustomer een object is en een UUID bevat
              if (!parsedCustomer || !parsedCustomer.UUID) {
                  console.warn('Invalid or empty customer JSON:', receipt.receiptCustomer);
                  return false;
              }
              
          } catch (error) {
              // Log de fout en sla deze receipt over
              console.error('Failed to parse receiptCustomer JSON:', receipt.receiptCustomer, error);
              return false;
          }
          
          // Controleer of de UUID overeenkomt en de status "new" is
          return parsedCustomer.UUID === customerId;
      });

      return newReceipts;
  }
);
//#endregion

//#### Profile ######//
//#region
const selectProfileSuccess = (state: RootState) => state.profile.success;

export const getProfileSuccess = createSelector(
  [selectProfileSuccess],
  (profile) => profile.success
);
//#endregion