import react from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Card, CardBody, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { deleteCustomer } from "src/slices/thunks";
import Cookie from "js-cookie";
import { AppDispatch } from "src";

//Interface 
//#region
interface DeleteModalType {
    isOpen: boolean;
    toggle: () => void;
    data: any;
    from: string;
}
//#endregion




export default function DeleteModal(modaldata: DeleteModalType) {

    const obj = JSON.parse(Cookie.get("userData"));
    const dispatch = useDispatch<AppDispatch>();
    const { t } = useTranslation();
    const _m = modaldata;
    //Deleting part
    //#region
    const deletehandler = () => {

       console.log(_m.from);
       console.log(_m.data.UUID);
       

        switch (_m.from) {
            case "Customer":
                if (_m.data.UUID) {
                    const datain: {} = { email: obj.email, token: obj.token, id: _m.data.UUID, METHOD: "deleteCustomer" };
                    dispatch(deleteCustomer(datain))
                }
                break;

            default:
                return "no selection";
                break;
        }
    }
    //#endregion
    return (
        <Modal isOpen={_m.isOpen} toggle={_m.toggle} >
            <ModalHeader>{t("Delete")}</ModalHeader>
            <ModalBody>
                <Row>
                    <p>{t("DeleteMessageCustomer")}</p>
                    <Col xl={6}>
                        <b>{_m.data?.companyname}</b><br />
                        {_m.data?.streetname}<br />
                        {_m.data?.zipcode} {_m.data?.city}<br />
                        {_m.data?.country}
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <div className="d-flex flex-wrap gap-2">
                    <button className="btn btn-soft-danger" onClick={() => {deletehandler(); _m.toggle() }}>
                        <i className="uil uil-trash-alt me-2"></i>{t("Delete")}
                    </button>
                    <button className="btn btn-soft-primary" onClick={_m.toggle}>
                        <i className="uil uil-times me-2"></i>{t('Cancel')}
                    </button>
                </div>
            </ModalFooter>
        </Modal>
    )




}