import { createSlice } from "@reduxjs/toolkit";
import { getReports, getReport } from "./thunk";


interface ReportStateType {
    reports: Array<any>;
    reportDetail: object;
    status: string;
    error?: string | object | null | undefined | unknown;
    setAddreport: Array<any>;
}
export const initialState: ReportStateType = {
    reports: [],
    reportDetail: {},
    status: 'idle',
    error: {},
    setAddreport: [],
};

const reportsSlice = createSlice({
    name: "reports",
    initialState,
    reducers: {
        // customersSuccessful(state, action) {
        //     state.status    = 'Success'
        //     state.customers = action.payload
        // },
        // customersFailed(state, action) {
        //     state.error     = action.payload
        //     state.customers = action.payload
        // },
    },
    extraReducers: builder => {
        //Get Orders ALL
        builder.addCase(getReports.pending, (state: any, action: any) => {
            state.status = 'loading';
        });
        builder.addCase(getReports.fulfilled, (state: any, action: any) => {
            state.reports = action.payload;
            state.status = 'success';
        });
        builder.addCase(getReports.rejected, (state, action) => {
            state.error = action.payload || null;
            state.status = 'error';
        });
        // Get Order by ID
        builder.addCase(getReport.pending, (state: any, action: any) => {
            state.status = 'loading';
        });
        builder.addCase(getReport.fulfilled, (state: any, action: any) => {
            state.reportDetail = action.payload;
            state.status = 'success';
        });
        builder.addCase(getReport.rejected, (state, action) => {
            state.error = action.payload || null;
            state.status = 'error';
        });
    },
});
// export const {
//     // customersSuccessful,
//     // customersFailed
// } = reportsSlice.actions

export default reportsSlice.reducer;