import { createAsyncThunk } from "@reduxjs/toolkit";
// import { customersFailed, customersSuccessful } from "./reducer";

import { getCustomers as getCustomersApi,  getCustomerbyID as getCustomerApi, SetAddCustomer as setAddCustomerApi, DeleteCustomer as deleteCustomerApi } from "../../helpers/real_helper";

export const getCustomers = createAsyncThunk('customers/getCustomers', async (customer:{}, {rejectWithValue}) => {
    try {
      const res = await getCustomersApi(customer);
      //console.log(res);
      return res;
    } catch (err) {
      return rejectWithValue({ error: err.message })
    }
  });

  export const getCustomer = createAsyncThunk('customer/getCustomer', async (customer:{}, {rejectWithValue}) => {
    try {
      const res = await getCustomerApi(customer);
      return res;
    } catch (err) {
      return rejectWithValue({ error: err.message })
    }
  })

  export const deleteCustomer = createAsyncThunk('customer/deleteCustomer', async (customer:{}, {rejectWithValue}) => {
    try {
      const res = await deleteCustomerApi(customer);
      return res;
    } catch (err) {
      return rejectWithValue({ error: err.message })
    }
  })

  export const setAddCustomer = createAsyncThunk('customer/setAddCustomer', async (customer:{}, {rejectWithValue}) => {
    try {
      const response:any = await setAddCustomerApi(customer);
      console.log(response);
      const dataout:any =  JSON.parse(response)
      // console.log(response);
      if (dataout.status !== 'error') {
        return dataout.responseServer;
      }else{
        return rejectWithValue(JSON.stringify(dataout.responseServer))
      }
      
      //return response.data.responseServer
      //console.log(res);
      //return res;
    } catch (err) {
      return rejectWithValue(err)
    }
  })



