import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Col, Container, Row } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import CustomTable from "src/components/CustomTable";
import { RootState } from "src";
import useMetaTag from "src/components/Common/useMetaTag";
import { Loader } from "rsuite";

const Locations = () => {
    const { t } = useTranslation();
    const locations = useSelector((state: RootState) => state.locations);
    const isLoading = locations.locations.length === 0; 
    const tableDataColumns = [
        { label: "Id", dataName: "uuid", props: { fixed: true, align: "center" } },
        { label: t('Active'), dataName: "Active", props: { fixed: false, flexGrow: 0, resizable: true, sortable: true, fullText: false } },
        { label: t('Location'), dataName: "locationname", props: { fixed: false, flexGrow: 1, resizable: false, sortable: true, fullText: false, style: { fontWeight: "700" } } },
        { label: t('Streetname'), dataName: "locationadress", props: { fixed: false, flexGrow: 1, resizable: true, sortable: true, fullText: false } },
        { label: t('Zipcode'), dataName: "locationzipcode", props: { fixed: false, flexGrow: 1, resizable: true, sortable: true, fullText: true } },
        { label: t('City'), dataName: "locationcity", props: { fixed: false, flexGrow: 1, resizable: true, sortable: true, fullText: true } },
        { label: t('Country'), dataName: "locationcountry", props: { fixed: false, flexGrow: 1, resizable: true, sortable: true, fullText: true } },

        // { label: t('City'), dataName: "city", props: { fixed: false, flexGrow: 1, resizable: true, sortable: true, fullText: false } },
    ]

    //#endregion
    const handleClick = (e) => {
        console.log("Gegevenes => " + e);
    }

    useMetaTag('Locaties | AWEKA Systeem', 'Locaties AWEKA');

    if (isLoading) {
        return <Loader content="Loading..." />;
    }
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Algemeen" breadcrumbItem={[{ active: false, urlLink: "#", label: t('Locations') }, { active: true, urlLink: "#", label: t('View') }]} />
                    <Row>
                        <Col xl={12}>
                            {locations.locations &&
                                <CustomTable
                                    PageSize={15}
                                    dataRows={locations.locations}
                                    onClickEvent={handleClick}
                                    columns={tableDataColumns}
                                    keyRow={"uuid"}
                                    isEdit={false}
                                    isModal={{ enable: false, modal: "Customers" }}
                                />
                            }
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}

export default Locations;