import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AutoComplete, Col, Container, Row } from "rsuite"
import { getOrders, getReports, getReceipts, getUser, preAddNewOrder, getLastOrderNumber, getCustomers, getLocations } from "src/slices/thunks";
import Cookie from "js-cookie";
import dataloaded from "src/components/Common/DataLoaded";
import React from "react";
import MetaTags from "react-meta-tags";
import { RootState, AppDispatch } from "src";




const OrderAdd = () => {

    // Get translation function
    const { t } = useTranslation();

    // Redux state and dispatch
    const dispatch = useDispatch<AppDispatch>();
    const orders = useSelector((state: RootState) => state.orders);
    const reports = useSelector((state: RootState) => state.reports);
    const receipts = useSelector((state: RootState) => state.receipts);
    const user = useSelector((state: RootState) => state.users);
    const customer = useSelector((state: RootState) => state.customer);
    const location = useSelector((state: RootState) => state.locations);



    //Redux status feedback 
    const ordersStatus = useSelector((state: RootState) => state.orders.status);
    const reportsStatus = useSelector((state: RootState) => state.reports.status);
    const receiptsStatus = useSelector((state: RootState) => state.receipts.status);


    //Redux Error Feedback
    const ordersError = useSelector((state: RootState) => state.orders.error);
    const reportsError = useSelector((state: RootState) => state.reports.error);
    const receiptsError = useSelector((state: RootState) => state.receipts.error);



    // Loading state
    const [isLoading, setIsLoading] = useState(true);

    // User data
    const obj = JSON.parse(Cookie.get("userData"));


        // Data Loading
        useEffect(() => {
            // Fetch orders, reports, receipts, customers, and locations
            const datain = { email: obj.email, token: obj.token, METHOD: "GetOrders" };
            dispatch(getOrders(datain));
    
            const datainReports = { email: obj.email, token: obj.token, METHOD: "GetReports" };
            dispatch(getReports(datainReports));
    
            const datainReceipts = { email: obj.email, token: obj.token, METHOD: "GetReceipts" };
            dispatch(getReceipts(datainReceipts));
    
            const datainUser = { email: obj.email, token: obj.token, id: obj.id, METHOD: "GetUserbyId" };
            dispatch(getUser(datainUser));
    
            const datainCustomers = { email: obj.email, token: obj.token, METHOD: "GetCustomers" };
            dispatch(getCustomers(datainCustomers))
    
            const datainLocations = { email: obj.email, token: obj.token, METHOD: "GetLocations" };
            dispatch(getLocations(datainLocations))
    
        }, [dispatch, isLoading, obj.email, obj.token, obj.id, orders.setAddorder]);



        const [valueSearchCustomer, setValueSearchCustomer] = React.useState('');
        const [valueSelectedCustomer, setValueSelectedCustomer] = React.useState('');
        const [valueSearchLocation, setValueSearchLocation] = React.useState('');
        const [valueSelectedLocation, setValueSelectedLocation] = React.useState(null);


        const selectedCustomer = customer.customers.find((el) => el.UUID === valueSearchCustomer);
        if (selectedCustomer && !valueSelectedCustomer) {
            //console.log(laced);
            setValueSelectedCustomer(selectedCustomer);
            //   return;
        }

        const selectedLocation = location.locations.find((el) => el.locationname === valueSearchLocation);
        if (selectedLocation && !valueSelectedLocation) {
            //console.log(laced);
            setValueSelectedLocation(selectedLocation);
            //   return;
        }
        const isMatch = (uuids, selectedUUID) => {
            if (Array.isArray(uuids)) {
                console.log("is array => " + uuids);

                return uuids.includes(selectedUUID);
            } else {
                return uuids === selectedUUID || uuids === String(selectedUUID);
            }
        };

        const filteredLocations = location.locations
            .filter((loc) =>
                isMatch(loc.locationcustomerUUID, selectedCustomer?.UUID))
            .map((loc) => loc.locationname);

        console.log(filteredLocations);


    return(
        <React.Fragment>

            <div className="page-content">
                <MetaTags>
                    <title>{t("Orders")} | AWEKA Energy Solutions</title>
                </MetaTags>

        <Container>
              <Row>
                        <Col>
                            {t("Customer")}
                            {/* {console.log(customer.customers.map(name => name.companyname))} */}
                            <AutoComplete data={customer.customers.map((name, index) => ({
                                value: name.UUID,
                                label: name.companyname,
                                item: name,
                                key: index, // Ensure a unique key for each item
                            }))} onSelect={setValueSearchCustomer} onChange={() => setValueSelectedCustomer('')} style={{ width: 224 }} />
                            {valueSearchCustomer &&

                                customer.customers.map((values, index) => {
                                    if (values.UUID === valueSearchCustomer) {

                                        return (
                                            <>
                                                <div key={index} style={{ listStyle: 'none', marginTop: "5px" }}>
                                                    Geselecteerde relatie
                                                    <li><strong>{values.companyname}</strong></li>
                                                    <li>{values.streetname}</li>
                                                    <li>{values.zipcode}, {values.city}</li>
                                                    <li>{values.country}</li>

                                                </div>
                                            </>
                                        )
                                    }

                                })



                            }
                        </Col>

                        <Col>
                            {t("Location")}
                            <AutoComplete data={filteredLocations} onSelect={setValueSearchLocation} onChange={() => setValueSelectedLocation('')} style={{ width: 224 }} />
                            {valueSearchLocation &&

                                location.locations.map((values, index) => {
                                    if (values.locationname === valueSearchLocation) {

                                        return (
                                            <>
                                                <div style={{ listStyle: 'none', marginTop: "5px" }}>
                                                    Geselecteerde locatie
                                                    <li><strong>{values.locationname}</strong></li>
                                                    <li>{values.locationadress}</li>
                                                    <li>{values.locationzipcode}, {values.locationcity}</li>
                                                    <li>{values.locationcountry}</li>

                                                </div>
                                            </>

                                        )
                                    }

                                })



                            }
                        </Col>
                        {/* <CustomTable
                            keyRow={"UUID"}
                            PageSize={10}
                            dataRows={customer.customers}
                            onClickEvent={(e) => handleClick(e)}
                            columns={tableDataColumns}
                            isEdit={false}
                            isModal={{ enable: false, modal: "Customers" }}

                        /> */}
                    </Row>
        </Container>
        </div>
        </React.Fragment>
    )



}


export default OrderAdd