import { createSlice } from "@reduxjs/toolkit";
import { getNotify, getNotifybyID, setNotifybyID} from "./thunk";


interface NotifyStateType {
    Notifys: Array<any>;
    NotifyDetail: object;
    status: string;
    error?: string | object | null | undefined | unknown;
}
export const initialState: NotifyStateType = {
    Notifys: [],
    NotifyDetail: {},
    status: 'idle',
    error: {},
};

const locationsSlice = createSlice({
    name: "notify",
    initialState,
    reducers: {
        // customersSuccessful(state, action) {
        //     state.status    = 'Success'
        //     state.customers = action.payload
        // },
        // customersFailed(state, action) {
        //     state.error     = action.payload
        //     state.customers = action.payload
        // },
    },
    extraReducers: builder => {
        builder.addCase(getNotify.pending, (state: any, action: any) => {
            state.status = 'loading';
        });
        builder.addCase(getNotify.fulfilled, (state: any, action: any) => {
            state.Notifys = action.payload
            state.status = 'success';         
        });
        builder.addCase(getNotify.rejected, (state, action) => {
            state.error = action.payload || null;
            state.status = 'error';
        });
        // Get location by ID
        builder.addCase(getNotifybyID.pending, (state: any, action: any) => {
            state.status = 'loading';
        });
        builder.addCase(getNotifybyID.fulfilled, (state: any, action: any) => {
            state.NotifyDetail = action.payload;  
            state.status = 'success';
        });
        builder.addCase(getNotifybyID.rejected, (state, action) => {
            state.error = action.payload || null;
            state.status = 'error';
        });
        // Get location by customer ID
        builder.addCase(setNotifybyID.pending, (state: any, action: any) => {
            state.status = 'loading';
        });
        builder.addCase(setNotifybyID.fulfilled, (state: any, action: any) => {
            state.setnotify = action.payload;       
            state.status = 'success';
        });
        builder.addCase(setNotifybyID.rejected, (state, action) => {
            state.error = action.payload || null;
            state.status = 'error';
        });
    },
});
// export const {
//     // customersSuccessful,
//     // customersFailed
// } =  locationsSlice.actions

export default  locationsSlice.reducer;