import { createSlice } from "@reduxjs/toolkit";
import { getOrders, getOrder, getLastOrderNumber, preAddNewOrder } from "./thunk";


interface OrderStateType {
    orders: Array<any>;
    orderDetail: object;
    orderLastOrderNumber: object;
    status: string;
    error?: string | object | null | undefined | unknown;
    setAddorder: Array<any>;
}
export const initialState: OrderStateType = {
    orders: [],
    orderDetail: {},
    orderLastOrderNumber: {},
    status: 'idle',
    error: {},
    setAddorder: [],
};

const ordersSlice = createSlice({
    name: "orders",
    initialState,
    reducers: {
        // customersSuccessful(state, action) {
        //     state.status    = 'Success'
        //     state.customers = action.payload
        // },
        // customersFailed(state, action) {
        //     state.error     = action.payload
        //     state.customers = action.payload
        // },
    },
    extraReducers: builder => {
        //Get Orders ALL
        builder.addCase(getOrders.pending, (state: any, action: any) => {
            state.status = 'loading';
        });
        builder.addCase(getOrders.fulfilled, (state: any, action: any) => {
            state.orders = action.payload;
            state.status = 'success';
        });
        builder.addCase(getOrders.rejected, (state, action) => {
            state.error = action.payload || null;
            state.status = 'error';
        });
        // Get Order by ID
        builder.addCase(getOrder.pending, (state: any, action: any) => {
            state.status = 'loading';
        });
        builder.addCase(getOrder.fulfilled, (state: any, action: any) => {
            state.orderDetail = action.payload;
            state.status = 'success';
        });
        builder.addCase(getOrder.rejected, (state, action) => {
            state.error = action.payload || null;
            state.status = 'error';
        });
         //Get Last Order Number
         builder.addCase(getLastOrderNumber.pending, (state: any, action: any) => {
            state.status = 'loading';
        });
        builder.addCase(getLastOrderNumber.fulfilled, (state: any, action: any) => {
            state.orderLastOrderNumber = action.payload;
            state.status = 'success';
        });
        builder.addCase(getLastOrderNumber.rejected, (state, action) => {
            state.error = action.payload || null;
            state.status = 'error';
        });
         //Get Last Order Number
         builder.addCase(preAddNewOrder.pending, (state: any, action: any) => {
            state.status = 'loading';
        });
        builder.addCase(preAddNewOrder.fulfilled, (state: any, action: any) => {
            state.setAddorder = action.payload;
            state.status = 'success';
        });
        builder.addCase(preAddNewOrder.rejected, (state, action) => {
            state.error = action.payload || null;
            state.status = 'error';
        });
    },
});
// export const {
//     // customersSuccessful,
//     // customersFailed
// } = ordersSlice.actions

export default ordersSlice.reducer;