import { createAsyncThunk } from "@reduxjs/toolkit";
// import { customersFailed, customersSuccessful } from "./reducer";

import { getInstallations as getInstallationsApi,  getInstallationbyID as getInstallationApi } from "../../helpers/real_helper";

export const getInstallations = createAsyncThunk('installations/getInstallations', async (installation:{}, {rejectWithValue}) => {
    try {
      const res = await getInstallationsApi(installation);
      //console.log(res);
      return res;
    } catch (err) {
      return rejectWithValue({ error: err.message })
    }
  });

  export const getInstallation = createAsyncThunk('installations/getInstallation', async (installation:{}, {rejectWithValue}) => {
    try {
      const res = await getInstallationApi(installation);
      return res;
    } catch (err) {
      return rejectWithValue({ error: err.message })
    }
  })




