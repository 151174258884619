import { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "src";
import io from 'socket.io-client';
import Cookie from "js-cookie";
import { toast } from "react-toastify";

export const PhonePlugin = () => {

    const socket = io('http://awsd1234.i234.me:3001', {
        withCredentials: true, // optioneel, afhankelijk van je setup
        reconnectionAttempts: 3, // Number of times to attempt reconnection before giving up
        // reconnectionDelay: 1000,  // Delay in milliseconds between reconnection attempts
    });


    const playRingtone = () => {
        const audio = new Audio('https://nl.ringsignaler.net/audio/twitter-notification-sound-ringtone-923.mp3'); // Plaats hier het pad naar je ringtone bestand
        audio.play();
    };

    const customers = useSelector((state: RootState) => state.customer.customers);

    socket.on('connect', () => {
        console.log('Connected to server');
    });


    useEffect(() => {

        // Event handler voor inkomende oproep data
        const handleCustomerCallerData = (phoneNumberIn) => {
            console.log('Customers from state:', customers);
            console.log('PhoneIn:', phoneNumberIn);

            const regex = /^.*(\d{9})$/;
            // Check of het telefoonnummer en de klantenlijst beschikbaar zijn
            if (phoneNumberIn && customers) {
                // Zoek de klant op basis van het telefoonnummer
                const customerCall = customers.find(customer => {
                    return typeof customer.phonenumber === 'string' &&
                        customer.phonenumber.trim().match(regex)?.[1] === phoneNumberIn.trim().match(regex)?.[1];
                });
                console.log('Customer found:', customerCall);

                // Toon een toast melding afhankelijk van of de klant gevonden is of niet
                if (customerCall) {
                    toast.info(
                        <div>
                            Er is een inkomende buitenlijn van <strong>{customerCall.companyname}</strong> | {customerCall.phonenumber}
                            <br />
                            <a style={{ color: "white" }} target="_blank" rel="noopener noreferrer" href={`/customer-profile/${customerCall.UUID}`}><button type="button" className="btn btn-soft-info"><i className="uil uil-info-circle me-2"></i> Ga naar klant gegevens</button></a>.
                            <a style={{ color: "white" }} target="_blank" rel="noopener noreferrer" href={`/customer-profile/${customerCall.UUID}`}><button type="button" className="btn btn-soft-primary"><i className="uil uil-info-circle me-2"></i> Open notities</button></a>.
                        </div>, {
                        closeOnClick: false,
                        theme: "colored",
                        icon: ({ theme, type }) => <i className="uil uil-phone-alt"></i>,
                        position: 'top-center',
                        autoClose: 120000
                    }
                    );
                } else {
                    toast.error(
                        <div>
                            Er is een inkomende buitenlijn van <strong>onbekende beller</strong> | {phoneNumberIn}
                            <br />
                            <a style={{ color: "white" }} href={`/Customer-Add/`}><button type="button" className="btn btn-soft-info"><i className="uil uil-info-circle me-2"></i> Maak nieuwe klant aan </button></a>.
                        </div>, {
                        closeOnClick: false,
                        theme: "colored",
                        icon: ({ theme, type }) => <i className="uil uil-phone-alt"></i>,
                        position: 'top-center',
                        autoClose: 120000
                    }
                    );
                }

                // Speel de ringtone af
                playRingtone();

            } else {
                console.log('Phone number or customers list is undefined');
            }
        }
        // Luister naar het 'customerCallerData' event als gebruiker ingelogd is
        Cookie.get("isLogged") &&
            socket.on('customerCallerData', handleCustomerCallerData);

        // Cleanup functie om de event listener te verwijderen wanneer de component unmount
        return () => {
            socket.off('customerCallerData', handleCustomerCallerData);
        };
    }, [customers]); // Voeg customers toe aan de afhankelijkheden array
}

const ModalNotes = () => {



// Moet nog gemaakt wordend dat er aantekeningen gemaakt kunnen worden !



}