import { createSlice } from "@reduxjs/toolkit";

import { UpdateCustomer, UpdateSettings } from "./thunk";

interface InputEditStateType {
  Fieldname: Array<any>,
  Value: object,
  status: string,
  error?: string | object | null | undefined | unknown,
}
export const initialState: InputEditStateType = {
  Fieldname: [],
  Value: {},
  status: 'idle',
  error: ''
};

const InputEditSlice = createSlice({
  name: "InputEdit",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(UpdateCustomer.pending, (state : any, action : any) => {
      state.status = 'loading';
    })
    builder.addCase(UpdateCustomer.fulfilled, (state : any, action : any) => {
      state.InputEdit = action.payload;
      state.status = 'success';
    })
    builder.addCase(UpdateCustomer.rejected, (state, action) => {
      state.error = action.payload || null;
      state.status = 'error';
    })
    builder.addCase(UpdateSettings.pending, (state : any, action : any) => {
      state.status = 'loading';
    })
    builder.addCase(UpdateSettings.fulfilled, (state : any, action : any) => {
      state.InputEdit = action.payload;
      state.status = 'success';
    })
    builder.addCase(UpdateSettings.rejected, (state, action) => {
      state.error = action.payload || null;
      state.status = 'error';
    })
  },
});

export default InputEditSlice.reducer;
