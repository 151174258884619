import { Navigate } from "react-router-dom";

//Sales
import Sales from "../pages/Sales";

//profile
import UserProfile from "../pages/Authentication/user-profile";

//Settings
import MainSettings from "src/pages/Settings/Settings";

//Tools
import Tools from "src/components/Layout/LayoutTable";
import ToolsDemo from "src/components/Layout/LayoutDemo";

//Locations
import Locations from "src/pages/Location/Locations";
import LocationAdd from "src/pages/Location/LocationAdd/index";

//Calendar 
import Calender from "src/pages/Calendar";
import Planner from "src/pages/Calendar/planner";

//Installations
import Installations from "src/pages/Installations/Installations";

//Orders 
import Orders from "src/pages/Orders/OrdersDashboard";
import OrderAdd from "src/pages/Orders/OrderAdd/order-Add";

//Receipts 
import AddReceipts from "src/pages/Receipts/ReceiptAdd/AddReceipt";
import ViewReceipts from "src/pages/Receipts/ViewReceipt/ViewReceipt";

//Products 
import Products from "src/pages/Products/Products";
import AddProducts from "src/pages/Products/AddProduct";


//Customers
import CustomerProfile from "../pages/Customers/Customer-Profile";
import Customers from "../pages/Customers/Customers";
import CustomerAdd from "../pages/Customers/CustomerAdd/index";

//Invoice 
import InvoiceDetail from "src/pages/Invoice/invoice-details";

//Authentication pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword"
import IframeHoorayHR from "src/pages/Weekstaten/iframe";
import NotFound from "src/pages/NotFound";
import LayoutBuilder from "src/components/Layout/LayoutBuilder";

interface RouteProps {
  path: string;
  component: any;
  exact?: boolean;
  permission?: string;
}

const userRoutes: Array<RouteProps> = [
  //dashboard
  { path: "/sales", component: <Sales/> },

  //CustomerPages
  { path: "/customer-profile/:id", component: <CustomerProfile/> },
  { path: "/Customers/:kindRelation", component: <Customers/> },
  { path: "/Customers", component: <Customers/> },
  { path: "/Customer-Add", component: <CustomerAdd/> },

  //LocationsPages
  { path: "/locations", component: <Locations/> },
  { path: "/location-Add", component: <LocationAdd/> },

  //InstallationsPages
  { path: "/installations", component: <Installations/> },

  //SettingsPages
  { path: "/settings", component: <MainSettings/>, permission: "admin" },

  //Calendar pages
  { path: "/calendar", component: <Calender/> },
  { path: "/planner", component: <Planner/> },

  //Invoice 
  { path: "/invoice", component: <InvoiceDetail/> },

  //Orders
  { path: "/orders", component: <Orders/> },
  { path: "/order-Add", component: <OrderAdd/>},

  //Receipts
  { path: "/receipt-add", component: <AddReceipts/> },
  { path: "/receipt-view/:id", component: <ViewReceipts/> },

  //Products
  { path: "/products-add", component: <AddProducts/> },
  { path: "/products", component: <Products/> },

  //Weekstaten pages
  { path: "/WeeklyStatement", component: <IframeHoorayHR/> },

  //Tools 
  { path: "/tools", component: <Tools/>, permission: "admin" },
  { path: "/toolsdemo", component: <ToolsDemo/> },
  { path: "/toolsbuilder", component: <LayoutBuilder/> },


  // //profile
  { path: "/profile", component: <UserProfile/> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: <Sales /> },
  { path: "*", exact: true, component: <NotFound /> }

];

const authRoutes: Array<RouteProps> = [
  //Authentication pages
  { path: "/login", component: <Login/> },
  { path: "/logout", component: <Logout/> },
  { path: "/register", component: <Register/> },
  { path: "/forgot-password", component: <ForgetPwd/> },
];

export { userRoutes, authRoutes };
