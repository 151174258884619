import { useEffect } from "react";
import { toast } from "react-toastify";

interface DataLoadedProps {
    id: number;
    status: string;
    error?: string;
    successful: string;
    time?: number;
    notify?: boolean;
    notifyData?: any;
}

export default async function dataloaded({id, status, error, successful, time, notify, notifyData}: DataLoadedProps) {
    const delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
      );
       // console.log(status);
        if (status === 'loading') {
            toast.info("Laden", {
                toastId: id,
                isLoading: false,
                autoClose: time,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
    
        }
        if (status === 'error') {
           //console.log(error.toString);
            toast.update(id, {
                render: error.toString(),
                type: "error",
                isLoading: false,
                autoClose: time,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
        if (status === 'success') {
            toast.update(id, {
                render: successful,
                type: "success",
                isLoading: false,
                autoClose: time,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            }); 
        }
        if (notify === true) {

            await delay(3000);
            // console.log(notifyData);
            


        }
}
