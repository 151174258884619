import axios from "axios";
import React, { useState } from "react";
import * as url from "./url_helper";
import { ApiCore } from "./api_helper";
import Cookies from "js-cookie";

const api = new ApiCore();

const getLoggedInUser = () => {
  const user = localStorage.getItem("user");
  if (user) return JSON.parse(user);
  return null;
};

const FormDataPost = {
  headers: {
    'Content-Type': 'multipart/form-data'
  }
}


//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

// Creating the real login 
//#region
const postRealLogin = (data: any) => {
  return api.create(url.POST_REAL_LOGIN, data, FormDataPost);
};
//#endregion

//Get Customers
//#region
const getCustomers = (data: any) => {
  return api.create(url.GET_CUSTOMERS, data, FormDataPost);
};
const getCustomerbyID = (data: any) => {
  return api.create(url.GET_CUSTOMERS, data, FormDataPost);
};
//#endregion

//Set Customer
//#region
const SetAddCustomer = (data: any) => {
  return api.create(url.SET_CUSTOMER, data, FormDataPost);
};
//#endregion

//Delete Customer
//#region
const DeleteCustomer = (data: any) => {
  return api.create(url.DELETE_CUSTOMER, data, FormDataPost);
};
//#endregion

//Update Customer
//#region
const UpdateCustomer = (data: any) => {
  return api.create(url.UPDATE_CUSTOMER, data, FormDataPost);
};
//#endregion

//Get Locations
//#region
const getLocations = (data: any) => {
  return api.create(url.GET_LOCATIONS, data, FormDataPost);
};
const getLocationbyID = (data: any) => {
  return api.create(url.GET_LOCATIONS, data, FormDataPost);
};
const getLocationbyCustomerID = (data: any) => {
  return api.create(url.GET_LOCATIONS, data, FormDataPost);
};
//#endregion

//Get/Set Tools / Layouts
//#region 
const getLayouts = (data: any) => {
  return api.create(url.GET_LAYOUTS, data, FormDataPost);
}
const getLayoutsbyID = (data: any) => {
  return api.create(url.GET_LAYOUTS, data, FormDataPost);
}
const SetLayoutbyID = (data: any) => {
  return api.create(url.GET_LAYOUTS, data, FormDataPost);
}
//#endregion

//SetNotify
//#region
const getNotifys = (data: any) => {
  return api.create(url.GET_NOTIFY, data, FormDataPost);
}
const getNotifysbyID = (data: any) => {
  return api.create(url.GET_NOTIFY, data, FormDataPost);
}
const setNotifybyID = (data: any) => {
  return api.create(url.SET_NOTIFY, data, FormDataPost);
}
const UpdateNotifyReaded = (data: any) => {
  return api.create(url.SET_NOTIFY, data, FormDataPost);
}
//#endregion

//Calendar events
//#region

// get Events
const getEvents = (data:any) => {
  return api.create(url.GET_EVENTS, data, FormDataPost);
};

// add Events
const addNewEvent = (data: any) => {
  return api.create(url.ADD_NEW_EVENT, data, FormDataPost);
};

// update Event
const updateEvent = (data: any) => {
  return api.create(url.UPDATE_EVENT, data, FormDataPost);
};

// delete Event
const deleteEvent = (data: any) => {
  return api.create(url.DELETE_EVENT, data, FormDataPost);
};

// get Categories
const getCategories = (data: any) => {
  return api.create(url.GET_CATEGORIES, data, FormDataPost);
};
//#endregion

//Get Settings
//#region 
const getSettings = (data: any) => {
  return api.create(url.GET_SETTINGS, data, FormDataPost);
}
//#endregion
//Update Settings
//#region 
const updateSettings = (data: any) => {
  return api.create(url.UPDATE_SETTINGS, data, FormDataPost);
}
//#endregion

//Get Users
//#region
const getUsers = (data: any) => {
  return api.create(url.GET_USERS, data, FormDataPost);
};
const getUser = (data: any) => {
  return api.create(url.GET_USERS, data, FormDataPost);
};
const getAuth = (data: any) => {
  return api.create(url.GET_USERS, data, FormDataPost);
};
//#endregion

//Set Users
//#region
const setUser = (data: any) => {
  return api.create(url.SET_USER, data, FormDataPost);
};
//#endregion

//Get Orders
//#region
const getOrders = (data: any) => {
  return api.create(url.GET_ORDERS, data, FormDataPost);
};
const getOrderbyID = (data: any) => {
  return api.create(url.GET_ORDERS, data, FormDataPost);
};
const getLastOrderNumber = (data: any) => {
  return api.create(url.GET_ORDERS, data, FormDataPost);
};
//#endregion

//Add New Order
//#region
const addNewOrder = (data: any) => {
  return api.create(url.ADD_ORDER, data, FormDataPost);
};
const preAddNewOrder = (data: any) => {
  return api.create(url.ADD_ORDER, data, FormDataPost);
};
//#endregion

//Get Reports
//#region
const getReports = (data: any) => {
  return api.create(url.GET_REPORTS, data, FormDataPost);
};
const getReportbyID = (data: any) => {
  return api.create(url.GET_REPORTS, data, FormDataPost);
};
//#endregion

//Update Receipts Items
//#region
// const updateReceipts = (data: any) => {
//   return api.create(url.GET_RECEIPTS, data, FormDataPost);
// };
const updateReceiptItembyID = (data: any) => {
  return api.create(url.UPDATE_RECEIPTS, data, FormDataPost);
};
//#endregion

//New Receipt
//#region
const newReceipt = (data: any) => {
  return api.create(url.NEW_RECEIPT, data, FormDataPost);
};
//#endregion


//Get Receipts
//#region
const getReceipts = (data: any) => {
  return api.create(url.GET_RECEIPTS, data, FormDataPost);
};
const getReceiptbyID = (data: any) => {
  return api.create(url.GET_RECEIPTS, data, FormDataPost);
};
//#endregion

//Get Installations
//#region
const getInstallations = (data: any) => {
  return api.create(url.GET_INSTALLATIONS, data, FormDataPost);
};
const getInstallationbyID = (data: any) => {
  return api.create(url.GET_INSTALLATIONS, data, FormDataPost);
};
//#endregion

//Get Installations
//#region
const getProducts = (data: any) => {
  return api.create(url.GET_PRODUCTS, data, FormDataPost);
};
const getProductbyID = (data: any) => {
  return api.create(url.GET_PRODUCTS, data, FormDataPost);
};
//#endregion


export {
  //CUSTOMERS
  getCustomers,
  getCustomerbyID,
  SetAddCustomer,
  DeleteCustomer,
  UpdateCustomer,
  //LOCATIONS
  getLocations,
  getLocationbyID,
  getLocationbyCustomerID,
  //LAYOUTS
  getLayouts,
  getLayoutsbyID,
  SetLayoutbyID,
  //NOTIFY
  getNotifys,
  getNotifysbyID,
  setNotifybyID,
  UpdateNotifyReaded,
  //CALENDAR
  getEvents,
  addNewEvent,
  updateEvent,
  deleteEvent,
  getCategories,
  //LOGIN
  getLoggedInUser,
  isUserAuthenticated,
  postRealLogin,
  //SETTINGS
  getSettings,
  updateSettings,
  //USERS
  getUser,
  getUsers,
  setUser,
  getAuth,
  //ORDERS
  getOrders,
  getOrderbyID,
  getLastOrderNumber,
  addNewOrder,
  preAddNewOrder,
  //REPORTS
  getReports,
  getReportbyID,
  //RECEIPTS
  getReceipts,
  getReceiptbyID,
  updateReceiptItembyID,
  newReceipt,
  //INSTALLATIONS
  getInstallations,
  getInstallationbyID,
  //PRODUCTS
  getProducts,
  getProductbyID
};