import { useEffect, useReducer, useState } from "react";
import { Card, Col, Form, InputGroup, Label } from "reactstrap";
import Select from "react-select";
import "./tooltip.css";
import Cookie from "js-cookie"
import { UpdateCustomer, UpdateSettings } from "src/slices/InputEdit_Component/thunk";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { UpdateUser, setNotifybyID } from "src/slices/thunks";
import { convertToHTML } from 'draft-convert';
import { EditorState } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { random } from "lodash";
import { DATABASE_TABLES } from "src/constants/DatabaseTables";
import { useController, useForm } from "react-hook-form";
import img1 from '../../assets/images/logo-dark.png'
import { AppDispatch } from "src";

interface InputProps {
    isHeaderText?: "img" | "h6" | "h5" | "h4" | "h3" | "h2" | "h1",
    isMultiLine?: "img" | "single" | "multi" | "advanced" | "selection",
    SelectionOptions?: any,
    onChange?: void,
    onBlur?: void,
    OnDoubleClick?: void,
    Value: any,
    DatabaseFieldName: string,
    DatabaseTableName: DATABASE_TABLES["default"]
    DatabaseUUID: number,
    className?: string,
    Updated?: void,
    SuccessEdit?: void,
    numberOfLines?: any,
    style?: any
}

const InputEdit = (props: InputProps) => {

    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const { register, handleSubmit, formState: { errors }, getValues, setValue, control } = useForm();
    const obj = JSON.parse(Cookie.get("userData"));
    const [FieldValue, setFieldValue] = useState();
    const [IsEdit, setIsEdit] = useState(false);
    const [IsDirty, setIsDirty] = useState(false);
    const [OldValue, setOldValue] = useState();
    const [editorState, setEditorState] = useState(
        () => EditorState.createEmpty(),
    );
    const [ignored, forceUpdate] = useReducer(x => x + 1, 0);
    

    const { field: { value: langValue, onChange: langOnChange, ...restLangField } } = useController({ name: 'country', control, rules: { required: true } });

    const delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
    );


    const [convertedContent, setConvertedContent] = useState(null);
    useEffect(() => {
        let html = convertToHTML(editorState.getCurrentContent());
        setConvertedContent(html);
        setIsDirty(true)
    }, [editorState]);

    const handleBlur = async (e: any, editor?: boolean) => {

        if (IsDirty === true && props.DatabaseFieldName.length > 0) {
            console.log(e.target.id);

            switch (props.DatabaseTableName) {
                case "export_clients_aweka":
                    var datain: {} = { email: obj.email, token: obj.token, dataUpload: JSON.stringify({ databaseFieldName: props.DatabaseFieldName, databaseFieldValue: editor ? convertedContent : FieldValue, id: props.DatabaseUUID }), METHOD: "updateCustomer" }
                    var returning = dispatch(UpdateCustomer(datain))
                    break;
                case "g_Settings":
                    var datain: {} = { email: obj.email, token: obj.token, dataUpload: JSON.stringify({ databaseFieldName: props.DatabaseFieldName, databaseFieldValue: editor ? convertedContent : FieldValue, id: props.DatabaseUUID }), METHOD: "updateSettings" }
                    var returning = dispatch(UpdateSettings(datain))
                    break;
                case "g_Users":
                    var datain: {} = { email: obj.email, token: obj.token, dataUpload: JSON.stringify({ databaseFieldName: props.DatabaseFieldName, databaseFieldValue: editor ? convertedContent : FieldValue, id: props.DatabaseUUID }), METHOD: "UpdateUser" }
                    var returning = dispatch(UpdateUser(datain))
                    break;


                default:
                    // setDatain({ email: obj.email, token: obj.token, dataUpload: JSON.stringify({ databaseFieldName: props.DatabaseFieldName, databaseFieldValue: editor ? convertedContent : FieldValue, id: props.DatabaseUUID }), METHOD: "updateCustomer" });
                    // setReturning(dispatch(UpdateCustomer(datain)))
                    break;
            }
            if (returning) {
                const dataNotify: {} = {
                    email: obj.email, token: obj.token, dataN: JSON.stringify({
                        action: t('changedMessageAdding', { mail: obj.email, company: props.DatabaseFieldName }),
                        user: obj.email,
                        readed: 0,
                        rawdata: JSON.stringify(datain),
                        status: "Success"
                    }), METHOD: "setNotify"
                }
                dispatch(setNotifybyID(dataNotify));
            }
            // return props.SuccessEdit;
           window.location.reload();
            // await delay(1000);
            // forceUpdate();

        }
        setIsEdit(!IsEdit)
    }

    const handleonChange = (e: any, editor: string) => {
        if (editor === "editor") {
            //console.log("editor");
            setFieldValue(convertedContent);
        } 
        if (editor === "img") {
            const outputimg = URL.createObjectURL(e.target.files[0]);
            console.log(outputimg);
            
            // setFieldValue(outputimg)
            
        }
        else {
            //console.log("Single or multi");
            setFieldValue(e.target.value);
        }
        //console.log(e);
        setIsDirty(true);
    }

    const SwitchHeaderText = () => {

        switch (props.isHeaderText) {
            case "img":
                return (<img style={props.style} onDoubleClick={() => { setIsEdit(!IsEdit) }} src={props.Value ? props.Value : img1} alt="blog img" className="avatar-lg h-auto rounded" />)
            case 'h6':
                return (<h6 style={props.style} className={props.className} id={props.DatabaseFieldName} onDoubleClick={() => { setIsEdit(!IsEdit) }}>{props.Value ? props.Value : t('Edit')}</h6>)
            case 'h5':
                return (<h5 style={props.style} className={props.className} id={props.DatabaseFieldName} onDoubleClick={() => { setIsEdit(!IsEdit) }}>{props.Value ? props.Value : t('Edit')}</h5>)
            case 'h4':
                return (<h4 style={props.style} className={props.className} id={props.DatabaseFieldName} onDoubleClick={() => { setIsEdit(!IsEdit) }}>{props.Value ? props.Value : t('Edit')}</h4>)
            case 'h3':
                return (<h3 style={props.style} className={props.className} id={props.DatabaseFieldName} onDoubleClick={() => { setIsEdit(!IsEdit) }}>{props.Value ? props.Value : t('Edit')}</h3>)
            case 'h2':
                return (<h2 style={props.style} className={props.className} id={props.DatabaseFieldName} onDoubleClick={() => { setIsEdit(!IsEdit) }}>{props.Value ? props.Value : t('Edit')}</h2>)
            case 'h1':
                return (<h1 style={props.style} className={props.className} id={props.DatabaseFieldName} onDoubleClick={() => { setIsEdit(!IsEdit) }}>{props.Value ? props.Value : t('Edit')}</h1>)
            default:
                return (<div style={props.style} className={props.className} id={props.DatabaseFieldName} onDoubleClick={() => { setIsEdit(!IsEdit) }}>{props.Value ? props.Value : t('Edit')}</div>)
        }

    }

    const InputSelector = () => {
        switch (props.isMultiLine) {
            case 'img':
                return (
                    <div className="form-floating mb-3">
                        <input
                            // defaultValue={props.Value}
                            //value={FieldValue ? FieldValue : props.Value}
                            type="file"
                           // defaultValue={FieldValue ? FieldValue : props.Value}
                            //onBlur={(e) => handleBlur(e)}
                            onChange={(e) => handleonChange(e, "img")}
                            className="form-control"
                            id={props.DatabaseFieldName ? props.DatabaseFieldName : "Id_" + random(5).toString}
                            autoFocus
                        />
                        <button onClick={handleBlur}>Opslaan</button>
                        <Label htmlFor={props.DatabaseFieldName ? props.DatabaseFieldName : "Id_" + random(5).toString}>
                            {props.DatabaseFieldName}
                        </Label>
                    </div>)
            case 'single':
                return (
                    <div className="form-floating mb-3">
                        <input
                            // defaultValue={props.Value}
                            // value={FieldValue}
                            defaultValue={FieldValue ? FieldValue : props.Value}
                            onBlur={(e) => handleBlur(e)}
                            onChange={(e) => handleonChange(e, "")}
                            className="form-control"
                            id={props.DatabaseFieldName ? props.DatabaseFieldName : "Id_" + random(5).toString}
                            autoFocus
                        />
                        <Label htmlFor={props.DatabaseFieldName ? props.DatabaseFieldName : "Id_" + random(5).toString}>
                            {props.DatabaseFieldName}
                        </Label>
                    </div>)
            case 'multi':
                return (
                    <div className="form-floating mb-3">
                        <input
                            defaultValue={FieldValue ? FieldValue : props.Value}
                            type="textarea"
                            // value={FieldValue}
                            // defaultValue={props.Value}
                            onBlur={(e) => handleBlur(e)}
                            onChange={(e) => handleonChange(e, "")}
                            className="form-control"
                            id={props.DatabaseFieldName ? props.DatabaseFieldName : "Id_" + random(5).toString}
                            autoFocus
                        />
                        <Label htmlFor={props.DatabaseFieldName ? props.DatabaseFieldName : "Id_" + random(5).toString}>
                            {props.DatabaseFieldName}
                        </Label>
                    </div>)
            case 'advanced':
                return (
                    <Col lg={12}>
                        <Card>
                            <Form>
                                
                            </Form>
                        </Card>
                    </Col>
                    // <Label htmlFor={props.DatabaseFieldName}>
                    //     {props.DatabaseFieldName}
                    // </Label>
                )
            case 'selection':
                return (
                    <Select
                        className='select-input'
                        style={{ width: "150px" }}
                        placeholder={props.DatabaseFieldName}
                        isClearable
                        options={props.SelectionOptions}
                        getOptionLabel={e => e.label}
                        getOptionValue={e => e.value}
                        onBlur={(e) => handleBlur(e)}
                        onChange={option => langOnChange(option ? option.value : option)}
                        {...restLangField}
                    />
                )
            default:
                return (
                    <div className="form-floating mb-3">
                        <input
                            //value={FieldValue}
                            defaultValue={FieldValue ? FieldValue : props.Value}
                            onBlur={(e) => handleBlur(e)}
                            onChange={(e) => handleonChange(e, "")}
                            className="form-control"
                            id={props.DatabaseFieldName ? props.DatabaseFieldName : "Id_" + random(5).toString}
                            autoFocus
                        />
                        <Label htmlFor={props.DatabaseFieldName ? props.DatabaseFieldName : "Id_" + random(5).toString}>
                            {props.DatabaseFieldName}
                        </Label>
                    </div>)
        }
    }

    return (
        <>
            {IsEdit ?
                (
                    <div>
                        <InputGroup>
                            <InputSelector />
                        </InputGroup >
                    </div >
                ) : (
                    <>
                        <SwitchHeaderText />
                    </>
                )}
        </>
    )


}



export default InputEdit;