import { random } from "lodash";
import React from "react";
import { Link } from "react-router-dom";
import { Row, Col, BreadcrumbItem } from "reactstrap";

interface BreadcrumbProps {
  title: string;
  breadcrumbItem: any;
}

const Breadcrumb = (props: BreadcrumbProps) => {
  const {
    title,
    breadcrumbItem,
  } = props

  const shouldShowStrong = breadcrumbItem.active !== false;
// console.log(shouldShowStrong);

  return (
    <Row>
      <Col xs="12">
        <div className="page-title-box d-flex align-items-center justify-content-between">
          <h4 className="mb-0">{breadcrumbItem[0].label}</h4>
          <div className="page-title-right">
            <ol className="breadcrumb m-0">
              <BreadcrumbItem key={0}>
                <Link to="#">{title}</Link>
              </BreadcrumbItem>
              {breadcrumbItem.map((value, index) => {
                if (value.active === false) {
                  return (
                  <BreadcrumbItem key={index + 1}>
                    <Link to="#">{value.label}</Link>
                  </BreadcrumbItem>
                )
                } else {
                  return (
                    <BreadcrumbItem key={index + 1}>
                      <Link to="#"><strong>{value.label}</strong></Link>
                    </BreadcrumbItem>
                  )
                }
                
              })}
            </ol>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default Breadcrumb;