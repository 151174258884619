import React from "react";
import MetaTags from "react-meta-tags";
import {
    Container,
    Row,
  } from "reactstrap";
  //Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

const IframeHoorayHR = () => {




    return (
            <div className="page-content">
                <MetaTags>
                    <title>weeklystatement | AWEKA Energy Solutions</title>
                </MetaTags>
                <Container fluid={true}>
                    {/* Render Breadcrumb */}
                    <Breadcrumbs title="Apps" breadcrumbItem={[{active: true, urlLink: "#", label: "weeklystatement"}]} />
                    <Row>
                        <iframe
                        style={{minHeight:"90vh"}}
                            src="https://app.hoorayhr.io/"
                            title="HoorayHR" />
                    </Row>
                </Container>
            </div>
    )


}
export default IframeHoorayHR;