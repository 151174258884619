//Imports
//#region
import React, { useEffect, useState } from "react";
import {
  CardBody,
  Col,
  Input,
  Label,
  Row,
  TabContent,
  TabPane,
  Button,
  Tooltip,
  Alert,
  InputGroup,
  InputGroupText
} from "reactstrap";
import Select from "react-select";

import cookie from "js-cookie";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import InputMask from "react-input-mask";
import { CountryData } from "src/constants/CountrySelect";
import { PayTermsData, PayRateData } from "src/constants/PaymentSelect";
import { setAddCustomer, setNotifybyID } from "src/slices/thunks";
import { useDispatch, useSelector } from "react-redux";
import dataloaded from "src/components/Common/DataLoaded";
import { useNavigate } from "react-router-dom";
import { useForm, SubmitHandler, useFormState, Controller, useController } from "react-hook-form";
import NotifyComponent from "src/components/Common/NotifyComponent";

import "./styleAddLocation.css";
import CustomersTable from "src/components/Customer/CustomersTable";
import { AppDispatch, RootState } from "src";
//#endregion

interface IFormValues {
  locationname: string,
  locationadress: string,
  locationzipcode: string,
  locationcity: string,
  locationcountry: string,
  locationphonenumber: string,
  locationaccesscode: string,
  locationdoor: string,
  locationcustomerUUID: string,
  Active: string,
}

const BasicPills = () => {

  // All Const variables 
  //#region

  const { t } = useTranslation();
  const { register, handleSubmit, formState: { errors }, getValues, setValue, control } = useForm();
  const [activeTab, setactiveTab] = useState(1);
  const dispatch = useDispatch<AppDispatch>();
  const Addcustomers = useSelector((state: RootState) => state.customer);
  const { status, error: any, error } = Addcustomers
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  const [tooltipOpen2, setTooltipOpen2] = useState(false);
  const toggle2 = () => setTooltipOpen2(!tooltipOpen2);
  const [tooltipOpen3, setTooltipOpen3] = useState(false);
  const toggle3 = () => setTooltipOpen3(!tooltipOpen3);
  const [modal_standard, setmodal_standard] = useState(false);
  const navigate = useNavigate();
  const obj = JSON.parse(cookie.get("userData"));
  //#endregion

  //Submit FormData to database 
  //#region
  const onSubmit: SubmitHandler<IFormValues> = (data) => {
    console.log(data);

    let formDataObject = Object.fromEntries(Object.entries(data));
    // Format the plain form data as JSON
    let formDataJsonString = JSON.stringify(formDataObject);
    const datain: {} = { email: obj.email, token: obj.token, dataUpload: formDataJsonString, METHOD: "setAddLocation" };
    // const returning = dispatch(setAddCustomer(datain))
    // dataloaded({ id: 15, status: status, error: errors.toString(), successful: t('successMessageAdding', { mail: obj.email, company: getValues('companyname') }), time: 1000 })
    // if (returning && status === 'success') {
    //   const dataNotify: {} = {email: obj.email, token: obj.token, dataN: JSON.stringify({
    //     action: t('successMessageAdding', { mail: obj.email, company: getValues('companyname') }),
    //     user: obj.email,
    //     readed: 0,
    //     rawdata: datain.toString(),
    //     status: "Success"}), METHOD: "setNotify" }
    //   dispatch(setNotifybyID(dataNotify));
    //   // console.log(dataNotify);
    //   history.push({
    //     pathname: '/Customers/',
    //     state: {}
    //   })
    // }
  }
  //#endregion

  //success or error status view popupwindow
  //#region
  // useEffect(() => {
  //   //console.log(status);
  //   dataloaded({ id: 5, status: status, error: error, successful: t('successMessageAdding', {mail: obj.email, company: getValues('companyname')}), time: 1000 })
  // }, [status]);
  //#endregion

  //Toggle tab pages for wizzard 
  //#region
  function toggleTab(tab: any) {
    if (activeTab !== tab) {
      if (tab >= 1 && tab <= 4) {
        setactiveTab(tab);
      }
    }
  }
  //#endregion

  // Select form Controller
  //#region
  const { field: { value: langValue, onChange: langOnChange, ...restLangField } } = useController({ name: 'locationcountry', control, rules: { required: true } });
  

  const { field: { value: VATRateValue, onChange: VATrateOnChange, ...restVATrateField } } = useController({ name: 'VATrate', control, rules: { required: true } });
  const { field: { value: paytermsValue, onChange: paytermsOnChange, ...restpaytermsField } } = useController({ name: 'payterms', control, rules: { required: true } });

  //#endregion
  return (
    <React.Fragment>
      <CardBody>
        <div>
          <ul className="wizard-nav mb-5">
            <li className="wizard-list-item">
              <div
                className={classnames({ active: activeTab === 1 }, "list-item")}
                id={"Tooltip-1"}
                onClick={() => {
                  toggleTab(1);
                }}
              >
                <div className="step-icon">
                  <i className="uil uil-list-ul"></i>
                </div>
              </div>
              <Tooltip
                placement="top"
                isOpen={tooltipOpen}
                target={"Tooltip-1"}
                toggle={toggle}
              >
                {t("Location")} {t("Details")}
              </Tooltip>
            </li>
            <li className="wizard-list-item">
              <div
                className={classnames({ active: activeTab === 2 }, "list-item")}
                id={"Tooltip-2"}
                onClick={() => {
                  toggleTab(2);
                }}
              >
                <div className="step-icon">
                  <i className="uil uil-clipboard-notes"></i>
                </div>
              </div>
              <Tooltip
                placement="top"
                isOpen={tooltipOpen2}
                target={"Tooltip-2"}
                toggle={toggle2}
              >
                {t("Location")} {t("LocationPolicy")}
              </Tooltip>
            </li>

            <li className="wizard-list-item">
              <div
                className={classnames({ active: activeTab === 3 }, "list-item")}
                id={"Tooltip-3"}
                onClick={() => {
                  toggleTab(3);
                }}
              >
                <div className="step-icon">
                  <i className="uil uil-university"></i>
                </div>
              </div>
              <Tooltip
                placement="top"
                isOpen={tooltipOpen3}
                target={"Tooltip-3"}
                toggle={toggle3}
              >
                Attached Files
              </Tooltip>
            </li>
          </ul>
          <form onSubmit={handleSubmit(onSubmit)}>
            <TabContent activeTab={activeTab}>
              {/* //Location Details TAB/PART */}
              <TabPane tabId={1}>
                <div className="text-center mb-4">
                  <h5>{t("Location")} {t("Details")}</h5>
                  <p className="card-title-desc">{t("FillLocationDetails")}</p>
                </div>
                <div>
                  <Row>
                    <Col lg={6}>
                      <Row>
                        <Col lg={12}>
                          <div className="mb-3">
                            <Label
                              htmlFor="basicpill-companyname-input"
                              className="form-label"
                            >
                              {t("LocationName")}
                            </Label>
                            <InputGroup>
                              {errors.locationname?.type === "required" && (
                                <div className="input-group-text-danger">
                                  !
                                </div>)}
                              <input
                                {...register("locationname", { required: true })}
                                type="text"
                                required
                                className="form-control "
                                id="basicpill-locationname-input"
                                aria-invalid={errors.locationname ? "true" : "false"}
                              />
                            </InputGroup>
                            {errors.locationname?.type === "required" && (
                              <p>
                                <Alert color="danger mt-2">{t('required', { labelreq: t("LocationName") })}</Alert>
                              </p>
                            )}
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={12}>
                          <div className="mb-3">
                            <Label
                              htmlFor="basicpill-locationadress-input"
                              className="form-label"
                            >
                              {t("Streetname")}
                            </Label>
                            <InputGroup>
                              {errors.locationadress?.type === "required" && (
                                <div className="input-group-text-danger">
                                  !
                                </div>)}
                              <input
                                type="text"
                                required
                                {...register("locationadress", { required: true })}
                                className="form-control"
                                id="basicpill-locationadress-input"
                                aria-invalid={errors.locationadress ? "true" : "false"}

                              />
                            </InputGroup>
                            {errors.locationadress?.type === "required" && (
                              <p>
                                <Alert color="danger mt-2">{t('required', { labelreq: t("Streetname") })}</Alert>
                              </p>
                            )}
                          </div>
                        </Col>

                      </Row>

                      <Row>
                        <Col lg={4}>
                          <div className="mb-3">
                            <Label
                              htmlFor="basicpill-zipcode-input"
                              className="form-label"
                            >
                              {t("Zipcode")}
                            </Label>
                            <InputGroup>
                              {errors.locationzipcode?.type === "required" && (
                                <div className="input-group-text-danger">
                                  !
                                </div>)}
                              {getValues("country")?.mask ?
                                <InputMask
                                  mask={getValues("country")?.mask}
                                  {...register("locationzipcode", { required: true })}
                                  required
                                  className="form-control input-color"
                                  aria-invalid={errors.locationzipcode ? "true" : "false"}
                                /> :
                                <input
                                  type="text"
                                  required
                                  className="form-control"
                                  {...register("locationzipcode", { required: true })}
                                  aria-invalid={errors.locationzipcode ? "true" : "false"}
                                  id="basicpill-locationzipcode-input"
                                />}

                            </InputGroup>
                            {errors.locationzipcode?.type === "required" && (
                              <p>
                                <Alert color="danger mt-2">{t('required', { labelreq: t("Zipcode") })}</Alert>
                              </p>
                            )}
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className="mb-3">
                            <Label
                              htmlFor="basicpill-city-input"
                              className="form-label"
                            >
                              {t("City")}
                            </Label>
                            <InputGroup>
                              {errors.locationcity?.type === "required" && (
                                <div className="input-group-text-danger">
                                  !
                                </div>)}
                              <input
                                required
                                type="text"
                                {...register("locationcity", { required: true })}
                                className="form-control"
                                id="basicpill-locationcity-input"
                                aria-invalid={errors.locationcity ? "true" : "false"}
                              />

                            </InputGroup>
                            {errors.locationcity?.type === "required" && (
                              <p>
                                <Alert color="danger mt-2">{t('required', { labelreq: t("City") })}</Alert>
                              </p>
                            )}
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={12}>
                          <div className="mb-3">
                            <Label
                              htmlFor="locationcountry"
                              className="form-label"
                            >
                              {t("Country")}
                            </Label>
                            <Select
                              className='select-input'
                              placeholder={t("SelectCountry")}
                              isClearable
                              options={CountryData}
                              getOptionLabel={e => e.label}
                              getOptionValue={e => e.value}
                              onChange={option => langOnChange(option ? option.value : option)}
                              {...restLangField}
                            />
                            {errors.locationcountry?.type === "required" && (
                              <p>
                                <Alert color="danger mt-2">{t('required', { labelreq: t("Country") })}</Alert>
                              </p>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    {/* <Col lg={6}>
                      <Row>
                        <Col lg={12}>
                          <div style={{ paddingLeft: "5em" }} className="ml-4 mb-3">
                            <Label
                              htmlFor="basicpill-locationphonenumber-input"
                              className="form-label"
                            >
                              {t("PhoneNumber")}
                            </Label>
                            <input
                              type="number"
                              className="form-control"
                              {...register("locationphonenumber")}
                              id="basicpill-locationphonenumber-input"
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={12}>
                          <div style={{ paddingLeft: "5em" }} className="ml-4 mb-3">
                            <Label
                              htmlFor="basicpill-locationaccesscode-input"
                              className="form-label"
                            >
                              {t("LocationAccessCode")}
                            </Label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("locationaccesscode")}
                              id="basicpill-locationaccesscode-input"
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={12}>
                          <div style={{ paddingLeft: "5em" }} className="ml-4 mb-3">
                            <Label
                              htmlFor="basicpill-locationdoor-input"
                              className="form-label"
                            >
                              {t("LocationDoor")}
                            </Label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("locationdoor")}
                              id="basicpill-locationdoor-input"
                            />
                          </div>
                        </Col>
                      </Row>
                    </Col> */}
                  </Row>
                </div>
              </TabPane>
              {/* //Location Policy and Contact TAB/PART */}
              <TabPane tabId={2}>
                <div className="text-center mb-4">
                  <h5>{t("Location")}</h5>
                  <p className="card-title-desc">{t("LocationPolicy")}</p>
                </div>

                <div>
                  <Row>
                    <Col xl={6}>
                      <Row>
                        <Col lg={12}>
                          <div className="ml-4 mb-3">
                            <Label
                              htmlFor="basicpill-locationphonenumber-input"
                              className="form-label"
                            >
                              {t("PhoneNumber")}
                            </Label>
                            <input
                              type="number"
                              className="form-control"
                              {...register("locationphonenumber")}
                              id="basicpill-locationphonenumber-input"
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={12}>
                          <div className="ml-4 mb-3">
                            <Label
                              htmlFor="basicpill-locationaccesscode-input"
                              className="form-label"
                            >
                              {t("LocationAccessCode")}
                            </Label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("locationaccesscode")}
                              id="basicpill-locationaccesscode-input"
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={12}>
                          <div className="ml-4 mb-3">
                            <Label
                              htmlFor="basicpill-locationdoor-input"
                              className="form-label"
                            >
                              {t("LocationDoor")}
                            </Label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("locationdoor")}
                              id="basicpill-locationdoor-input"
                            />
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </TabPane>
              {/* //Location connect to Customer TAB/PART */}
              <TabPane tabId={3}>
                <div>
                  <div className="text-center mb-4">
                    <h5>Koppel Locatie</h5>
                    <p className="card-title-desc">Fill all information below</p>
                  </div>
                  <div>
                    <Row>
                      <Col xl={12}>
                        <CustomersTable PageSize={15} />
                      </Col>
                    </Row>
                  </div>
                </div>
              </TabPane>
            </TabContent>
          </form>
          {/* //BUTTONS PART */}
          <div className="d-flex align-items-start gap-3 mt-4">
            {activeTab !== 1 &&
              <Button type="button"
                color="primary"
                className="w-sm"
                id="prevBtn"
                onClick={() => {
                  toggleTab(activeTab - 1);
                }}>{t("Previous")}</Button>
            }


            {activeTab !== 3 &&
              <Button type="button"
                color="primary"
                className="w-sm ms-auto"
                id="nextBtn"
                onClick={() => {
                  toggleTab(activeTab + 1);
                }}>{t("Next")}</Button>
            }

            {activeTab === 3 &&
              <Button type="button"
                color="primary"
                onClick={handleSubmit(onSubmit)}
                className="btn btn-primary w-sm ms-auto"
                id="nextBtn">{t("Save")}</Button>
            }

          </div>
        </div>
      </CardBody>
    </React.Fragment>
  );
};

export default BasicPills;
