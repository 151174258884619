import React, { StrictMode, useEffect } from 'react';
import {createRoot} from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './slices'; // Adjust the import according to your file structure

// Check if the root container exists
const container = document.getElementById('root');
if (!container) {
  throw new Error('Root container is missing in index.html');
}

// Create the React root
const root = createRoot(container as HTMLElement);

// Configure the Redux store
const store = configureStore({ reducer: rootReducer, devTools: true });

function AppWithCallbackAfterRender() {
  useEffect(() => {
    console.log('rendered');
  }, []); // Add [] to only log after the first render

  return <App />;
}

// Type definitions for Redux
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export default store;

// Define the app with the necessary providers
const app = (
  <React.StrictMode>
    
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
    
  </React.StrictMode>
);

// Render the app to the root
root.render(app);