const CustomerLogo = ({ domain }: { domain: string }) => {
// 
    return (
        <div>
            {domain ? (
                <div className="avatar-xl">
                    <div className="avatar-title bg-soft-primary text-primary font-size-18 rounded">
                        <img src={`https://logo.clearbit.com/${domain}?format=jpg`} height={'100'} width={'100'} alt={`${domain} logo`} />
                    </div>
                </div>
            ) : (
                <div className="avatar-xl">
                    <div className="avatar-title bg-soft-primary text-primary font-size-18 rounded">
                        <p>LNB</p>
                    </div>
                </div>
            )
            }
        </div >
    );
}

export default CustomerLogo;