import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap";
import SimpleBar from "simplebar-react";

//Import Icons
import { Icon } from "@ailibs/feather-react-ts";

//Import images
import avatar3 from "../../../assets/images/users/avatar-3.jpg";
import avatar4 from "../../../assets/images/users/avatar-4.jpg";

//i18n
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { UpdateNotifyReaded, getNotify } from "src/slices/thunks";
import Cookie from "js-cookie";
import moment from "moment";
import 'moment/locale/nl';
import 'moment/locale/en-gb';
import 'moment/locale/es';
import { RootState, AppDispatch } from "src";


const NotificationDropdown = (props: any) => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const notifys = useSelector((state: RootState) => state.notify);
  const [data, setData] = useState([]);
  const [IsSetReaded, SetIsReaded] = useState(false);
  moment.locale(props.t.language);



  useEffect(() => {
    const obj = JSON.parse(Cookie.get("userData"));
    const datain: {} = { email: obj.email, token: obj.token, id: obj.email, METHOD: "GetNotifybyId" };
    dispatch(getNotify(datain))
    if (notifys.status === 'success') {
      setData(notifys.Notifys);
    }
  }, [menu, IsSetReaded])
  const filteredNotifications = Object.keys(data)
    .filter(id => data[id].readed === 0)


  const handlereaded = (props: any) => {

    const obj = JSON.parse(Cookie.get("userData"));
    const datain: {} = { email: obj.email, token: obj.token, id: props.ID, METHOD: "updateNotify" };
    const returningReaded = dispatch(UpdateNotifyReaded(datain))
    if (returningReaded) {
      SetIsReaded(!IsSetReaded)
      // setData(notifys.Notifys);
    }

  }
  const strDescending = [...data].sort((a, b) =>
  a.date > b.date ? -1 : 1,
);
  //console.log(filteredNotifications);

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <Icon name="bell" className="icon-sm" />
          {filteredNotifications.length > 0 ? <span className="noti-dot bg-danger"></span> : ""}
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0" dir="left">
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h5 className="m-0 font-size-15"> {props.t("Notifications")} </h5>
              </Col>
              <div className="col-auto">
                <Link to="#" className="small">
                  {" "}
                  {props.t('ViewAll')}
                </Link>
              </div>
            </Row>
          </div>

          <SimpleBar style={{ height: "230px" }}>
            <h6 className="dropdown-header bg-light">{props.t('NotifyEarly')}</h6>
            {strDescending.map((value, index) => {

              if (index >= 5 || value.readed === 0) {
                return;
              }
              return (
                <Link key={index} to="" onClick={() => handlereaded({ID: value.uuid})} className="text-reset notification-item">
                  <div className="d-flex border-bottom align-items-start">
                    <div className="flex-shrink-0">
                      <img src={avatar3}
                        className="me-3 rounded-circle avatar-sm" alt="user-pic" />

                    </div>
                    <div className="flex-grow-1">
                      <h6 className="mb-1">{value.action}  {value.readed === 0 ? <span className="badge badge-soft-danger">Nieuw</span> : " "}</h6>
                      <div className="text-muted">
                        <p className="mb-1 font-size-13">{value.rawdata} {value.status === "Success" ? <span className="badge badge-soft-success">Success</span> : <span className="badge badge-soft-primary">{value.status}</span>}</p>
                        <p className="mb-0 font-size-10 text-uppercase fw-bold"><i className="mdi mdi-clock-outline"></i> {moment(new Date(value.date)).locale('nl-be').fromNow()}</p>
                      </div>
                    </div>
                  </div>
                </Link>)
            })}
            <h6 className="dropdown-header bg-light">{props.t('NotifyLater')}</h6>
            {strDescending.map((value, index) => {

              if (index >= 10 && value.date <= (moment.now)) {
                return;
              }
              return (
                <Link key={index} to="" className="text-reset notification-item">
                  <div className="d-flex border-bottom align-items-start">
                    <div className="flex-shrink-0">
                      <img src={avatar3}
                        className="me-3 rounded-circle avatar-sm" alt="user-pic" />
                    </div>
                    <div className="flex-grow-1">
                      <h6 className="mb-1">{value.action}</h6>
                      <div className="text-muted">
                        <p className="mb-1 font-size-13">{value.rawdata} {value.status === "Success" ? <span className="badge badge-soft-success">Success</span> : <span className="badge badge-soft-primary">{value.status}</span>}</p>
                        <p className="mb-0 font-size-10 text-uppercase fw-bold"><i className="mdi mdi-clock-outline"></i>{moment(new Date(value.date)).locale('nl-be').fromNow()}</p>
                      </div>
                    </div>
                  </div>
                </Link>)
            })}

          </SimpleBar>
          <div className="p-2 border-top d-grid">
            <Link
              className="btn btn-sm btn-link font-size-14 btn-block text-center"
              to="#"
            >
              <i className="mdi mdi-arrow-right-circle me-1"></i>{" "}
              {props.t('ViewAll')}{" "}
            </Link>
          </div>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default withTranslation()(NotificationDropdown);