import { createAsyncThunk } from "@reduxjs/toolkit";
import { getNotifys as getNotifysApi,  getNotifysbyID as getNotifybyIDApi, setNotifybyID as setNotifybyIDApi, UpdateNotifyReaded as UpdateNotifyReadedApi } from "../../helpers/real_helper";

export const getNotify = createAsyncThunk('locations/getNotify', async (Notify:{}, {rejectWithValue}) => {
    try {
      const res = await getNotifysApi(Notify);
      //console.log(res);
      return res;
    } catch (err) {
      return rejectWithValue({ error: err.message })
    }
  });

  export const getNotifybyID = createAsyncThunk('locations/getNotifybyID', async (NotifybyID:{}, {rejectWithValue}) => {
    try {
      const res = await getNotifybyIDApi(NotifybyID);
      //console.log(res);
      return res;
    } catch (err) {
      return rejectWithValue({ error: err.message })
    }
  });

  export const setNotifybyID = createAsyncThunk('locations/setNofitybyID', async (SetNotify:{}, {rejectWithValue}) => {
    try {
      const res = await setNotifybyIDApi(SetNotify);
      //console.log(res);
      return res;
    } catch (err) {
      return rejectWithValue({ error: err.message })
    }
  });

  export const UpdateNotifyReaded = createAsyncThunk('locations/UpdateNofityReaded', async (SetNotify:{}, {rejectWithValue}) => {
    try {
      const res = await UpdateNotifyReadedApi(SetNotify);
      console.log(res);
      return res;
    } catch (err) {
      return rejectWithValue({ error: err.message })
    }
  });

