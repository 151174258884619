import React, { useEffect, useRef } from 'react';
import grapesjs from 'grapesjs';
import gjsTabs from 'grapesjs-tabs';
import plugin from 'grapesjs-preset-webpage';
import 'grapesjs/dist/css/grapes.min.css';
import pluginbg from 'grapesjs-style-bg';
import 'grapick/dist/grapick.min.css';

const GrapesEditor = () => {
  const editorRef = useRef(null);

  useEffect(() => {
    if (editorRef.current) {
      const editor = grapesjs.init({
        container: editorRef.current,
        fromElement: true,
        height: '600px',
        width: 'auto',
        storageManager: false,
        blockManager: {
          appendTo: '#blocks',
          blocks: [
            {
              id: 'section', // id is mandatory
              label: '<b>Section</b>', // You can use HTML/SVG inside labels
              attributes: { class:'gjs-block-section' },
              content: `<section>
                  <h1>This is a simple section</h1>
                  <p>You can create custom blocks to insert them into your template.</p>
                </section>`,
            },
            {
              id: 'text',
              label: 'Text',
              content: '<div data-gjs-type="text">Insert your text here</div>',
            },
            {
              id: 'image',
              label: 'Image',
              content: { type: 'image' },
              activate: true,
            }
          ],
        },
        panels: { defaults: [] },
        plugins: [gjsTabs, plugin, pluginbg], // Add the grapesjs-tabs plugin here
        pluginsOpts: {
          [gjsTabs]: {} // You can pass options here if needed
        },
      });

      // If you want to access the editor instance outside of the useEffect scope
      window.editor = editor;
    }
  }, []);

  return (
    <div style={{ display: 'flex', height: '100vh' }}>
      <div id="blocks" style={{ width: '300px', padding: '10px', background: '#f5f5f5', borderRight: '1px solid #ddd' }} />
      <div ref={editorRef} style={{ flex: 1, overflow: 'hidden' }} />
    </div>
  );
};

export default GrapesEditor;