import React, { useEffect, useState } from "react";
import cookie from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getCustomer, getLocationbyCustomerID, getReceipts, setNotifybyID, UpdateCustomer } from "src/slices/thunks";
import { useTranslation } from "react-i18next";
import dataloaded from "src/components/Common/DataLoaded";
import { AppDispatch, RootState } from "src";
import { Col, Container, Row, Card, CardBody, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Nav, NavItem, TabPane, TabContent, NavLink, UncontrolledDropdown } from "reactstrap";
import classnames from 'classnames';
import CustomerLogo from "src/components/Customer/CustomerLogo";
import CustomerOverview from "src/components/Customer/CustomerOverview";
import CustomerLocations, { CountLocations } from "src/components/Customer/CustomerLocations";
import CustomerReceipts from "src/components/Customer/CustomerReceipts";
import { TotalReceiptsByCustomer, NewReceiptsByCustomer, ListReceiptsByCustomerId } from "src/components/Receipts/NewReceiptsByCustomer";




const CustomerProfile = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const customer = useSelector((state: RootState) => state.customer);
    const locationsbycustomerID = useSelector((state: RootState) => state.locations);
    const customerReceipt = useSelector((state: RootState) => state.receipts);
    const inputedit = useSelector((state: RootState) => state.inputEdit);
    const { id } = useParams();
    const [customerData, setcustomerData] = useState([]);
    const [locationsData, setlocationsData] = useState([]);
    const [receiptData, setreceiptData] = useState([]);

    const [menu1, setMenu1] = useState<boolean>(false);
    const [activeTab, setactiveTab] = useState<string>("1");

    const [editing, setEditing] = useState<boolean>(false);
    const [formData, setFormData] = useState(customerData);

    const toggleTab = (tab: any) => {
        if (activeTab !== tab) {
            setactiveTab(tab);
        }
    };


    useEffect(() => {

        const obj = JSON.parse(cookie.get("userData"));
        // const datainCustomers: {} = { email: obj.email, token: obj.token, METHOD: "GetCustomers" };
        // dispatch(getCustomers(datainCustomers))
        if (id !== customer.customerDetail[0]?.UUID) {
            const datain: {} = { email: obj.email, token: obj.token, id: id, METHOD: "GetCustomerbyId" };
            dispatch(getCustomer(datain))
        } else if (customer.status === 'success') {
            setcustomerData(customer.customerDetail[0]);
            setFormData(customer.customerDetail[0]);
        }
        if (customer.customerDetail[0]?.UUID !== id) {
            const datain: {} = { email: obj.email, token: obj.token, id: id, METHOD: "GetLocationbyCustomerID" };
            dispatch(getLocationbyCustomerID(datain))
            console.log(locationsbycustomerID);

        } else if (locationsbycustomerID.status === 'success') {
            setlocationsData(locationsbycustomerID.locations);
        }
        const datainReceipts = { email: obj.email, token: obj.token, METHOD: "GetReceipts" };
        dispatch(getReceipts(datainReceipts));
        if (customerReceipt.status === 'success') {
            setreceiptData(customerReceipt.receipts);
        }

    }, [customer.status, dispatch, id, locationsbycustomerID.status, inputedit])

    const handleEditClick = () => {
        setEditing(true);
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSave = () => {
        setcustomerData(formData);
        setEditing(false);
        handleBlur()
        // Voeg hier je logica toe om de data naar je backend te sturen
      }; 

      
      const handleBlur = async (e?: any, editor?: boolean) => {

        const obj = JSON.parse(cookie.get("userData"));
        var datain: {} = { email: obj.email, token: obj.token, dataUpload: JSON.stringify({ databaseFieldName: "export_clients_aweka", databaseFieldValue: formData, id: customerData['UUID'] }), METHOD: "updateCustomer" }
        var returning = dispatch(UpdateCustomer(datain))
               
        if (returning) {
            const dataNotify: {} = {
                email: obj.email, token: obj.token, dataN: JSON.stringify({
                    action: t('changedMessageAdding', { mail: obj.email, company: "props.DatabaseFieldName" }),
                    user: obj.email,
                    readed: 0,
                    rawdata: JSON.stringify(datain),
                    status: "Success"
                }), METHOD: "setNotify"
            }
            dispatch(setNotifybyID(dataNotify));
        }
        // return props.SuccessEdit;
       window.location.reload();
        // await delay(1000);
        // forceUpdate();

    }



    const custom = customer.customerDetail[0];
    const totalLocations = CountLocations(locationsData, custom?.UUID);

    // console.log("data => "+ JSON.stringify(locationsData));

    console.log(formData);


    useEffect(() => {
        dataloaded({ id: 2, status: customer.status, successful: "Klant opgehaald", time: 1500 })
        dataloaded({ id: 3, status: locationsbycustomerID.status, successful: "Locaties opgehaald", time: 1500 })
    }, [customer.status, locationsbycustomerID.status]);
    return (
        <React.Fragment>
            <div className="page-content">
                {/* <MetaTags>
                    <title>Profiel van {custom?.companyname} | AWEKA Energy Solutions</title>
                </MetaTags> */}
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    {/* <Breadcrumbs title="Projects" breadcrumbItem="Project Overview" /> */}

                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody className="pb-0">
                                    <Row>
                                        <div className="col">
                                            <div className="d-flex align-items-center">
                                                <div className="flex-shrink-0">
                                                    <CustomerLogo domain={customerData['website']} />
                                                </div>
                                                <div className="flex-grow-1 ms-3">
                                                    <div className="text-muted">
                                                        <h5 className="font-size-16 mb-1">{customerData['companyname']}</h5>
                                                        <p className="mb-0">{customerData['streetname']} {customerData['zipcode']} {customerData['city']}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-auto">
                                            <div>
                                                <div className="d-flex flex-wrap align-items-start justify-content-md-end gap-2 mb-3">
                                                    {editing ? (
                                                        <button className="btn btn-soft-success" onClick={handleSave}>
                                                            <i className="uil uil-receipt" style={{ lineHeight: 1, fontSize: "1.5em" }}></i>Opslaan
                                                        </button>
                                                    ) : (
                                                        <Dropdown isOpen={menu1}
                                                            toggle={() => setMenu1(!menu1)}>
                                                            <DropdownToggle className="btn btn-link text-dark shadow-none" color="">
                                                                <i className="uil uil-ellipsis-h"></i>
                                                            </DropdownToggle>
                                                            <DropdownMenu className="dropdown-menu-end">
                                                                <DropdownItem onClick={handleEditClick} href="#">Wijzig</DropdownItem>
                                                                <DropdownItem href="#">Another action</DropdownItem>
                                                                <DropdownItem href="#">Something else here</DropdownItem>
                                                            </DropdownMenu>
                                                        </Dropdown>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </Row>

                                    <Row>
                                        <Col lg={5}>
                                            <div className="mt-3">
                                                <ul className="text-muted ps-4">
                                                    {editing ? (
                                                        <>
                                                            <label>
                                                                Website :
                                                                <input
                                                                    type="text"
                                                                    name="website"
                                                                    value={formData['website'] ? formData['website'] : ""}
                                                                    onChange={handleChange}
                                                                // disabled={loading}
                                                                />
                                                            </label>

                                                            <label>
                                                                Telefoon :
                                                                <input
                                                                    type="text"
                                                                    name="phonenumber"
                                                                    value={formData['phonenumber'] ? formData['phonenumber'] : ""}
                                                                    onChange={handleChange}
                                                                // disabled={loading}
                                                                />
                                                            </label>

                                                            <label>
                                                                Mobiel :
                                                                <input
                                                                    type="text"
                                                                    name="mobilenumber"
                                                                    value={formData['mobilenumber'] ? formData['mobilenumber'] : ""}
                                                                    onChange={handleChange}
                                                                // disabled={loading}
                                                                />
                                                            </label>

                                                            <label>
                                                                Email :
                                                                <input
                                                                    type="text"
                                                                    name="email"
                                                                    value={formData['email'] ? formData['email'] : ""}
                                                                    onChange={handleChange}
                                                                // disabled={loading}
                                                                />
                                                            </label>
                                                        </>
                                                    ) : (
                                                        <ul className="text-muted ps-4">
                                                            <li className="py-1">Website : {customerData['website']}</li>
                                                            <li className="py-1">Telefoon : {customerData['phonenumber']}</li>
                                                            <li className="py-1">Mobiel : {customerData['mobilenumber']}</li>
                                                            <li className="py-1">Email : {customerData['email']}</li>
                                                        </ul>
                                                    )}
                                                </ul>
                                            </div>
                                        </Col>
                                    </Row>

                                    <div className="mt-3">

                                        <Nav className="nav-tabs nav-tabs-custom">
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({
                                                        active: activeTab === "1",
                                                    })}
                                                    onClick={() => {
                                                        toggleTab("1")
                                                    }}
                                                >
                                                    Overzicht
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({
                                                        active: activeTab === "2",
                                                    })}
                                                    onClick={() => {
                                                        toggleTab("2")
                                                    }}
                                                >
                                                    Locaties ({totalLocations})
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({
                                                        active: activeTab === "3",
                                                    })}
                                                    onClick={() => {
                                                        toggleTab("3")
                                                    }}
                                                >
                                                    Bonnen (<NewReceiptsByCustomer customerId={custom?.UUID}/>/<TotalReceiptsByCustomer customerId={custom?.UUID}/>)
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({
                                                        active: activeTab === "4",
                                                    })}
                                                    onClick={() => {
                                                        toggleTab("4")
                                                    }}
                                                >
                                                    Facturen
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({
                                                        active: activeTab === "5",
                                                    })}
                                                    onClick={() => {
                                                        toggleTab("5")
                                                    }}
                                                >
                                                    Contacten
                                                </NavLink>
                                            </NavItem>
                                        </Nav>

                                    </div>
                                </CardBody>
                            </Card>

                            <TabContent activeTab={activeTab}>
                                <TabPane tabId="1">
                                    <CustomerOverview />
                                </TabPane>
                                <TabPane tabId="2">
                                    {locationsData && <CustomerLocations data={locationsData} />}
                                </TabPane>

                                <TabPane tabId="3">
                                    {receiptData && <ListReceiptsByCustomerId  customerId={custom?.UUID} />}
                                </TabPane>

                                <TabPane tabId="4">
                                    {/* <Activities /> */}
                                </TabPane>

                                <TabPane tabId="5">
                                    {/* <Activities /> */}
                                </TabPane>
                            </TabContent>
                        </Col>
                    </Row>

                </Container>
            </div>

        </React.Fragment>
    );
};

export default CustomerProfile;
