import React, { ChangeEvent, useEffect, useState } from "react";
import { Container, Row, Col, Form, Input, Label } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
// availity-reactstrap-validation
import { UserLoginReal } from 'src/slices/thunks';
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { getOrders, getReports, getReceipts, getUser, getCustomers, getLocations } from "src/slices/thunks";
import Cookie from "js-cookie";
import { AppDispatch, RootState } from "src";
import "./login.css";
import useMetaTag from "src/components/Common/useMetaTag";


interface LoginProps {
  navigate?: any;
}
const Login = ({ navigate }: LoginProps) => {

  const { t } = useTranslation();
  const [email, setEmail] = useState(String);
  const [pass, setPass] = useState(String);
  const dispatch = useDispatch<AppDispatch>();
  const login = useSelector((state: RootState) => state.login);
  const { status } = login
  const go = useNavigate();

  const handleChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
    // 👇 Store the input value to local state
    setEmail(e.target.value);
  };
  const handleChangePass = (e: ChangeEvent<HTMLInputElement>) => {
    // 👇 Store the input value to local state
    setPass(e.target.value);
  };

  // handleValidSubmit
  const handleValidSubmit = (e) => {
    e.preventDefault();
    const InputData = { email: email, password: pass, METHOD: "doLogin" }
    dispatch(UserLoginReal(InputData));
  };
  useEffect(() => {
    console.log(status);
    if (status === 'loading') {
      toast.info("Laden", {
        toastId: "1",
        isLoading: false,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

    }
    if (status === 'error') {
      console.log(login.error);

      toast.update("1", {
        render: t(login.error),
        type: "error",
        isLoading: false,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    if (status === 'success') {
      console.log((login.user));

      toast.update("1", {
        render: "Welkom terug " + login.user.first_name + " " + login.user.last_name,
        type: "success",
        isLoading: false,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      const obj = JSON.parse(Cookie.get("userData"));
      try {
        const datain = { email: obj.email, token: obj.token, METHOD: "GetOrders" };
        const datainReports = { email: obj.email, token: obj.token, METHOD: "GetReports" };
        const datainReceipts = { email: obj.email, token: obj.token, METHOD: "GetReceipts" };
        const datainUser = { email: obj.email, token: obj.token, id: obj.id, METHOD: "GetUserbyId" };
        const datainCustomers = { email: obj.email, token: obj.token, METHOD: "GetCustomers" };
        const datainLocations = { email: obj.email, token: obj.token, METHOD: "GetLocations" };

        Promise.all([
          dispatch(getOrders(datain)),
          dispatch(getReports(datainReports)),
          dispatch(getReceipts(datainReceipts)),
          dispatch(getUser(datainUser)),
          dispatch(getCustomers(datainCustomers)),
          dispatch(getLocations(datainLocations))
        ]).then(() => {
          go("/sales");
        }).catch(error => {
          console.error("Error during dispatching actions:", error);
        });
      } catch (error) {
        console.error("Error:", error);
      }
    }

  }, [dispatch, login.error, go, login.user, status, t]);

  useMetaTag('Login | AWEKA Systeem', 'Login Systeem');
  return (
    <React.Fragment>
      <div className="authentication-bg min-vh-100">
        <div className="bg-overlay bg-white"></div>
        <Container>
          <div className="d-flex flex-column min-vh-100 px-3 pt-4">
            <Row className="justify-content-center my-auto">
              <Col lg={10}>
                <div className="py-5">
                  <div className="card auth-cover-card overflow-hidden">
                    <Row className="g-0">
                      <Col lg={6}>
                        <div className="auth-img">
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="p-4 p-lg-5 bg-danger h-100 d-flex align-items-center justify-content-center">
                          <div className="w-100">

                            <div className="text-white-50 mb-4">
                              <h5 className="text-white">AWEKA Energy Solutions</h5>
                              <p>Personeels omgeving</p>
                            </div>

                            <Form onSubmit={handleValidSubmit}>
                              <div className="form-floating form-floating-custom mb-3">
                                <Input type="email" className="form-control" id="input-email" autoComplete="username" onChange={handleChangeEmail} placeholder="Voer uw gebruikersnaam in" />
                                <Label htmlFor="input-email">Email</Label>
                                <div className="form-floating-icon">
                                  <i className="uil uil-users-alt"></i>
                                </div>
                              </div>
                              <div className="form-floating form-floating-custom mb-3">
                                <Input type="password" className="form-control" id="input-password" autoComplete="current-password" onChange={handleChangePass} placeholder="Voer uw wachtwoord in" />
                                <Label htmlFor="input-password">Wachtwoord</Label>
                                <div className="form-floating-icon">
                                  <i className="uil uil-padlock"></i>
                                </div>
                              </div>
                              <div className="mt-3">
                                <button className="btn btn-danger w-100" type="submit">Aanmelden</button>
                              </div>

                              <div className="mt-4 text-center">
                                <Link to="/forgot-password" className="text-white-50 text-decoration-underline">Wachtwoord vergeten?</Link>
                              </div>
                            </Form>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>

            <Row>
              <Col xl="12">
                <div className="text-center text-muted p-4">
                  <p className="mb-0">&copy;{new Date().getFullYear()} AWEKA Energy Solutions.</p>
                </div>
              </Col>
            </Row>

          </div>
        </Container>
      </div></React.Fragment>
  );
};

export default Login;